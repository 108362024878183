<dx-data-grid
  #grid
  height="100%"
  [dataSource]="dso"
  [errorRowEnabled]="false"
  (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging pageSize="20"></dxo-paging>
  <dxo-pager [allowedPageSizes]="[20, 50]"></dxo-pager>

  <dxo-export [enabled]="true" fileName="auto-dispatch-export"></dxo-export>

  <!-- Columns -->

  <dxi-column caption="Sender Name" dataField="broker.firstName"></dxi-column>
  <dxi-column caption="Sender Email" dataField="broker.email"></dxi-column>
  <dxi-column caption="Received" dataField="incomingAt" dataType="date" format="shortDateShortTime"></dxi-column>
  <dxi-column caption="Reply" dataField="replyAt" dataType="date" format="shortDateShortTime"></dxi-column>
  <dxi-column caption="Load" [calculateDisplayValue]="getDaysLoad" dataField="daysLoadMap"></dxi-column>
  <dxi-column caption="Trips In File" dataField="incomingTripsCount"></dxi-column>
  <dxi-column caption="Schedulable Trips In File" dataField="validTripsCount"></dxi-column>
  <dxi-column caption="Trips Requested" dataField="accepted.length" [allowSorting]="false"></dxi-column>
  <dxi-column caption="Trips Allowed" dataField="tripsAllowed"></dxi-column>
  <dxi-column caption="Alt Re-Run" dataField="acceptedAlt.length" [allowSorting]="false"></dxi-column>

  <!-- Templates -->
</dx-data-grid>


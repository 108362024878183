<div mat-dialog-title>
  <mat-toolbar>

    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis;">
        Authorization Edit
      </div>

      <button mat-icon-button
              type="button"
              [mat-dialog-close]="false"
              matTooltip="Close"
              color="primary"
              style="margin: -1em;"
      >
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>

  </mat-toolbar>
</div>

<mat-dialog-content cdkScrollable>

  <dx-form #form
           [formData]="authFormData"
           [readOnly]="false"
           [showColonAfterLabel]="true"
           [showValidationSummary]="false"
           (onFieldDataChanged)="onFieldDataChanged($event)"
           validationGroup="authData"
  >

    <dxi-item itemType="group" caption="Member">

      <dxi-item>

        <dxo-label text="Client"></dxo-label>

        <div *dxTemplate>
          <dx-drop-down-box
            [(value)]="authFormData._consumerIds"
            [(opened)]="isGridBoxOpened"
            [deferRendering]="false"
            valueExpr="id"
            [displayExpr]="gridBox_displayExpr"
            placeholder="Select a member..."
            [showClearButton]="true"
            [dataSource]="consumerDso"
            (onOptionChanged)="onGridBoxOptionChanged($event)"
          >
            <div *dxTemplate="let data of 'content'">
              <dx-data-grid
                height="100%"
                [dataSource]="consumerDso"

                [hoverStateEnabled]="true"
                [columnChooser]="{enabled: false}"
                [searchPanel]="{visible: false}"
                [filterRow]="{ visible: true }"
                [editing]="{allowAdding: false, allowUpdating: false, allowDeleting: false}"
                [paging]="{enabled: true}"
                [pager]="{visible: false}"
                [scrolling]="{mode: 'infinite'}"
                [selection]="{mode: 'single'}"
                [(selectedRowKeys)]="authFormData._consumerIds"
              >
                <dxi-column caption="Facility"
                            dataField="facility_shortname"
                            dataType="string"
                ></dxi-column>

                <dxi-column caption="Firstname"
                            dataField="person_firstname"
                            dataType="string"
                ></dxi-column>

                <dxi-column caption="Lastname"
                            dataField="person_lastname"
                            dataType="string"
                ></dxi-column>

                <dxi-column caption="DOB"
                            dataField="person_dob"
                            dataType="date"
                            format="shortDate"
                            [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
                ></dxi-column>

                <dxi-column caption="MCI"
                            dataField="mci"
                            dataType="string"
                ></dxi-column>

                <dxi-column caption="Status"
                            dataField="status"
                            dataType="string"
                ></dxi-column>

              </dx-data-grid>
            </div>
          </dx-drop-down-box>
        </div>

        <dxi-validation-rule type="required" message="Client is required"></dxi-validation-rule>

      </dxi-item>

      <dxi-item dataField="MemberID" editorType="dxTextBox">
        <dxi-validation-rule type="required" message="MemberID is required"></dxi-validation-rule>
      </dxi-item>

    </dxi-item>

    <dxi-item itemType="group" caption="Authorization Data">

      <dxi-item dataField="AuthNumberFacets" editorType="dxTextBox"></dxi-item>

      <dxi-item
        dataField="Frequency"
        editorType="dxSelectBox"
        [editorOptions]='{
         dataSource: frequencyDso
        }'
      >
        <dxi-validation-rule type="required" message="Frequency is required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="Code"
        editorType="dxSelectBox"
        [editorOptions]='{
         dataSource: authCodes,
         valueExpr: "_id",
         displayExpr: "t"
        }'
      >
        <dxi-validation-rule type="required" message="Code is required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="StartDT"
        editorType="dxDateBox"
        [editorOptions]="{
                type: 'date',
                displayFormat: 'shortDate',
                dateSerializationFormat: 'yyyy-MM-dd'
              }"
      >
        <dxo-label text="Start Date"></dxo-label>
        <dxi-validation-rule type="required" message="Start Date is required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item
        dataField="EndDT"
        editorType="dxDateBox"
        [editorOptions]="{
                type: 'date',
                displayFormat: 'shortDate',
                dateSerializationFormat: 'yyyy-MM-dd'
              }"
      >
        <dxo-label text="End Date"></dxo-label>
        <dxi-validation-rule type="required" message="End Date is required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="AuthUnitsApproved" editorType="dxNumberBox">
        <dxi-validation-rule type="required" message="Auth Units Approved is required"></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="Note">

        <div *dxTemplate>
          <app-weekday-number-box
            *ngIf="!noteDisabled"
            [(ngModel)]="authFormData._manifest"
            (ngModelChange)="weekly_ngModelChange($event)"
          ></app-weekday-number-box>

          <p>{{ authFormData.Note }}</p>
        </div>

      </dxi-item>

    </dxi-item>

    <dxi-item itemType="group" caption="Service Coordinator">

      <dxi-item dataField="ServiceCoordinatorName" editorType="dxTextBox"></dxi-item>
      <dxi-item dataField="ServiceCoordinatorPhone" editorType="dxTextBox"></dxi-item>
      <dxi-item dataField="ServiceCoordinatorEmail" editorType="dxTextBox"></dxi-item>

    </dxi-item>

  </dx-form>

</mat-dialog-content>

<mat-dialog-actions>

  <!--  <pre>{{auth | json}}</pre>-->

  <dx-validation-summary
    validationGroup="authData"
  ></dx-validation-summary>

  <!-- /// -->

  <div class="toolbar">

    <button
      mat-button
      type="button"
      color="primary"
      [disabled]="submitting"
      (click)="reset_click($event, form)"
      matTooltip="Reset form fields to initial values"
    >Reset
    </button>

    <span style="flex: auto"></span>

    <button mat-button
            type="button"
            color="primary"
            [disabled]="submitting"
            (click)="cancel_click($event, form)"
    >Cancel
    </button>

    <button mat-button
            type="submit"
            color="primary"
            cdkFocusInitial
            [disabled]="submitting"
            (click)="submit_click($event, form)"
    >Save
    </button>
  </div>

</mat-dialog-actions>

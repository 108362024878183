import {
  PersDevice
} from '../index';

declare var Object: any;
export interface PersAlertInterface {
  "_id"?: any;
  "hash": string;
  "deviceNum": string;
  "clientField": string;
  "addressField"?: string;
  "name"?: string;
  "phone"?: string;
  "datetime": Date;
  "providerComments"?: any;
  "msgStruct"?: any;
  "tags"?: any;
  "hasMsgParsingIssue"?: boolean;
  "groupID"?: string;
  "resolution"?: any;
  "include"?: boolean;
  "type"?: string;
  "wereEmergencyServicesDispatched"?: boolean;
  "wasTheParticipantTransportedByEmergencyServices"?: boolean;
  "mci"?: string;
  "mco"?: string;
  "source"?: any;
  "ctime"?: Date;
  "utime"?: Date;
  "consumerId"?: number;
  device?: PersDevice;
}

export class PersAlert implements PersAlertInterface {
  "_id": any;
  "hash": string;
  "deviceNum": string;
  "clientField": string;
  "addressField": string;
  "name": string;
  "phone": string;
  "datetime": Date;
  "providerComments": any;
  "msgStruct": any;
  "tags": any;
  "hasMsgParsingIssue": boolean;
  "groupID": string;
  "resolution": any;
  "include": boolean;
  "type": string;
  "wereEmergencyServicesDispatched": boolean;
  "wasTheParticipantTransportedByEmergencyServices": boolean;
  "mci": string;
  "mco": string;
  "source": any;
  "ctime": Date;
  "utime": Date;
  "consumerId": number;
  device: PersDevice;
  constructor(data?: PersAlertInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `PersAlert`.
   */
  public static getModelName() {
    return "PersAlert";
  }

  /**
  * This method creates an instance of PersAlert for dynamic purposes.
  **/
  public static factory(data: PersAlertInterface): PersAlert{
    return new PersAlert(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'PersAlert',
      plural: 'PersAlerts',
      path: 'PersAlerts',
      idName: '_id',
      properties: {
        "_id": {
          name: '_id',
          type: 'any'
        },
        "hash": {
          name: 'hash',
          type: 'string'
        },
        "deviceNum": {
          name: 'deviceNum',
          type: 'string'
        },
        "clientField": {
          name: 'clientField',
          type: 'string'
        },
        "addressField": {
          name: 'addressField',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "datetime": {
          name: 'datetime',
          type: 'Date'
        },
        "providerComments": {
          name: 'providerComments',
          type: 'any'
        },
        "msgStruct": {
          name: 'msgStruct',
          type: 'any'
        },
        "tags": {
          name: 'tags',
          type: 'any'
        },
        "hasMsgParsingIssue": {
          name: 'hasMsgParsingIssue',
          type: 'boolean'
        },
        "groupID": {
          name: 'groupID',
          type: 'string'
        },
        "resolution": {
          name: 'resolution',
          type: 'any'
        },
        "include": {
          name: 'include',
          type: 'boolean'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "wereEmergencyServicesDispatched": {
          name: 'wereEmergencyServicesDispatched',
          type: 'boolean'
        },
        "wasTheParticipantTransportedByEmergencyServices": {
          name: 'wasTheParticipantTransportedByEmergencyServices',
          type: 'boolean'
        },
        "mci": {
          name: 'mci',
          type: 'string'
        },
        "mco": {
          name: 'mco',
          type: 'string'
        },
        "source": {
          name: 'source',
          type: 'any'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "consumerId": {
          name: 'consumerId',
          type: 'number'
        },
      },
      relations: {
        device: {
          name: 'device',
          type: 'PersDevice',
          model: 'PersDevice',
          relationType: 'belongsTo',
                  keyFrom: 'deviceNum',
          keyTo: 'phoneNumber'
        },
      }
    }
  }
}

import {
  DocumentType,
  LastFileView
} from '../index';

declare var Object: any;
export interface LastDocumentInterface {
  "expDate"?: any;
  "expField"?: string;
  "documentTypeId": number;
  "objectType": string;
  "objectId": number;
  "name": string;
  "id": number;
  "ctime": Date;
  "dtime"?: Date;
  "dtime_str"?: string;
  "meta": any;
  "notes"?: string;
  "ownerId": number;
  "utime": Date;
  documentType?: DocumentType;
  forObject?: any;
  lastFile?: LastFileView;
}

export class LastDocument implements LastDocumentInterface {
  "expDate": any;
  "expField": string;
  "documentTypeId": number;
  "objectType": string;
  "objectId": number;
  "name": string;
  "id": number;
  "ctime": Date;
  "dtime": Date;
  "dtime_str": string;
  "meta": any;
  "notes": string;
  "ownerId": number;
  "utime": Date;
  documentType: DocumentType;
  forObject: any;
  lastFile: LastFileView;
  constructor(data?: LastDocumentInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `LastDocument`.
   */
  public static getModelName() {
    return "LastDocument";
  }

  /**
  * This method creates an instance of LastDocument for dynamic purposes.
  **/
  public static factory(data: LastDocumentInterface): LastDocument{
    return new LastDocument(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'LastDocument',
      plural: 'LastDocuments',
      path: 'LastDocuments',
      idName: 'id',
      properties: {
        "expDate": {
          name: 'expDate',
          type: 'any'
        },
        "expField": {
          name: 'expField',
          type: 'string'
        },
        "documentTypeId": {
          name: 'documentTypeId',
          type: 'number'
        },
        "objectType": {
          name: 'objectType',
          type: 'string'
        },
        "objectId": {
          name: 'objectId',
          type: 'number'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "ownerId": {
          name: 'ownerId',
          type: 'number'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
      },
      relations: {
        documentType: {
          name: 'documentType',
          type: 'DocumentType',
          model: 'DocumentType',
          relationType: 'belongsTo',
                  keyFrom: 'documentTypeId',
          keyTo: 'id'
        },
        forObject: {
          name: 'forObject',
          type: 'any',
          model: '',
          relationType: 'belongsTo',
                  keyFrom: 'objectId',
          keyTo: 'id'
        },
        lastFile: {
          name: 'lastFile',
          type: 'LastFileView',
          model: 'LastFileView',
          relationType: 'hasOne',
                  keyFrom: 'id',
          keyTo: 'documentId'
        },
      }
    }
  }
}

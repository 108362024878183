/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/select-tenant-popup/select-tenant-popup.component.ngfactory";
import * as i3 from "../../components/select-tenant-popup/select-tenant-popup.component";
import * as i4 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i7 from "@angular/material/card";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../components/signout-form/signout-form.component.ngfactory";
import * as i10 from "../../components/signout-form/signout-form.component";
import * as i11 from "../../components/signin-form/signin-form.component.ngfactory";
import * as i12 from "../../components/signin-form/signin-form.component";
import * as i13 from "@angular/forms";
import * as i14 from "../../../../shared/sdk/services/custom/MyUser";
import * as i15 from "@angular/router";
import * as i16 from "./sign.component";
import * as i17 from "../../../../shared/sdk/services/custom/logger.service";
import * as i18 from "../../../../shared/modules/my-common/services/common.service";
import * as i19 from "@angular/platform-browser";
var styles_SignComponent = [i0.styles];
var RenderType_SignComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignComponent, data: {} });
export { RenderType_SignComponent as RenderType_SignComponent };
function View_SignComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-select-tenant-popup", [["class", "old-dx-swatch-material"]], null, [[null, "mySetTenant"], [null, "myCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySetTenant" === en)) {
        var pd_0 = (_co.onSetTenant($event) !== false);
        ad = (pd_0 && ad);
    } if (("myCancel" === en)) {
        var pd_1 = (_co.onSetTenantCancel($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SelectTenantPopupComponent_0, i2.RenderType_SelectTenantPopupComponent)), i1.ɵdid(2, 114688, null, 0, i3.SelectTenantPopupComponent, [i4.DataSourceService], { popupVisible: [0, "popupVisible"], isPending: [1, "isPending"], error: [2, "error"], ids: [3, "ids"], isSU: [4, "isSU"] }, { mySetTenant: "mySetTenant", myCancel: "myCancel" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.isSetTenantPending$)); var currVal_2 = i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 4).transform(_co.setTenantError$)); var currVal_3 = i1.ɵunv(_v, 2, 3, i1.ɵnov(_v, 5).transform(_co.userTenants$)); var currVal_4 = i1.ɵunv(_v, 2, 4, i1.ɵnov(_v, 6).transform(_co.isSU$)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_SignComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "mat-card", [["class", "mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i6.View_MatCard_0, i6.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i7.MatCard, [[2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i6.View_MatCardHeader_0, i6.RenderType_MatCardHeader)), i1.ɵdid(4, 49152, null, 0, i7.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 1, 3, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i7.MatCardTitle, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sign Out"])), (_l()(), i1.ɵeld(9, 0, null, 1, 1, "mat-card-subtitle", [["class", "mat-card-subtitle"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i7.MatCardSubtitle, [], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 6, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i7.MatCardContent, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "app-signout-form", [], null, [[null, "mySubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySubmit" === en)) {
        var pd_0 = (_co.onLogout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_SignoutFormComponent_0, i9.RenderType_SignoutFormComponent)), i1.ɵdid(14, 114688, null, 0, i10.SignoutFormComponent, [], { isSubmitting: [0, "isSubmitting"], signedUser: [1, "signedUser"], error: [2, "error"] }, { mySubmit: "mySubmit" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.isLogoutPending$)); var currVal_2 = i1.ɵunv(_v, 14, 1, i1.ɵnov(_v, 16).transform(_co.signedUser$)); var currVal_3 = i1.ɵunv(_v, 14, 2, i1.ɵnov(_v, 17).transform(_co.logoutError$)); _ck(_v, 14, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0); }); }
function View_SignComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-signin-form", [], null, [[null, "mySubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySubmit" === en)) {
        var pd_0 = (_co.onLogin($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SigninFormComponent_0, i11.RenderType_SigninFormComponent)), i1.ɵdid(2, 114688, null, 0, i12.SigninFormComponent, [i13.FormBuilder, i14.MyUserApi, i15.ActivatedRoute, i15.Router], { mfaSecret: [0, "mfaSecret"], signedUser: [1, "signedUser"], isSubmitting: [2, "isSubmitting"], error: [3, "error"] }, { mySubmit: "mySubmit" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.mfaSecret$)); var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.signedUser$)); var currVal_2 = i1.ɵunv(_v, 2, 2, i1.ɵnov(_v, 5).transform(_co.isLoginPending$)); var currVal_3 = i1.ɵunv(_v, 2, 3, i1.ɵnov(_v, 6).transform(_co.loginError$)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SignComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignComponent_1)), i1.ɵdid(4, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignComponent_3)), i1.ɵdid(8, 16384, null, 0, i5.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.mode$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = "SET_TENANT"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "LOGOUT"; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SignComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign", [], null, null, null, View_SignComponent_0, RenderType_SignComponent)), i1.ɵdid(1, 114688, null, 0, i16.SignComponent, [i17.LoggerService, i18.CommonService, i15.ActivatedRoute, i19.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignComponentNgFactory = i1.ɵccf("app-sign", i16.SignComponent, View_SignComponent_Host_0, {}, {}, []);
export { SignComponentNgFactory as SignComponentNgFactory };

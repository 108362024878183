<dx-toast [(visible)]="isToastVisible" [message]="toastMessage" [type]="toastType" [displayTime]="5000"> </dx-toast>
<div class="filter-panel">
  <dx-date-box #from width="100px" [(value)]="selectedDate" (onValueChanged)="calendar_onValueChanged($event)">
    <ng-container *dxTemplate="let data of 'cell'">
      <div [ngClass]="calendarClass(data)" [title]="calendarTitle(data)">
        {{ data.text }}
      </div>
    </ng-container> </dx-date-box
  >&nbsp;
  <dx-button
    [disabled]="!selectedRows.length"
    type="normal"
    text="Send Schedule to All selected Employees"
    (onClick)="sendSchedules_onClick(selectedRows)"></dx-button>
</div>

<div class="progress-container" *ngIf="!isBackUpEmployeeOnly && progress">
  <mat-progress-bar class="custom-progress-bar" mode="determinate" [value]="progress"></mat-progress-bar>
  <div class="progress-text">{{ progressCount }} / {{ progressTotal }}</div>
</div>

<dx-popup
  #popupClone
  [dragEnabled]="false"
  [showTitle]="false"
  [showCloseButton]="false"
  [visible]="false"
  [width]="480"
  [height]="120"
  [elementAttr]="{ class: 'grid-popup' }">
  <ng-container *dxTemplate="let data of 'content'">
    <div style="font-size: 25px; text-align: center; margin: 10px 0">
      Clone all schedules for {{ selectedDate | date: 'EEEE' }}
    </div>
  </ng-container>
</dx-popup>

<dx-data-grid
  #grid
  [dataSource]="dso"
  [remoteOperations]="false"
  [errorRowEnabled]="false"
  (onToolbarPreparing)="grid_onToolbarPreparing($event)"
  (onInitialized)="grid_onInitialized($event)"
  (onEditingStart)="grid_onEditingStart($event)"
  (onRowRemoving)="grid_onRowRemoving($event)"
  (onSelectionChanged)="grid_onSelectionChanged($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-selection selectAllMode="allPages" showCheckBoxesMode="onClick" mode="multiple"></dxo-selection>

  <dxo-editing
    mode="form"
    [allowAdding]="false"
    [allowUpdating]="!isBackUpEmployeeOnly"
    [allowDeleting]="!isBackUpEmployeeOnly"></dxo-editing>

  <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

  <dxo-export [enabled]="true" fileName="employees-ext-export"></dxo-export>

  <!-- Columns -->

  <dxi-column
    caption="First Name"
    dataField="person.firstname"
    dataType="string"
    cellTemplate="fname_cell"></dxi-column>

  <dxi-column caption="Last Name" dataField="person.lastname" dataType="string"></dxi-column>

  <dxi-column caption="Phone" dataField="emplPhone" dataType="string"></dxi-column>

  <dxi-column caption="Status" dataField="schedule.status" dataType="string" width="125px" cellTemplate="status_cell">
    <dxo-lookup [dataSource]="statuses" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Facility" dataField="facility.shortname" dataType="string"></dxi-column>

  <dxi-column
    *ngIf="isBackUpEmployeeOnly"
    caption="⁠SignIn Time"
    dataField="dayService.value"
    dataType="date"
    format="shortTime"></dxi-column>

  <dxi-column
    *ngIf="isBackUpEmployeeOnly"
    caption="Vehicle CheckIn"
    cellTemplate="geoTabLocation_cell"
    [allowSearch]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"
    [allowSorting]="false"></dxi-column>

  <dxi-column *ngIf="isBackUpEmployeeOnly" caption="Signatures" dataField="signatureCount"></dxi-column>

  <dxi-column caption="Manifest" dataField="manifestExists" dataType="string" cellTemplate="manifest_cell">
    <dxo-lookup [dataSource]="manifestExistsDS" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column
    *ngIf="!isBackUpEmployeeOnly"
    caption="Scheduling Mode"
    dataField="scheduleMode"
    dataType="string"
    width="110px"
    cellTemplate="mode_cell">
    <dxo-lookup [dataSource]="modesLookup" displayExpr="Name" valueExpr="ID"></dxo-lookup>
  </dxi-column>

  <dxi-column caption="Vehicle #" dataField="internalId" dataType="number" width="80px"></dxi-column>

  <dxi-column
    caption="First Pickup Time"
    dataField="manifest.firstTrip.t"
    dataType="date"
    format="shortTime"></dxi-column>

  <dxi-column caption="First Pickup Location" dataField="firstTripLocaction" dataType="string"></dxi-column>

  <dxi-column
    caption="Work Start Time"
    cellTemplate="start_time_cell"
    dataField="schedule.startTime"
    dataType="date"></dxi-column>

  <dxi-column caption="Work Start Location" dataField="schedule.startLocationAddress" dataType="string"></dxi-column>

  <dxi-column
    caption="Last Drop Off Time"
    dataField="manifest.lastTrip.dot"
    dataType="date"
    format="shortTime"></dxi-column>

  <dxi-column caption="Last Drop Off  Location" dataField="lastTripLocaction" dataType="string"></dxi-column>

  <dxi-column
    caption="Work Finish Time"
    dataField="schedule.finishTime"
    dataType="date"
    format="shortTime"></dxi-column>

  <dxi-column caption="Work Finish Location" dataField="schedule.finishLocationAddress" dataType="string"></dxi-column>

  <!-- Templates -->

  <ng-container *dxTemplate="let cellInfo of 'geoTabLocation_cell'">
    <a
      *ngIf="cellInfo.data.vehicleGeotab?.lastPosition"
      target="_blank"
      class="show-loop-link"
      [href]="getMapURL(cellInfo.data.vehicleGeotab.lastPosition)"
      title="link">
      {{ cellInfo.data.vehicleGeotab.internalId }}</a
    >&nbsp;
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'status_cell'">
    <dx-select-box
      height="20px"
      [dataSource]="statuses"
      displayExpr="Name"
      valueExpr="ID"
      (onValueChanged)="status_onValueChanged($event, cellInfo.data)"
      [value]="(cellInfo.data.schedule && cellInfo.data.schedule.status) || ''"></dx-select-box>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'mode_cell'">
    <dx-select-box
      *ngIf="cellInfo.data.scheduleMode !== 'NO_SCHEDULE'"
      height="20px"
      [dataSource]="modes"
      displayExpr="Name"
      valueExpr="ID"
      (onValueChanged)="mode_onValueChanged($event, cellInfo.data)"
      [value]="cellInfo.data.schedule.mode"></dx-select-box>
    <span style="color: #920909" *ngIf="cellInfo.data.scheduleMode == 'NO_SCHEDULE'">NO SCHEDULE</span>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'fname_cell'">
    {{ cellInfo.value }}
    <i
      *ngIf="cellInfo.data.schedule"
      title="History"
      class="button-checklist dx-icon dx-icon-checklist"
      (click)="history_onClick(cellInfo.data)"></i>
    <i
      *ngIf="cellInfo.data.schedule?.startTime"
      title="Send Schedule"
      class="button-schedule dx-icon dx-icon-comment"
      (click)="sendSchedules_onClick([cellInfo.data])"></i>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'start_time_cell'">
    <span
      [title]="(!isBackUpEmployeeOnly && helper.isGoodToRecalculateStartTime(cellInfo.data)) || ''"
      [class]="(!isBackUpEmployeeOnly && helper.isGoodToRecalculateStartTime(cellInfo.data) && 'starttime-bold') || ''"
      >{{ formatTime(cellInfo.data.schedule?.startTime) }}</span
    >
    <i
      *ngIf="
        !isBackUpEmployeeOnly &&
        cellInfo.data.schedule?.startTime &&
        cellInfo.data.firstTripConsumer?.person.contact.addresses[0] &&
        cellInfo.data.manifest?.firstTrip?.t &&
        cellInfo.data.schedule?.startLocationCoords
      "
      title="Re-calculate {{ startTimeCalculatedAt(cellInfo.data.schedule.startTimeCalculatedAt) }}"
      class="button-refresh dx-icon dx-icon-refresh"
      (click)="recalculateStartTime_onClick(cellInfo.data)"></i>
  </ng-container>

  <ng-container *dxTemplate="let cellInfo of 'manifest_cell'">
    <a *ngIf="cellInfo.data.manifest" class="show-loop-link" (click)="manifest_onClick(cellInfo.data)">Manifest</a>
  </ng-container>
</dx-data-grid>


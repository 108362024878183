<table [ngClass]="{ wrapped: wrap, unwrapped: !wrap }">
  <ng-container *ngFor="let note of model?.relatedNotes; let isFirst = first">
    <tr>
      <td>
        <div [title]="note.text">
          <span class="badge"> {{ note.dateTime | date: 'yyyy/MM/dd HH:mm' }}</span>
          : {{ note.author || 'Unknown' }} : {{ note.text }} {{ info(note) }}
        </div>
      </td>
      <td width="1%" valign="top">
        <ng-container *ngIf="isFirst">
          <dx-button
            hint="Expand Grid"
            icon="fas fa-expand-arrows-alt"
            (onClick)="expand_onClick()"
          ></dx-button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </ng-container>
      </td>
    </tr>
  </ng-container>
</table>

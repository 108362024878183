import {
  Facility
} from '../index';

declare var Object: any;
export interface ConfigInterface {
  "data"?: any;
  "id"?: any;
  "tenantId"?: number;
  tenant?: Facility;
}

export class Config implements ConfigInterface {
  "data": any;
  "id": any;
  "tenantId": number;
  tenant: Facility;
  constructor(data?: ConfigInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Config`.
   */
  public static getModelName() {
    return "Config";
  }

  /**
  * This method creates an instance of Config for dynamic purposes.
  **/
  public static factory(data: ConfigInterface): Config{
    return new Config(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Config',
      plural: 'Configs',
      path: 'Configs',
      idName: 'id',
      properties: {
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
      }
    }
  }
}

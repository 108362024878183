import { Component, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-schedule-сom-сenter',
  templateUrl: './schedule-сom-сenter.component.html',
  styleUrls: ['./schedule-сom-сenter.component.scss'],
})
export class ScheduleComCenterComponent implements OnInit, OnChanges {
  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  typeOptions = [
    { name: 'All', value: '' },
    { name: 'Employee Schedule', value: 'Employee Schedule' },
  ];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(): void {}
}


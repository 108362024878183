import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { MyUser, MyUserApi } from '../../../../shared/sdk';

import getBrowserFingerprint from 'get-browser-fingerprint';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
})
export class SigninFormComponent implements OnInit {
  form: FormGroup;
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  passHide = true;
  mode: 'SIGN_IN' | 'FORGOT_PASSWORD' | 'RESET_PASSWORD' = 'SIGN_IN';
  resetPasswordToken: string | null = null;
  @Output() mySubmit = new EventEmitter<MyUser>();

  constructor(
    private fb: FormBuilder,
    private userApi: MyUserApi,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.buildForm();
  }

  @Input()
  set mfaSecret(value: string) {
    if (value) this.form.get('mfaSecret').reset(value);
  }

  @Input() signedUser: MyUser;

  private _isSubmitting = false;
  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  @Input()
  set isSubmitting(value) {
    this._isSubmitting = value;
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private _error: any = null;
  get error(): string {
    return (this._error && this._error.message) || this._error;
  }

  @Input()
  set error(value) {
    this._error = value;
    this.error && notify(this.error, 'error', 5000);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['reset-password']) {
        this.resetPasswordToken = params['reset-password'];
        this.mode = 'RESET_PASSWORD';
      }
    });
  }

  form_ngSubmit(): boolean {
    if (this.form.valid) {
      [this.form]
        .filter((form: FormGroup) => form.valid)
        .map((form: FormGroup) => new MyUser(form.value))
        .forEach((user: MyUser) => this.mySubmit.emit(user));
    }
    return false;
  }

  reset_onClick(): void {
    this.form.reset();
  }

  getErrorMessage(form: FormGroup, field: string, name: string) {
    if (form.get(field).hasError('required')) return `${name} is required`;
    if (form.get(field).hasError('email')) return `Invalid ${name}`;
    return '';
  }

  getEmail() {
    const { email } = this.signedUser;
    const [start, end] = email.split('@');
    return `${start.slice(0, 3)}*@*****${end.slice(-7)}`;
  }

  showForgotPasswordForm(): void {
    this.mode = 'FORGOT_PASSWORD';
  }

  hideForgotPasswordForm(): void {
    this.mode = 'SIGN_IN';
    this.forgotPasswordForm.reset();
    this.error = null;
  }

  async forgotPasswordSubmit(): Promise<void> {
    if (this.forgotPasswordForm.valid) {
      this.isSubmitting = true;
      console.log('Forgot Password:', this.forgotPasswordForm.value);
      try {
        await this.userApi.sendPasswordResetEmail(this.forgotPasswordForm.value.email).toPromise();
        notify('Password reset email sent', 'success', 5000);
        this.hideForgotPasswordForm();
      } catch (err) {
        this.error = err;
      }
      this.isSubmitting = false;
    }
  }

  hideResetPasswordForm(): void {
    this.resetPasswordForm.reset();
    this.mode = 'SIGN_IN';
    this.resetPasswordToken = null;
    this.error = null;
    this.router.navigate([], { queryParams: { 'reset-password': null }, queryParamsHandling: 'merge' });
  }

  async resetPasswordSubmit(): Promise<void> {
    if (this.resetPasswordForm.valid) {
      this.isSubmitting = true;
      console.log('Reset Password:', this.resetPasswordForm.value);
      try {
        const newPassword = this.resetPasswordForm.value.password;
        await this.userApi.resetPasswordByToken(this.resetPasswordToken, newPassword).toPromise();
        notify('Password reset successfully', 'success', 5000);
        this.hideResetPasswordForm();
      } catch (err) {
        this.error = err;
      }
      this.isSubmitting = false;
    }
  }

  // Custom validator to check if passwords match
  private passwordsMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password').value;
      const passwordConfirmation = control.get('passwordConfirmation').value;

      return password && passwordConfirmation && password !== passwordConfirmation ? { passwordsMismatch: true } : null;
    };
  }

  private buildForm(): void {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      mfaSecret: [''],
      mfaToken: [''],
      mfaRememberForThisBrowser: [''],
      browserFingerprint: getBrowserFingerprint(),
    });

    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.resetPasswordForm = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirmation: ['', [Validators.required, Validators.minLength(8)]],
      },
      { validators: this.passwordsMatchValidator() },
    );
  }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MyCommonModule } from '../../shared/modules/my-common/my-common.module';
import { UiModule } from '../../shared/modules/ui/ui.module';
import { SignaturesValidationComponent } from './components/service-validation/signatures-validation/signatures-validation.component';
import { OperationsComponent } from './containers/operations/operations.component';
import { SignaturesComponent } from './components/signatures/signatures.component';
import { DayServicesComponent } from './components/day-services/day-services.component';
import { ConsumerModule } from '../consumer/consumer.module';
import { DlgEditValidationStateComponent } from './dialogs/dlg-edit-validation-state/dlg-edit-validation-state.component';
import { ExportsConsistencyCheckComponent } from './components/exports-consistency-check/exports-consistency-check.component';
import { ConsistencyCheck1Component } from './components/consistency-check/consistency-check1/consistency-check1.component';
import { ConsistencyCheck2Component } from './components/consistency-check/consistency-check2/consistency-check2.component';
import { ConsistencyCheck3Component } from './components/consistency-check/consistency-check3/consistency-check3.component';
import { ConsistencyCheck4Component } from './components/consistency-check/consistency-check4/consistency-check4.component';
import { ConsistencyCheck5Component } from './components/consistency-check/consistency-check5/consistency-check5.component';
import { ConsistencyCheck6Component } from './components/consistency-check/consistency-check6/consistency-check6.component';
import { TripTraceComponent } from './components/trip-trace/trip-trace.component';
import { MtmApiLogsComponent } from './components/mtm-api-logs/mtm-api-logs.component';
import { ServiceValidationV2Component } from './components/service-validation/service-validation-v2/service-validation-v2.component';
import { DlgUnlockServiceComponent } from './dialogs/dlg-unlock-service/dlg-unlock-service.component';
import { AuthenticationsLogComponent } from './components/authentications-log/authentications-log.component';
import { SignDetailsComponent } from './components/sign-details/sign-details.component';
import { TardinessLogComponent } from './components/tardiness-log/tardiness-log.component';
import { GMapLogComponent } from './components/gmap-log/gmap-log.component';
import { TripsLogComponent } from './components/trips-log/trips-log.component';
import { KeyPerformanceIndicatorsComponent } from './components/key-performance-indicators/key-performance-indicators.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MyCommonModule,
    UiModule,
    NgxJsonViewerModule,
    ConsumerModule,
  ],
  declarations: [
    AuthenticationsLogComponent,
    GMapLogComponent,
    OperationsComponent,
    SignaturesComponent,
    DayServicesComponent,
    SignaturesValidationComponent,
    DlgEditValidationStateComponent,
    ExportsConsistencyCheckComponent,
    ConsistencyCheck1Component,
    ConsistencyCheck2Component,
    ConsistencyCheck3Component,
    ConsistencyCheck4Component,
    ConsistencyCheck5Component,
    ConsistencyCheck6Component,
    TripTraceComponent,
    MtmApiLogsComponent,
    KeyPerformanceIndicatorsComponent,
    TripsLogComponent,
    TardinessLogComponent,
    ServiceValidationV2Component,
    DlgUnlockServiceComponent,
    SignDetailsComponent,
  ],
  exports: [DlgEditValidationStateComponent, SignDetailsComponent],
  entryComponents: [DlgEditValidationStateComponent, DlgUnlockServiceComponent],
})
export class OperationsModule {}


import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { browserApiErrorsIntegration, init } from '@sentry/browser';
//
import { baseZIndex } from 'devextreme/ui/overlay';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
// import {SocketIoModule} from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store.module';
import { API_BASE_URL, API_VERSION, DEFAULT_ERROR_HANDLER_PROVIDER, HTTP_INTERCEPTOR_PROVIDERS } from './config';
import { AppComponent } from './core/containers/app/app.component';
//
import { CoreModule } from './core/core.module';
import { AlertModule } from './modules/alert/alert.module';
import { BillingModule } from './modules/billing/billing.module';
import { ConsumerModule } from './modules/consumer/consumer.module';
import { DocumentTypeModule } from './modules/document-type/document-type.module';
import { DocumentModule } from './modules/document/document.module';
import { EmployeeModule } from './modules/employee/employee.module';
import { FacilityModule } from './modules/facility/facility.module';
import { FleetModule } from './modules/fleet/fleet.module';
import { ImportModule } from './modules/import/import.module';
import { LogModule } from './modules/log/log.module';
import { PersModule } from './modules/pers/pers.module';
import { PersonModule } from './modules/person/person.module';
import { ReportsModule } from './modules/reports/reports.module';
import { ServiceAuthorizationModule } from './modules/service-authorization/service-authorization.module';
import { SettingsModule } from './modules/settings/settings.module';
import { SignModule } from './modules/sign/sign.module';
import { StatsModule } from './modules/stats/stats.module';
import { SuModule } from './modules/su/su.module';
import { TripManifestModule } from './modules/trip-manifest/trip-manifest.module';
import { UsersModule } from './modules/users/users.module';
import { VehicleModule } from './modules/vehicle/vehicle.module';
import { VehicleLocationModule } from './modules/vehicle-location/vehicle-location.module';
// shared
import { LoopBackConfig } from './shared/sdk';
import { ExtSdkModule } from './shared/modules/ext-sdk/ext-sdk.module';
import { MyCommonModule } from './shared/modules/my-common/my-common.module';
// import {IoService} from './shared/modules/my-common/services/io.service.ts';
import { UiModule } from './shared/modules/ui/ui.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SwUpdatesModule } from './sw-updates/sw-updates.module';
import config from 'devextreme/core/config';
import { locale } from 'devextreme/localization';
import first_day_of_week_data from 'devextreme/localization/cldr-data/first_day_of_week_data';
import { OperationsModule } from './modules/operations/operations.module';
import moment from 'moment';
import isEmpty from 'lodash-es/isEmpty';
import { TripsAuditModule } from './modules/trips-audit/trips-audit.module';
import { VehicleBackupModule } from './modules/vehicle-backup/vehicle-backup.module';
import { VehicleInMaintenanceModule } from './modules/vehicle-in-maintenance/vehicle-in-maintenance.module';
import { VehicleHRCertsModule } from './modules/vehicle-hr-certs/vehicle-hr-certs.module';
import { VehicleUtilisation2Module } from './modules/vehicle-utilisation2/vehicle-utilisation2.module';
import { ScheduleModule } from './modules/schedule/schedule.module';
import { AutoDispatchModule } from './modules/auto-dispatch/auto-dispatch.module';
import { CloneLogModule } from './modules/clone-log/clone-log.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    ExtSdkModule.forRoot(),
    MyCommonModule.forRoot(),
    UiModule.forRoot(),

    SwUpdatesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // rename to `service-worker.js` for pusher beam support
    // ServiceWorkerModule.register('service-worker.js', { enabled: environment.production }),

    // SocketIoModule.forRoot(IoService.config),

    AppStoreModule.forRoot(),

    CoreModule.forRoot(),
    SignModule,
    SettingsModule,
    ScheduleModule,
    OperationsModule,
    ImportModule,
    ReportsModule,
    BillingModule,
    ServiceAuthorizationModule,
    LogModule,
    FacilityModule,
    DocumentTypeModule,
    DocumentModule,
    PersonModule,
    EmployeeModule,
    VehicleModule,
    VehicleBackupModule,
    VehicleInMaintenanceModule,
    VehicleLocationModule,
    VehicleHRCertsModule,
    VehicleUtilisation2Module,
    AutoDispatchModule,
    CloneLogModule,
    TripsAuditModule,
    ConsumerModule,
    TripManifestModule,
    UsersModule,
    AlertModule,
    StatsModule,
    FleetModule,
    SuModule,
    PersModule,

    AppRoutingModule, // Notice that the AppRoutingModule is last
  ],
  providers: [
    Title,
    // INIT_API_CONFIGURATION,
    HTTP_INTERCEPTOR_PROVIDERS,
    // DEFAULT_ERROR_HANDLER_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// region config

if (!isEmpty(environment.sentryDsn)) {
  init({
    dsn: environment.sentryDsn,
    environment: environment.production ? 'production' : 'development',
    tracesSampleRate: Number(environment.tracesSampleRate || 0.1),
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [
      browserApiErrorsIntegration({
        XMLHttpRequest: false,
      }),
      // new TryCatch({
      //   XMLHttpRequest: false,
      // }),
    ],
  });
}

// devextreme config

first_day_of_week_data.en = 1;
// locale('en');
// config({forceIsoDateParsing: false});
console.log('devextreme config:', locale(), config());

// moment js config

moment.updateLocale('en', {
  week: {
    dow: 1, // First day of week is Monday
    doy: 6, // First week of year must contain 2 January (7 + 1 - 2)
  },
});
moment.locale('en');
console.log('moment js config:', moment.locale());

// server sdk config

LoopBackConfig.setDebugMode(!environment.production);
LoopBackConfig.setRequestOptionsCredentials(true);
LoopBackConfig.setBaseURL(API_BASE_URL);
LoopBackConfig.setApiVersion(API_VERSION);
LoopBackConfig.setAuthPrefix('');
LoopBackConfig.filterOnUrl();
LoopBackConfig.whereOnUrl();

// theme config

baseZIndex(1500);
// themes.current(window.localStorage.getItem('dx-theme') || 'generic.light.compact');

// endregion

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-\u0441om-\u0441enter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../com-center/com-center.component.ngfactory";
import * as i3 from "../../../com-center/com-center.component";
import * as i4 from "../../../../shared/sdk/services/custom/logger.service";
import * as i5 from "../../../../shared/modules/my-common/services/config.service";
import * as i6 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i7 from "./schedule-\u0441om-\u0441enter.component";
var styles_ScheduleComCenterComponent = [i0.styles];
var RenderType_ScheduleComCenterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleComCenterComponent, data: {} });
export { RenderType_ScheduleComCenterComponent as RenderType_ScheduleComCenterComponent };
export function View_ScheduleComCenterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-com-center", [["domain", "Scheduling"]], null, null, null, i2.View_ComCenterComponent_0, i2.RenderType_ComCenterComponent)), i1.ɵdid(1, 245760, null, 0, i3.ComCenterComponent, [i4.LoggerService, i5.ConfigService, i6.DataSourceService], { domain: [0, "domain"], typeOptions: [1, "typeOptions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Scheduling"; var currVal_1 = _co.typeOptions; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ScheduleComCenterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "enter", [], null, null, null, View_ScheduleComCenterComponent_0, RenderType_ScheduleComCenterComponent)), i1.ɵdid(1, 638976, null, 0, i7.ScheduleComCenterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduleComCenterComponentNgFactory = i1.ɵccf("app-schedule-\u0441om-\u0441enter", i7.ScheduleComCenterComponent, View_ScheduleComCenterComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { ScheduleComCenterComponentNgFactory as ScheduleComCenterComponentNgFactory };

import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import {
  Consumer,
  ConsumerApi,
  DriverSchedule,
  DriverScheduleApi,
  Employee,
  EmployeeDayService,
  EmployeeDayServiceApi,
  Facility,
  FacilityApi,
  LoggerService,
  LoopBackAuth,
  Signature,
  SignatureApi,
  TripManifest,
  TripManifestApi,
  VehicleCheckUp,
  VehicleCheckUpApi,
} from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import moment from 'moment';
import { ScheduleFormComponent } from '../schedule-form/schedule-form.component';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { get } from 'lodash-es';
import { HelperService } from '../../services/helper.service';
import { DlgManifestGridComponent } from '../dlg-manifest-grid/dlg-manifest-grid.component';
import { BACKUP_DRIVER, FLEET_MANAGER, SCHEDULE_MODES, SCHEDULE_STATUSES } from '../../classes/enums';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { DlgAddEmployeeBackupComponent } from '../dlg-add-employee-backup/dlg-add-employee-backup.component';
import { environment } from 'src/environments/environment';
import { asShortDate, asWeekday } from 'src/app/shared/classes/utils/time.utils';
import { DxPopupComponent } from 'devextreme-angular';
import { DlgCalendarComponent } from '../dlg-calendar/dlg-calendar.component';
import { confirm } from 'devextreme/ui/dialog';
import { DlgScheduleHistoryGridComponent } from '../dlg-schedule-history-grid/dlg-schedule-history-grid.component';
import { DlgSendSchedulesComponent } from '../dlg-send-schedules/dlg-send-schedules.component';

const BASE_LOCATION: any = environment.baseLocation && JSON.parse(environment.baseLocation);

@Component({
  selector: 'app-schedule-grid',
  templateUrl: './schedule-grid.component.html',
  styleUrls: ['./schedule-grid.component.scss'],
  providers: [EmployeeHelperService, HelperService],
})
export class ScheduleGridComponent extends ABaseComponent implements OnInit {
  @ViewChild('popupClone', { static: false }) popupClone: DxPopupComponent;
  dso: DataSourceOptions;
  selectedDate: Date = new Date();
  selectedRows: any[] = [];
  calendarMap: { [id: number]: number } = {};
  facilities: Facility[];
  facilityMap: { [id: number]: Facility };
  statuses = SCHEDULE_STATUSES;
  modes = SCHEDULE_MODES;
  modesLookup = [...this.modes, { ID: 'NO_SCHEDULE', Name: 'NO SCHEDULE' }];
  isBackUpEmployeeOnly = false;
  notBackUpEmployees = [];
  rows: any[] = [];

  isToastVisible = false;
  toastMessage = '';
  toastType = 'success'; // can be 'info', 'warning', 'error', or 'success'
  formLoaded = false;
  progress = 0;
  progressCount = 0;
  progressTotal = 0;
  startPrepareDuration = 40;
  manifestExistsDS = [
    { ID: true, Name: 'Yes' },
    { ID: false, Name: 'Not' },
  ];

  @Output() mySelected: EventEmitter<Employee[]> = new EventEmitter<Employee[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dss: DataSourceService,
    public employeeHelper: EmployeeHelperService,
    public helper: HelperService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
    private router: Router,
    @Inject(LoopBackAuth) private auth: ExtLoopBackAuth,
  ) {
    super(logger);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isBackUpEmployeeOnly = this.router.url.includes('backup-drivers');
    this.buildDso();
    if (!this.isBackUpEmployeeOnly) this.buildCalendarMap();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  showToasts(msg, type = 'success') {
    this.toastMessage = msg;
    this.toastType = type;
    this.isToastVisible = true;
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  getMapURL = v => {
    const location = `${v.latitude},${v.longitude}`;
    return `https://maps.google.com/?q=${location}&ll=${location}&z=11`;
  };

  formatTime = v => (v ? moment(v, 'HH:mm:ss').format('LT') : '');
  startTimeCalculatedAt = v => (v ? `(Calculated at: ${moment(v).format('M/D/YYYY LT')})` : '');

  async status_onValueChanged(e, data) {
    const { schedule, tenantId, facilityId: fid, id } = data;
    const d: any = { status: e.value };
    if (d.status === BACKUP_DRIVER) {
      d.startTime = '06:00:00';
      if (BASE_LOCATION) {
        d.startLocationAddress = BASE_LOCATION.address;
        d.startLocationCoords = BASE_LOCATION.coords;
      }
    }
    let sch = null;
    if (!schedule) {
      const date = `${moment(this.selectedDate).format('YYYY-MM-DD')}T16:00:00.000Z`;
      const facilityId = fid || tenantId || this.auth.getCurrentTenant();
      sch = await this.dss
        .getApi<DriverScheduleApi>(DriverSchedule)
        .create({ driverId: id, facilityId, date, ...d })
        .toPromise();
    } else sch = await this.dss.getApi<DriverScheduleApi>(DriverSchedule).patchAttributes(schedule.id, d).toPromise();
    data.schedule = sch;
    if (this.isBackUpEmployeeOnly) this.grid.instance.refresh();
  }

  async mode_onValueChanged(e, data) {
    const { schedule } = data;
    const d: any = { mode: e.value };
    const sch = await this.dss.getApi<DriverScheduleApi>(DriverSchedule).patchAttributes(schedule.id, d).toPromise();
    data.schedule = sch;
  }

  grid_onEditingStart(e: any): void {
    e.cancel = true;
    const d = moment(this.selectedDate).format('M/D/YYYY');
    const n = this.employeeHelper.displayExpr(e.data);
    const m = e.data.manifest;
    const v = (m && m.firstTrip && `, Vehicle: #${e.data.internalId}`) || '';
    const f = (e.data.facility && `, from Manifest - Facility: ${e.data.facility.shortname}`) || '';
    const title = `Schedule for ${d} - ${n}${f}${v}`;
    this.ui
      .openEditDialog(
        {
          modelId: (e.data.schedule && e.data.schedule.id) || null,
          inputs: {
            data: e.data,
            selectedDate: this.selectedDate,
            facilities: this.facilities,
            facilityMap: this.facilityMap,
          },
          ModelClass: DriverSchedule,
          FormComponentClass: ScheduleFormComponent,
          title,
        },
        { minWidth: 1200 },
      )
      .afterClosed()
      .toPromise()
      .then(schedule => {
        if (schedule) e.data.schedule = schedule;
      });
  }

  grid_onToolbarPreparing(e) {
    if (this.isBackUpEmployeeOnly)
      e.toolbarOptions.items.unshift({
        name: 'newBackUpEmployee',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'inMenu',
        options: {
          icon: 'fas fa-plus',
          text: 'Change Employee Status to “Backup”',
          hint: 'Change Employee Status to “Backup”',
          onClick: this.grid_toolbar_newBackUpEmployee_onClick.bind(this),
        },
      });
    else {
      e.toolbarOptions.items.unshift(
        {
          name: 'recalculateStartTime',
          locateInMenu: 'auto',
          widget: 'dxButton',
          location: 'after',
          sortIndex: 30,
          showText: 'always',
          options: {
            text: 'Re-calculate Start Time For All',
            onClick: this.grid_toolbar_recalculateStartTime_onClick.bind(this),
          },
        },
        {
          name: 'cloneSchedules',
          locateInMenu: 'auto',
          widget: 'dxButton',
          location: 'after',
          sortIndex: 30,
          showText: 'always',
          options: { text: `Clone Schedules`, onClick: this.cloneSchedules_onClick.bind(this) },
        },
        {
          name: 'dropAllSchedules',
          locateInMenu: 'auto',
          widget: 'dxButton',
          location: 'after',
          // sortIndex: 99,
          showText: 'inMenu',
          options: {
            type: 'danger',
            icon: 'trash',
            text: 'Drop All Employee Schedules',
            hint: 'Drop All Employee Schedules',
            onClick: this.dropAllSchedules_onClick.bind(this),
          },
        },
      );
    }
  }

  grid_toolbar_newBackUpEmployee_onClick() {
    const dialogRef = this.dialog.open<DlgAddEmployeeBackupComponent>(DlgAddEmployeeBackupComponent, {
      minWidth: 650,
      data: { notBackUpEmployees: this.notBackUpEmployees },
    });

    dialogRef
      .afterClosed()
      .pipe(
        tap(selected => {
          if (selected) this.status_onValueChanged({ value: BACKUP_DRIVER }, selected);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  async recalculateStartTime_onClick(data: any) {
    if (this.helper.isGoodToRecalculateStartTime(data)) {
      data.schedule = await this.helper.updateStartTime(data, this.selectedDate, this.startPrepareDuration);
      this.showToasts(`Re-calculated Start Time for ${this.employeeHelper.displayExpr(data)}`);
    } else this.showToasts(`Start Time is already re-calculated for ${this.employeeHelper.displayExpr(data)}`, 'info');
  }

  async history_onClick(data: any) {
    const d = moment(this.selectedDate).format('M/D/YYYY');
    const title = `Employee Scheduling History for ${this.employeeHelper.displayExpr(data.person)} (${d})`;
    void this.dialog.open<any, any, number>(DlgScheduleHistoryGridComponent, {
      hasBackdrop: true,
      data: { title, row: data },
      minWidth: 1200,
    });
  }

  async sendSchedules_onClick(preparedDataSchedules: any[]) {
    const data = { preparedDataSchedules, day: asShortDate(this.selectedDate) };
    const res = await this.dialog
      .open(DlgSendSchedulesComponent, { hasBackdrop: true, data })
      .afterClosed()
      .toPromise();
    if (!res) return;
    const { sentCount } = await this.dss
      .getApi<DriverScheduleApi>(DriverSchedule)
      .sendSchedule(preparedDataSchedules)
      .toPromise();
    this.showToasts(`Sent Schedules ${sentCount} of ${preparedDataSchedules.length} successfully`);
  }

  dropAllSchedules_onClick() {
    if (moment(this.selectedDate).isBefore(moment().startOf('day')))
      return this.showToasts('Cannot drop schedules for past dates', 'error');

    const date = moment(this.selectedDate).format('M/D/YYYY');
    confirm(`Are you sure you want to drop all employee schedules for ${date}?`, 'Confirm selection').then(
      async dialogResult => {
        if (dialogResult) {
          this.ui.showLoading();
          const d = asShortDate(this.selectedDate);
          await this.dss.getApi<DriverScheduleApi>(DriverSchedule).dropAll(d).toPromise();
          this.ui.hideLoading();
          this.showToasts(`Successfully Dropped All Schedules for ${date}`);
          this.buildCalendarMap();
          this.grid.instance.refresh();
        }
      },
    );
  }
  async cloneSchedules_onClick() {
    const actionLabels = {
      prev: `Clone latest ${asWeekday(this.selectedDate)}`,
      date: 'Clone from the other date',
      cancel: 'Cancel',
    };
    const actions = [actionLabels.prev, actionLabels.date, actionLabels.cancel];
    const doActionAsync = async action => {
      this.popupClone.instance.hide();
      const clone = async (from: Date, to: Date) => {
        this.ui.showLoading();
        await this.dss.getApi<DriverScheduleApi>(DriverSchedule).clone(asShortDate(from), asShortDate(to)).toPromise();
        this.ui.hideLoading();
        this.showToasts(`Successfully Cloned Schedules for ${asWeekday(to)}`);
        this.buildCalendarMap();
        this.grid.instance.refresh();
      };
      const getDate = async (act, acls) => {
        switch (act) {
          case acls.prev:
            return moment(this.selectedDate).subtract(7, 'day').toDate();
          case acls.date: {
            const data = { calendarMap: this.calendarMap, length: this.rows.length };
            return await this.dialog.open(DlgCalendarComponent, { hasBackdrop: true, data }).afterClosed().toPromise();
          }
        }
      };
      const date = await getDate(action, actionLabels);
      if (date) await clone(date, this.selectedDate);
    };

    this.popupClone.instance.beginUpdate();
    this.popupClone.container = this.grid.instance.element();
    this.popupClone.position = { my: 'center', at: 'center', of: `center` || null };
    this.popupClone.toolbarItems = actions.map(action => ({
      widget: 'dxButton',
      toolbar: 'bottom',
      location: 'center',
      options: { type: 'default', text: action, onClick: () => doActionAsync(action) },
    }));
    this.popupClone.instance.show();
    this.popupClone.instance.endUpdate();
  }

  async grid_toolbar_recalculateStartTime_onClick() {
    const isGood = d => get(d, `schedule.mode`) === 'AUTO' && this.helper.isGoodToRecalculateStartTime(d);
    const selectedRows = this.rows.filter(isGood);
    this.progressTotal = selectedRows.length;
    for (const [i, d] of selectedRows.entries()) {
      d.schedule = await this.helper.updateStartTime(d, this.selectedDate, this.startPrepareDuration);
      this.progressCount = i + 1;
      this.progress = (this.progressCount / this.progressTotal) * 100;
    }
    if (this.progressTotal) {
      this.showToasts(`Successfully Re-calculated Start Time for ${this.progressTotal} employees`);
    } else this.showToasts(`Start Time is already re-calculated for all employees`, 'info');
    this.progress = 0;
  }

  async manifest_onClick(data: any) {
    const cIds = data.manifest.trips.reduce((p, { c }) => ({ ...p, [c]: 1 }), {});
    const consumers = await this.dss
      .getApi<ConsumerApi>(Consumer)
      .find<Consumer>(
        { where: { id: { inq: Object.keys(cIds) } }, include: [{ person: { contact: ['addresses', 'phones'] } }] },
        headersAllTenantsAppend,
      )
      .toPromise();
    const consumersMap = consumers.reduce((p, c) => ({ ...p, [c.id]: c }), {});
    const trips = [...data.manifest.trips.sort(({ t: t1 }, { t: t2 }) => (t1 < t2 ? -1 : t1 > t2 ? 1 : 0))];
    const manifest = { ...data.manifest, trips };
    const schedule = data.schedule;
    const d = moment(this.selectedDate).format('M/D/YYYY');
    const n = this.employeeHelper.displayExpr(data);
    let [start, finish] = ['', ''];
    if (schedule) {
      if (schedule.startTime) start = `, Work Start Time: ${moment(schedule.startTime, 'HH:mm:ss').format('LT')}`;
      if (schedule.finishTime) finish = `, Work Finish Time: ${moment(schedule.finishTime, 'HH:mm:ss').format('LT')}`;
    }
    const facilityName = data.facility && data.facility.name;
    const title = `${facilityName} Manifest for ${d} - ${n}${start}${finish}`;

    void this.dialog.open<any, any, number>(DlgManifestGridComponent, {
      hasBackdrop: true,
      data: { title, manifest, consumersMap },
      minWidth: 1200,
    });
  }

  async grid_onRowRemoving(e: any) {
    e.cancel = true;
    if (!e.data.schedule) return;
    await this.dss.getApi<DriverScheduleApi>(DriverSchedule).deleteById(e.data.schedule.id).toPromise();
    e.data.schedule = null;
  }

  grid_onCellPrepared(e: any) {
    if (e.rowType === 'data' && e.column.command === 'select') {
      if (!e.data.schedule || !e.data.schedule.startTime) {
        e.cellElement.innerHTML = '-';
        e.cellElement.title = 'No Schedule for this Employee';
      }
    }
  }

  grid_onSelectionChanged(e: any): void {
    this.selectedRows = e.selectedRowsData.filter(r => r.schedule && r.schedule.startTime);
    this.mySelected.emit(e.selectedRowsData);
  }

  calendar_onValueChanged(e) {
    this.grid.instance.refresh();
  }

  calendarClass = ({ date, view }) => {
    const l = this.rows.length;
    if (view !== 'month' || this.isBackUpEmployeeOnly || !l) return '';
    const colors = [
      { cls: 'day-green', rate: 0.95 },
      { cls: 'day-yellow', rate: 0.8 },
      { cls: 'day-orange', rate: 0.5 },
      { cls: 'day-red', rate: 0.01 },
    ];
    const d = moment(date).format('YYYY-MM-DD');
    const r = (this.calendarMap[d] || 0) / l;
    return (colors.find(({ rate }) => r >= rate) || { cls: '' }).cls;
  };

  calendarTitle = ({ date, view }) => {
    const l = this.rows.length;
    if (view !== 'month' || this.isBackUpEmployeeOnly || !l) return '';
    const d = moment(date).format('YYYY-MM-DD');
    const c = this.calendarMap[d] || 0;
    const p = Math.ceil((c / l) * 100);
    return `${p > 100 ? 100 : p}%`;
  };

  getPhoneCellValue = (e: Employee) => {
    const phones = e.person.contact.phones || [];
    const phone = phones.find(p => (p.label || '').toLowerCase() == 'main') || phones[0];
    return (phone && phone.value) || '';
  };
  getManifestText = ({ manifest: m }) => (m ? `Manifest` : '');

  getFirstTripLocation = ({ firstTripConsumer: c }) =>
    this.helper.getFullAddress(get(c, `person.contact.addresses[0]`));
  getLastTripLocation = ({ lastTripConsumer: c }) => this.helper.getFullAddress(get(c, `person.contact.addresses[0]`));

  async buildDso() {
    const { _id: id, ...aSch }: any =
      (await gqlMongoLoad(this.dss, 'AutoSchedulingSettings', {}, []).pipe().toPromise())[0] || {};
    this.startPrepareDuration = aSch.startPrepareDuration || this.startPrepareDuration;

    const so = this.dss.getStoreOptions(Employee, Employee, false);
    so.customFilter = {
      where: { status: 'Active', employeePositionId: { inq: [39, 40, 273] } },
      include: [{ person: { contact: ['phones'] } }],
    };
    const store = new CustomStore(so);
    dxStoreLoadHooks(store, undefined, async (args: any[], [empls]: any[]) => {
      empls = empls.sort((e1, e2) => {
        const n1 = this.employeeHelper.displayExpr(e1);
        const n2 = this.employeeHelper.displayExpr(e2);
        return n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
      });
      const date = moment(this.selectedDate).format('YYYY-MM-DD');
      const [driverSchedules, dayService, checkins, signatures, vehicleGeotabs] = await Promise.all(
        [
          this.dss
            .getApi<DriverScheduleApi>(DriverSchedule)
            .getDriverSchedulesWithDetails(date, headersAllTenantsAppend),
          ...(this.isBackUpEmployeeOnly
            ? [
                this.dss
                  .getApi<EmployeeDayServiceApi>(EmployeeDayService)
                  .find<EmployeeDayService>(
                    { where: { employeeId: { inq: empls.map(e => e.id) }, date, marker: 'PUNCH-IN:1' } },
                    headersAllTenantsAppend,
                  ),
                this.dss.getApi<VehicleCheckUpApi>(VehicleCheckUp).find<VehicleCheckUp>(
                  {
                    where: { employeeId: { inq: empls.map(e => e.id) }, vdate: +date.replace(/\-/g, '') },
                    include: ['vehicle'],
                  },
                  headersAllTenantsAppend,
                ),
                this.dss
                  .getApi<SignatureApi>(Signature)
                  .find<Signature>(
                    { where: { employeeId: { inq: empls.map(e => e.id) }, vdate: date } },
                    headersAllTenantsAppend,
                  ),
                this.getLastGeoTabAggregate(),
              ]
            : [[], [], [], []]),
        ].map(p => (Array.isArray(p) ? p : p.toPromise())),
      );
      const { schedules, facilities, manifestsMap, consumers, vehiclesMap } = driverSchedules;
      const schedulesMap = schedules.reduce((p, v) => ({ ...p, [v.driverId]: v }), {});
      if (this.isBackUpEmployeeOnly) {
        this.notBackUpEmployees = empls
          .filter(e => !schedulesMap[e.id] || ![BACKUP_DRIVER, FLEET_MANAGER].includes(schedulesMap[e.id].status))
          .map(e => ({ ...e, schedule: schedulesMap[e.id] }));
        empls = empls.filter(
          e => schedulesMap[e.id] && [BACKUP_DRIVER, FLEET_MANAGER].includes(schedulesMap[e.id].status),
        );
      }

      this.facilities = facilities;
      this.facilityMap = facilities.reduce((p, v) => ({ ...p, [v.id]: v }), {});
      const emplsMap = empls.reduce((p, e) => ({ ...p, [e.id]: e }), {});
      const consumersMap = consumers.reduce((p, v) => ({ ...p, [v.id]: v }), {});
      const dayServiceMap = dayService.reduce((p, v) => ({ ...p, [v.employeeId]: v }), {});
      const checkinsMap = checkins.reduce((p, v) => ({ ...p, [v.employeeId]: v }), {});
      const signaturesMap = signatures.reduce((p, v) => ({ ...p, [v.employeeId]: (p[v.employeeId] || 0) + 1 }), {});
      const vehicleGeotabsMap = vehicleGeotabs.reduce((p, v) => ({ ...p, [v.internalId]: v }), {});

      const res = empls.map(e => {
        const schedule = schedulesMap[e.id];
        if (schedule && !schedule.mode) schedule.mode = 'AUTO';
        const scheduleMode = (schedule && schedule.mode) || 'NO_SCHEDULE';
        const dayService = dayServiceMap[e.id];
        const checkin = checkinsMap[e.id];
        const vehicleGeotab = checkin ? vehicleGeotabsMap[checkin.vehicle.internalId] : null;
        const signatureCount = signaturesMap[e.id] || 0;
        const manifest = manifestsMap[e.id];
        const facilityId = get(manifest, 'firstTrip.facilityId') || (schedule && schedule.startFacilityId);
        const facility = facilityId && this.facilityMap[facilityId];
        const firstTripConsumer = get(manifest, 'firstTrip.c') && consumersMap[manifest.firstTrip.c];
        const lastTripConsumer = get(manifest, 'lastTrip.c') && consumersMap[manifest.lastTrip.c];
        const internalId = get(manifest, 'firstTrip.v') && vehiclesMap[manifest.firstTrip.v];
        const manifestExists = !!manifest;
        const emplPhone = this.getPhoneCellValue(e);
        const firstTripLocaction = this.getFirstTripLocation({ firstTripConsumer });
        const lastTripLocaction = this.getLastTripLocation({ lastTripConsumer });
        const partnerId = (get(manifest, 'trips') || []).flatMap(t => [t.e, t.esc]).find(pId => pId !== e.id);
        const partner = emplsMap[partnerId];
        return {
          ...{ ...e, schedule, scheduleMode, dayService, checkin, vehicleGeotab, signatureCount },
          ...{ manifest, facilityId, facility, firstTripConsumer, lastTripConsumer, manifestExists },
          ...{ emplPhone, firstTripLocaction, lastTripLocaction, internalId, partner },
        };
      });
      this.rows = res;
      return [res];
    });

    this.dso = { store } as DataSourceOptions;
  }

  async buildCalendarMap() {
    const start = moment().subtract(2, 'month').startOf('month').startOf('day').toISOString(true);
    const end = moment().add(1, 'month').endOf('month').endOf('day').toISOString(true);
    const $and = [
      { $gte: ['$date', { $dateFromString: { dateString: start } }] },
      { $lte: ['$date', { $dateFromString: { dateString: end } }] },
    ];
    const stages = [
      { $match: { $expr: { $and }, status: 'DRIVER' } },
      { $group: { _id: { $dateToString: { format: '%Y-%m-%d', date: '$date' } }, count: { $sum: 1 } } },
    ];
    const cnts = await gqlMongoLoad(this.dss, 'DriverSchedule', {}, stages).pipe().toPromise();
    this.calendarMap = cnts.reduce((p, v) => ({ ...p, [v._id]: v.count }), {});
  }

  getLastGeoTabAggregate() {
    const month = +moment(this.selectedDate).format('YYYYMM');
    const stages = [{ $match: { month } }, { $project: { internalId: 1, lastPosition: 1 } }];
    return gqlMongoLoad(this.dss, 'VehicleGeotab', {}, stages).pipe();
  }
}

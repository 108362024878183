/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../schedule-grid/schedule-grid.component.ngfactory";
import * as i3 from "../../../employee/services/helper.service";
import * as i4 from "../../../../shared/sdk/services/custom/Employee";
import * as i5 from "../../../../shared/sdk/services/custom/EmployeeView";
import * as i6 from "../../../../shared/sdk/services/custom/Vehicle";
import * as i7 from "../../../../shared/modules/my-common/services/config.service";
import * as i8 from "../../../../shared/modules/my-common/services/common.service";
import * as i9 from "../../../../shared/sdk/services/custom/Facility";
import * as i10 from "../../../../shared/sdk/services/custom/MyFile";
import * as i11 from "../../../../shared/modules/my-common/services/datasource.service";
import * as i12 from "../../services/helper.service";
import * as i13 from "../schedule-grid/schedule-grid.component";
import * as i14 from "../../../../shared/sdk/services/custom/logger.service";
import * as i15 from "../../../../shared/modules/ui/services/ui.service";
import * as i16 from "../../../../shared/modules/ui/services/grid-helper.service";
import * as i17 from "@angular/material/dialog";
import * as i18 from "@angular/router";
import * as i19 from "../../../../shared/sdk/services/core/auth.service";
import * as i20 from "./schedule-list.component";
var styles_ScheduleListComponent = [i0.styles];
var RenderType_ScheduleListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleListComponent, data: {} });
export { RenderType_ScheduleListComponent as RenderType_ScheduleListComponent };
export function View_ScheduleListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-schedule-grid", [], null, [[null, "mySelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mySelected" === en)) {
        var pd_0 = (_co.grid_onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScheduleGridComponent_0, i2.RenderType_ScheduleGridComponent)), i1.ɵprd(512, null, i3.HelperService, i3.HelperService, [i4.EmployeeApi, i5.EmployeeViewApi, i6.VehicleApi, i7.ConfigService, i8.CommonService, i9.FacilityApi, i10.MyFileApi, i11.DataSourceService]), i1.ɵprd(512, null, i12.HelperService, i12.HelperService, [i11.DataSourceService]), i1.ɵdid(3, 245760, null, 0, i13.ScheduleGridComponent, [i14.LoggerService, i7.ConfigService, i15.UiService, i11.DataSourceService, i3.HelperService, i12.HelperService, i16.GridHelperService, i17.MatDialog, i18.Router, i19.LoopBackAuth], null, { mySelected: "mySelected" })], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_ScheduleListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-schedule-list", [], null, null, null, View_ScheduleListComponent_0, RenderType_ScheduleListComponent)), i1.ɵdid(1, 114688, null, 0, i20.ScheduleListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduleListComponentNgFactory = i1.ɵccf("app-schedule-list", i20.ScheduleListComponent, View_ScheduleListComponent_Host_0, {}, { mySelected: "mySelected" }, []);
export { ScheduleListComponentNgFactory as ScheduleListComponentNgFactory };

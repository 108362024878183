import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { get } from 'lodash-es';
import { LoggerService, TripManifestRec } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService } from '../../services/helper.service';
import moment from 'moment';

@Component({
  selector: 'app-dlg-send-schedules',
  templateUrl: './dlg-send-schedules.component.html',
  styleUrls: ['./dlg-send-schedules.component.scss'],
  providers: [ConsumerHelperService, HelperService],
})
export class DlgSendSchedulesComponent implements OnInit {
  title: string = '';
  constructor(
    private dialogRef: MatDialogRef<DlgSendSchedulesComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    public helper: HelperService,
    public consumerHelper: ConsumerHelperService,
  ) {}

  ngOnInit() {
    const day = this.data.day;
    let dayName = '';
    if (moment().format('YYYY-MM-DD') === day) dayName = 'Today';
    if (moment().add(1, 'days').format('YYYY-MM-DD') === day) dayName = 'Tomorrow';

    this.title = `Schedule for ${dayName} (${moment(day).format('ddd M/D/YYYY')}) will be sent to ${this.data.preparedDataSchedules.length} employees:`;
  }

  emplName = (person: any): string => `${person.firstname} ${person.lastname}`;
  startTime = (schedule: any): string => moment(schedule.startTime, 'HH:mm').format('h:mma');
  sendSchedules = () => this.dialogRef.close(true);
}


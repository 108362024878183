import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import notify from 'devextreme/ui/notify';
import merge from 'lodash-es/merge';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { PushNotificationsService } from '../../../../shared/modules/ui/services/push-notifications.service';
import { LoggerService } from '../../../../shared/sdk';

enum TYPES {
  L0_FILL_BROKER_TRIPS_DATA = 'L0_FILL_BROKER_TRIPS_DATA',
  L0_ADC_FOLLOWUP = 'L0_ADC_FOLLOWUP',
  L0_GOOD_MORNING = 'L0_GOOD_MORNING',

  L1_MISSING_MANIFEST_REPORT = 'L1_MISSING_MANIFEST_REPORT',
  L1_NON_ADC_NEW_CLIENTS_OR_TRIPS_NOT_ON_MANIFEST_REPORT = 'L1_NON_ADC_NEW_CLIENTS_OR_TRIPS_NOT_ON_MANIFEST_REPORT',
  L1_BASE_TRIPS_BY_IMPORT_DATE_REPORT = 'L1_BASE_TRIPS_BY_IMPORT_DATE_REPORT',
  L1_NOT_ENTERED_CLIENTS_REPORT = 'L1_NOT_ENTERED_CLIENTS_REPORT',
  L1_CANCELLATIONS_REPORT = 'L1_CANCELLATIONS_REPORT',

  L2_MISSING_TRIP_ID_REPORT = 'L2_MISSING_TRIP_ID_REPORT',
  L2_MTM_STANDING_ORDER_EXPORT_REPORT = 'L2_MTM_STANDING_ORDER_EXPORT_REPORT',
  L2_MTM_STANDING_ORDER_EXPORT_REPORT_V2 = 'L2_MTM_STANDING_ORDER_EXPORT_REPORT_V2',
  L2_CTS_STANDING_ORDER_EXPORT_REPORT = 'L2_CTS_STANDING_ORDER_EXPORT_REPORT',
  L2_MISSING_MCI__MA_ID_REPORT = 'L2_MISSING_MCI__MA_ID_REPORT',
  L2_OTHER_COMP_OUR_TRIP_ID_REPORT = 'L2_OTHER_COMP_OUR_TRIP_ID_REPORT',
  L2_ACTIVE_CLIENTS_NOT_ASSIGNED_ON_MANIFEST = 'L2_ACTIVE_CLIENTS_NOT_ASSIGNED_ON_MANIFEST',
  L2_MTM_DENIED_TRIPS_REPORT = 'L2_MTM_DENIED_TRIPS_REPORT',
  L2_MCO_CHANGES_AND_DISCHARGES_REPORT = 'L2_MCO_CHANGES_AND_DISCHARGES_REPORT',
  L2_ADC_TRIPS_NOT_ON_MANIFEST_REPORT = 'L2_ADC_TRIPS_NOT_ON_MANIFEST_REPORT',
  L2_NON_PACHC_NOT_ACTIVE_TRIP_IDs = 'L2_NON_PACHC_NOT_ACTIVE_TRIP_IDs',

  L3_NON_CHC_CLIENTS_FOLLOW_UP = 'L3_NON_CHC_CLIENTS_FOLLOW_UP',
  L3_CLIENT_STATUS_FOLLOW_UP = 'L3_CLIENT_STATUS_FOLLOW_UP',
  L3_DATA_VERIFICATION = 'L3_DATA_VERIFICATION',
  L3_MULTI_ADC_CLIENTS = 'L3_MULTI_ADC_CLIENTS',
  L3_CANCELLATIONS_PIVOT = 'L3_CANCELLATIONS_PIVOT',
  L3_NEW_MONTH_TERMINATIONS = 'L3_NEW_MONTH_TERMINATIONS',
  L3_CLIENT_ELIGIBILITY = 'L3_CLIENT_ELIGIBILITY',
  L3_PORTAL_VS_CLIENTS_SHEET_DISCREPANCIES = 'L3_PORTAL_VS_CLIENTS_SHEET_DISCREPANCIES',
  L3_ACTIVE_CLIENTS_NEVER_ASSIGNED_ON_MANIFEST = 'L3_ACTIVE_CLIENTS_NEVER_ASSIGNED_ON_MANIFEST',
  L3_NOT_ATTENDING_CLIENTS_WITH_TRIP_ID = 'L3_NOT_ATTENDING_CLIENTS_WITH_TRIP_ID',
  L3_CLIENTS_WITH_EXTRA_TRIPS = 'L3_CLIENTS_WITH_EXTRA_TRIPS',

  L4_MTM_CLAIMS_TO_BILL_REPORT = 'L4_MTM_CLAIMS_TO_BILL_REPORT',
  L4_CTS_CLAIMS_TO_BILL_REPORT = 'L4_CTS_CLAIMS_TO_BILL_REPORT',
  L4_MTM_BY_FACILITY_CLAIMS_TO_BILL_REPORT = 'L4_MTM_BY_FACILITY_CLAIMS_TO_BILL_REPORT',
  L4_CTS_BY_FACILITY_CLAIMS_TO_BILL_REPORT = 'L4_CTS_BY_FACILITY_CLAIMS_TO_BILL_REPORT',
  L4_MTM_BY_FACILITY_ECLAIMS_TO_BILL_REPORT = 'L4_MTM_BY_FACILITY_ECLAIMS_TO_BILL_REPORT',
  L4_CTS_BY_FACILITY_ECLAIMS_TO_BILL_REPORT = 'L4_CTS_BY_FACILITY_ECLAIMS_TO_BILL_REPORT',
  L4_UNUSED_TRIPS = 'L4_UNUSED_TRIPS',
  L4_UNBILLED_SERVICES = 'L4_UNBILLED_SERVICES',
  L4_ALL_SERVICES = 'L4_ALL_SERVICES',

  L4_BILLING_STATUS_REPORT = 'L4_BILLING_STATUS_REPORT',
  L4_MTM_TRIPS_IMPORT_UNITS_WITH_NO_TRIP_ID_REPORT = 'L4_MTM_TRIPS_IMPORT_UNITS_WITH_NO_TRIP_ID_REPORT',
  L4_MTM_ALL_CLIENTS_VISITED_REPORT = 'L4_MTM_ALL_CLIENTS_VISITED_REPORT',
  L4_CTS_TRIPS_IMPORT_UNITS_WITH_NO_TRIP_ID_REPORT = 'L4_CTS_TRIPS_IMPORT_UNITS_WITH_NO_TRIP_ID_REPORT',
  L4_NEAR_MISS_TRIPS_EXPORT_REPORT = 'L4_NEAR_MISS_TRIPS_EXPORT_REPORT',
  L4_NEAR_MISS_TRIPS_MTM_FILE = 'L4_NEAR_MISS_TRIPS_MTM_FILE',
  L4_SIGNATURES_WITH_NO_TRIP_ID_MTM_FILE = 'L4_SIGNATURES_WITH_NO_TRIP_ID_MTM_FILE',
  L4_UNITS_WITH_NO_TRIP_ID_MTM_FILE = 'L4_UNITS_WITH_NO_TRIP_ID_MTM_FILE',

  L5_TRENDS_REPORT = 'L5_TRENDS_REPORT',
  L5_ALL_CLIENT_DATA = 'L5_ALL_CLIENT_DATA',
  L5_HHAxBROKERxSYSTEM = 'L5_HHAxBROKERxSYSTEM',
  L3_CLIENT_UPDATES = 'L3_CLIENT_UPDATES',
  L5_PORTAL_ACTIVITY = 'L5_PORTAL_ACTIVITY',
  L5_ELIGIBILITY_PIVOT = 'L5_ELIGIBILITY_PIVOT',
  L5_MTM_INTEGRATION_FAILED_SERVICES_REPORT = 'L5_MTM_INTEGRATION_FAILED_SERVICES_REPORT',

  L6_EMPLOYEE_ATTENDANCE_CROSS_CHECK = 'L6_EMPLOYEE_ATTENDANCE_CROSS_CHECK',

  L7_AGING_SUMMARY_REPORT = 'L7_AGING_SUMMARY_REPORT',
  L7_MTM_AGING_SUMMARY_REPORT = 'L7_MTM_AGING_SUMMARY_REPORT',
  L7_MTM_CTS_AGING_SUMMARY_REPORT = 'L7_MTM_CTS_AGING_SUMMARY_REPORT',
  L7_SUBMITTED_CLAIMS_REPORT = 'L7_SUBMITTED_CLAIMS_REPORT',
  L7_SUBMITTED_CLAIMS_REPORT_ALL = 'L7_SUBMITTED_CLAIMS_REPORT_ALL',
  L7_SUBMITTED_CLAIMS_REPORT_ALL_MONTHLY = 'L7_SUBMITTED_CLAIMS_REPORT_ALL_MONTHLY',
  L7_MEALS_SUBMITTED_CLAIMS_REPORT = 'L7_MEALS_SUBMITTED_CLAIMS_REPORT',
  L7_AR_AGING_REPORT = 'L7_AR_AGING_REPORT',
  L7_AR_AGING_APPROVED_ONLY_REPORT = 'L7_AR_AGING_APPROVED_ONLY_REPORT',
  L7_MTM_BILLING_TIMELINE_ANALYSIS = 'L7_MTM_BILLING_TIMELINE_ANALYSIS',
  L7_MERCY_FLEET_RECEIVED_PAYMENTS = 'L7_MERCY_FLEET_RECEIVED_PAYMENTS',
  L7_MERCY_FLEET_RECEIVED_PAYMENTS_2021 = 'L7_MERCY_FLEET_RECEIVED_PAYMENTS_2021',
  L7_MERCY_FLEET_RECEIVED_PAYMENTS_BY_PD = 'L7_MERCY_FLEET_RECEIVED_PAYMENTS_BY_PD',
  L7_SERVICE_MISMATCH_DESTINATIONS = 'L7_SERVICE_MISMATCH_DESTINATIONS',
  L7_SIGNS_COLLECTED_APP = 'L7_SIGNS_COLLECTED_APP',
  L8_TRAILING_YEAR_PERFORMANCE = 'L8_TRAILING_YEAR_PERFORMANCE',
  L8_TRAILING_STAKEHOLDER_INSIGHT = 'L8_TRAILING_STAKEHOLDER_INSIGHT',
  L8_CURRENT_UNITS_VS_X_WEEKS_TRAILING = 'L8_CURRENT_UNITS_VS_X_WEEKS_TRAILING',

  EXPORTS_INCONSISTENCY_CHECK = 'EXPORTS_INCONSISTENCY_CHECK',
}

interface IReportItem {
  type: TYPES;
  roles?: string[];
  settings: {
    forPeriod?: boolean;
    broker?: string;
    byFacility?: boolean;
    trailingWeeks?: number;
  };
  caption: string;
  description: string;
  notification?: { title: string; opts?: NotificationOptions };
}

interface IReportItemGroup {
  text?: string;
  roles: string[];
  description?: string;
  items: IReportItem[];
}

@Component({
  selector: 'app-reports-form',
  templateUrl: './reports-form.component.html',
  styleUrls: ['./reports-form.component.scss'],
})
export class ReportsFormComponent extends ABaseComponent implements OnInit, AfterViewInit {
  selectedFromValue: Date;
  selectedToValue: Date;
  tenantType$: Observable<string>;

  @ViewChild('from', { static: true }) fromDateBox: DxDateBoxComponent;
  @ViewChild('to', { static: true }) toDateBox: DxDateBoxComponent;

  items: Array<IReportItemGroup> = [
    {
      roles: ['SU', 'BILLER', 'MANAGER'],
      items: [
        {
          type: TYPES.L0_FILL_BROKER_TRIPS_DATA,
          settings: {
            forPeriod: true,
          },
          caption: 'Fill Broker Data',
          description:
            'If MTM/CTS Exports files uploaded,' +
            ' use this action to immediately assign TripIDs for selected period,' +
            ' otherwise uploaded files will be processed nightly at 3:00 AM.',
        },
        {
          type: TYPES.L0_ADC_FOLLOWUP,
          settings: {
            forPeriod: true,
          },
          caption: 'L0 - ADC FOLLOWUP',
          description: '',
        },
        {
          type: TYPES.L0_GOOD_MORNING,
          settings: {
            forPeriod: true,
          },
          caption: 'L0 - GOOD MORNING',
          description: '',
        },
        // {
        //   type: TYPES.L1_MISSING_MANIFEST_REPORT,
        //   forPeriod: true,
        //   caption: 'L1 - MISSING MANIFEST REPORT',
        //   description: '',
        // },
      ],
    },

    {
      text: 'Level 1 - Intake Reports',
      roles: ['SU', 'BILLER', 'MANAGER'],
      items: [
        {
          type: TYPES.L1_NON_ADC_NEW_CLIENTS_OR_TRIPS_NOT_ON_MANIFEST_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L1 - NEW BASE TRIPS',
          description:
            'Trips from broker`s export file where delivery address' +
            ' is not one of priority ADC useful to catch random appointment trips.',
        },
        {
          type: TYPES.L1_BASE_TRIPS_BY_IMPORT_DATE_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L1 - NOT ASSIGNED TRIPS BY IMPORT DATE',
          description: '',
        },

        // {
        //   type: 'GENERATE_NON_ADC_UNASSIGNED_REPORTS',
        //   caption: 'Generate NON-ADC Unassigned Reports',
        //   description: 'Trips from MTM Export file where delivery address is not one of priority ADC, ' +
        //     'useful to catch random appointment trips, not entered into manifest.',
        // },

        {
          type: TYPES.L1_NOT_ENTERED_CLIENTS_REPORT,
          settings: {
            forPeriod: false,
          },
          caption: 'L1 - NOT ENTERED CLIENTS',
          description:
            'List of clients from Export file who are not entered into the portal,' +
            ' with possible priority ADC, or clients for whom we never got TripID before.',
        },
        //   {
        //     type: 'GENERATE_NEW_CONSUMERS_REPORT',
        //     caption: 'Generate New Consumer Report',
        //     description: 'Shows Not entered into the portal consumers who have MTM TripID,' +
        //       ' useful to find new consumers on priority ADCs.',
        //     onClick: (e) => this.newConsGen_onClick(e),
        //     notificationOptions: {},
        //   },

        {
          type: TYPES.L1_CANCELLATIONS_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L1 - CANCELLATIONS',
          description:
            'Show all clients who has at least one cancelled TripID for the period.' +
            ' Note: Clients who have non-active TripIDs (in X Status or D Status) with subsequently issued active TripIDs (in S Status)' +
            ' for same day for same client not going to show up in this report.',
        },
      ],
    },

    {
      text: 'Level 2 - OnBoarding Reports',
      roles: ['SU', 'BILLER', 'MANAGER'],
      items: [
        {
          type: TYPES.L2_MISSING_MCI__MA_ID_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - MISSING MCI (MA_ID)',
          description:
            'List of consumers on manifests for selected period with missing MCI (MA ID, Medicaid ID) data.' +
            ' No MCI means billing can`t be done.',
        },

        // {
        //   type: 'GENERATE_NO_MCI_CONSUMERS_REPORT',
        //   caption: 'Generate No MCI Consumer Report',
        //   description: 'List of consumers on manifest with missing MCI data. No MCI means - we can not bill.',
        //   onClick: (e) => this.noMciGen_onClick(e),
        //   notificationOptions: {},
        // },

        {
          type: TYPES.L2_MISSING_TRIP_ID_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - MISSING TRIP_ID',
          description:
            'List of clients who are on schedule for selected period but don`t have MTM or CTS Trip_IDs.' +
            ' So If selected period is one day - to show up in this report client has to be on schedule this day and not to have TripID' +
            ' from any of brokers for this day. If selected period more than one day, for example a week - to show up in this' +
            ' report client has to be on schedule at least once but have no TripIDs assigned during selected period.',
        },
        // {
        //   type: 'GENERATE_NO_TRIP_ID_CONSUMERS_FOR_MCO_REPORT',
        //   caption: 'MTM FULL - Active clients with MTM No TripIDs for selected period',
        //   description: 'List of active portal clients who don’t have TripIDs (in “S” - scheduled status)' +
        //     ' from MTM for selected period, used for submission to broker.',
        //   onClick: (e) => this.noTripIdForMcoGen_onClick(e),
        //   notificationOptions: {},
        // },

        {
          type: TYPES.L2_MTM_STANDING_ORDER_EXPORT_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - MTM STANDING ORDER EXPORT',
          description: '',
        },

        // {
        //   type: TYPES.L2_MTM_STANDING_ORDER_EXPORT_REPORT_V2,
        //   settings: {
        //     forPeriod: true,
        //   },
        //   caption: 'L2 - MTM STANDING ORDER EXPORT V2',
        //   description: '',
        // },

        {
          type: TYPES.L2_CTS_STANDING_ORDER_EXPORT_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - CTS STANDING ORDER EXPORT',
          description: '',
        },

        {
          type: TYPES.L2_ACTIVE_CLIENTS_NOT_ASSIGNED_ON_MANIFEST,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - CLIENTS NOT ON SCHEDULE',
          description: 'List of portal clients not placed on schedule and signature sheets for selected period.',
        },
        //   {
        //     type: 'GENERATE_NEVER_ASSIGNED_CONSUMERS_REPORT',
        //     caption: 'Generate Never Assigned Consumer Report',
        //     description: 'List of active consumers with MCI data never assigned on manifest.',
        //     onClick: (e) => this.neverAssignedGen_onClick(e),
        //     notificationOptions: {},
        //   },

        {
          type: TYPES.L2_ADC_TRIPS_NOT_ON_MANIFEST_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - TRIP_ID NOT ON SCHEDULE',
          description:
            'Report is useful to find portal clients who receive TripID' +
            ' but never added on schedule & signature sheets during selected period.',
        },

        {
          type: TYPES.L2_MCO_CHANGES_AND_DISCHARGES_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - CLIENT ELIGIBILITY CHANGES',
          description:
            'List of clients discharged from one MCO, and possibly got assigned to another MCO (as per HHA Data).',
        },

        {
          type: TYPES.L2_MTM_DENIED_TRIPS_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - MTM INELIGIBLE TRIPS',
          description:
            'List of active consumers with Trips in D0-denied/ineligible status for selected date period.' +
            ' In most cases client has MTM D0 status when switching MCO, particularly when prior MCO (for example PAHW)' +
            ' sent termination to MTM but new MCO (For example Keystone) did not submit authorization.',
        },
        // {
        //   type: 'GENERATE_MTM_DENIED_TRIPS_REPORT',
        //   caption: 'Generate MTM Denied Trips Report',
        //   description: 'List of active consumers with denied trips for selected date period.',
        //   onClick: (e) => this.mtmDeniedTripsGen_onClick(e),
        //   notificationOptions: {},
        // },

        {
          type: TYPES.L2_OTHER_COMP_OUR_TRIP_ID_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - OTHER COMP OUR TRIP_ID',
          description: 'Find trip ids assigned to other company driver.',
        },

        {
          type: TYPES.L2_NON_PACHC_NOT_ACTIVE_TRIP_IDs,
          settings: {
            forPeriod: true,
          },
          caption: 'L2 - NON PA-CHC & NOT ACTIVE TRIP_IDs',
          description:
            'Shows all the clients who`s program is not PA-CHC but receiving TripIDs on one tab,' +
            ' and the clients who is not in ACTIVE status but receiving TripIDs on another Tab.\n' +
            'USE CASE: If you see "NON-PROGRAM-YET" client who gets TripID, consider changing client\'s program to "PA-CHC";' +
            ' or if you see "PENDING" client who receives TripID, consider changing status to "ACTIVE".',
        },
      ],
    },

    {
      text: 'Level 3 - Maintenance Reports',
      roles: ['SU', 'BILLER', 'MANAGER'],
      items: [
        {
          type: TYPES.L3_NON_CHC_CLIENTS_FOLLOW_UP,
          settings: {
            forPeriod: false,
          },
          caption: 'L3 - NOT BILLABLE CLIENTS FOLLOW-UP',
          description:
            'List of active clients in any other status but PA-CHC.' +
            ' Run periodically to see client who require follow-up on their program status.',
        },
        {
          type: TYPES.L3_CLIENT_STATUS_FOLLOW_UP,
          settings: {
            forPeriod: false,
          },
          caption: 'L3 - CLIENT STATUS FOLLOW UP',
          description: 'List of clients in ON-HOLD and PENDING statuses.',
        },

        {
          type: TYPES.L3_ACTIVE_CLIENTS_NEVER_ASSIGNED_ON_MANIFEST,
          settings: {
            forPeriod: false,
          },
          caption: 'L3 - CLIENTS NEVER BEEN ON SCHEDULE',
          description:
            'List of active clients who never been assigned on schedule a single time.' +
            ' Useful to find unfinished data-entry, when client was created in the portal but never been placed on manifest.',
        },
        //   {
        //     type: 'GENERATE_NEVER_ASSIGNED_CONSUMERS_REPORT',
        //     caption: 'Generate Never Assigned Consumer Report',
        //     description: 'List of active consumers with MCI data never assigned on manifest.',
        //     onClick: (e) => this.neverAssignedGen_onClick(e),
        //     notificationOptions: {},
        //   },

        {
          type: TYPES.L3_DATA_VERIFICATION,
          settings: {
            forPeriod: false,
          },
          caption: 'L3 - DATA VERIFICATION',
          description:
            'List of clients with discrepancies, cross check with HHA & Broker data by MCI - to find mistakes in Name,' +
            ' DOB, Address, etc. and back by Name & DOB to find discrepancies in MCI.',
        },

        {
          type: TYPES.L3_MULTI_ADC_CLIENTS,
          settings: {
            forPeriod: true,
          },
          caption: 'L3 - MULTI-ADC CLIENTS',
          description: 'Shows clients attending different facilities during specified period.',
        },

        {
          type: TYPES.L3_CANCELLATIONS_PIVOT,
          settings: {
            forPeriod: true,
          },
          caption: 'L3 - CANCELLATIONS PIVOT',
          description:
            'Show all clients who has at least one cancelled TripID for the period.' +
            ' Clients who have non-active TripIDs (in X Status or D Status) with subsequently issued active TripIDs (in S Status)' +
            ' for same day for same client not going to show up in this report.' +
            ' All statuses for all dates shown color-coded in the report.',
        },

        {
          type: TYPES.L3_NEW_MONTH_TERMINATIONS,
          settings: {
            forPeriod: true,
          },
          caption: 'L3 - NEW MONTH TERMINATIONS PIVOT',
          description:
            'Report is helpful to bulk-identify issues with Trip Cancellations or Terminations usually due to' +
            ' Eligibility changes between just ended month and a new month.' +
            ' It is best to run this report when Eligibility for new month is confirmed.' +
            ' Good time range would be 10 days in old months to 10 days in new month: Jun 20 - Jul 10',
        },

        {
          type: TYPES.L3_PORTAL_VS_CLIENTS_SHEET_DISCREPANCIES,
          settings: {
            forPeriod: false,
          },
          caption: 'L3 - PORTAL vs CLIENTS SHEET DISCREPANCIES',
          description: '',
        },

        // {
        //   type: TYPES.L3_CLIENT_ELIGIBILITY,
        //   forPeriod: true,
        //   caption: 'L3 - CLIENT ELIGIBILITY',
        //   description: '',
        // },

        {
          type: TYPES.L3_CLIENT_UPDATES,
          settings: {
            forPeriod: true,
          },
          caption: 'L3 - CLIENT UPDATES',
          description: '',
        },

        {
          type: TYPES.L3_NOT_ATTENDING_CLIENTS_WITH_TRIP_ID,
          settings: {
            forPeriod: true,
          },
          caption: 'L3 - NOT ATTENDING CLIENTS WITH TRIP_ID',
          description: '',
        },

        {
          type: TYPES.L3_CLIENTS_WITH_EXTRA_TRIPS,
          settings: {
            forPeriod: true,
          },
          caption: 'L3 - CLIENTS WITH EXTRA TRIPS',
          description: '',
        },
      ],
    },

    {
      text: 'Level 4 - Billing Reports',
      roles: ['SU', 'BILLER'],
      items: [
        {
          type: TYPES.L4_MTM_BY_FACILITY_CLAIMS_TO_BILL_REPORT,
          settings: {
            broker: 'MTM',
            forPeriod: true,
            byFacility: true,
          },
          caption: 'L4 - MTM PER FACILITY CLAIMS TO BILL',
          description: 'Daily Per Facility Excel 2003 file for MTM batch claims billing.',
        },
        {
          type: TYPES.L4_MTM_BY_FACILITY_ECLAIMS_TO_BILL_REPORT,
          settings: {
            broker: 'MTM',
            forPeriod: true,
            byFacility: true,
          },
          caption: 'L4 - MTM PER FACILITY E-CLAIMS TO BILL',
          description: 'Daily Per Facility Excel 2003 file for MTM batch claims billing.',
        },
        {
          type: TYPES.L4_CTS_BY_FACILITY_CLAIMS_TO_BILL_REPORT,
          settings: {
            broker: 'CTS',
            forPeriod: true,
            byFacility: true,
          },
          caption: 'L4 - CTS PER FACILITY CLAIMS TO BILL',
          description: 'Daily Per Facility CSV file for CTS batch claims billing.',
        },
        {
          type: TYPES.L4_CTS_BY_FACILITY_ECLAIMS_TO_BILL_REPORT,
          settings: {
            broker: 'CTS',
            forPeriod: true,
            byFacility: true,
          },
          caption: 'L4 - CTS PER FACILITY E-CLAIMS TO BILL',
          description: 'Daily Per Facility CSV file for CTS batch claims billing.',
        },
        {
          type: TYPES.L4_MTM_CLAIMS_TO_BILL_REPORT,
          settings: {
            broker: 'MTM',
            forPeriod: true,
            byFacility: false,
          },
          caption: 'L4 - MTM CLAIMS TO BILL',
          description: 'Daily Excel 2003 file for MTM batch claims billing.',
        },
        {
          type: TYPES.L4_CTS_CLAIMS_TO_BILL_REPORT,
          settings: {
            broker: 'CTS',
            forPeriod: true,
            byFacility: false,
          },
          caption: 'L4 - CTS CLAIMS TO BILL',
          description: 'Daily CSV file for CTS batch claims billing.',
        },
        // {
        //   type: 'GENERATE_CLAIMS_SHEETS',
        //   caption: 'Generate MTM Claims Reports',
        //   description: 'Daily Per Facility Excel file for MTM batch claims billing.',
        //   onClick: (e) => this.mtmGen_onClick(e),
        //   notificationOptions: {},
        // },

        {
          type: TYPES.L4_BILLING_STATUS_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - ALL BILLING STATUS BY ADC',
          description:
            'Google sheet with color-coded claims statuses for selected date period, with separate per facility tabs.',
        },

        {
          type: TYPES.L4_BILLING_STATUS_REPORT,
          settings: {
            forPeriod: true,
            broker: 'MTM',
          },
          caption: 'L4 - MTM BILLING STATUS BY ADC',
          description:
            'Google sheet with color-coded claims statuses for selected date period, with separate per facility tabs.',
        },

        {
          type: TYPES.L4_BILLING_STATUS_REPORT,
          settings: {
            forPeriod: true,
            broker: 'CTS',
          },
          caption: 'L4 - CTS BILLING STATUS BY ADC',
          description:
            'Google sheet with color-coded claims statuses for selected date period, with separate per facility tabs.',
        },

        // {
        //   type: 'GENERATE_PERIOD_MAP_BILLING_REPORTS',
        //   caption: 'Generate MTM Billing Status Report',
        //   description: 'Google sheet with color-coded claims statuses for selected date period, with separate per facility tabs.',
        //   onClick: (e) => this.billingGen_onClick(e),
        //   notificationOptions: {},
        // },

        {
          type: TYPES.L4_MTM_TRIPS_IMPORT_UNITS_WITH_NO_TRIP_ID_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - MTM TRIPS IMPORT UNITS WITH NO TRIP_ID REPORT',
          description: '',
        },

        {
          type: TYPES.L4_MTM_ALL_CLIENTS_VISITED_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - ALL CLIENTS VISITED',
          description:
            'List of all clients with eligibility records who visited and signed at least once during selected period',
        },

        {
          type: TYPES.L4_CTS_TRIPS_IMPORT_UNITS_WITH_NO_TRIP_ID_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - CTS TRIPS IMPORT UNITS WITH NO TRIP_ID REPORT',
          description: '',
        },

        {
          type: TYPES.L4_NEAR_MISS_TRIPS_EXPORT_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - NEAR MISS TRIPS EXPORT',
          description: '',
        },

        {
          type: TYPES.L4_NEAR_MISS_TRIPS_MTM_FILE,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - NEAR MISS TRIPS MTM FILE',
          description: '',
        },
        {
          type: TYPES.L4_SIGNATURES_WITH_NO_TRIP_ID_MTM_FILE,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - SIGNATURES WITH NO TRIP_ID MTM FILE',
          description: '',
        },
        {
          type: TYPES.L4_UNITS_WITH_NO_TRIP_ID_MTM_FILE,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - UNITS WITH NO TRIP_ID MTM FILE',
          description:
            'Generates list of Trips we performed, judging by Units, which did not have TripID associated with them.',
        },
        {
          type: TYPES.L4_UNUSED_TRIPS,
          settings: {
            forPeriod: true,
            broker: 'MTM',
          },
          caption: 'L4 - UNUSED TRIP_ID MTM FILE',
          description: '',
        },
        {
          type: TYPES.L4_UNBILLED_SERVICES,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - UNBILLED SERVICES',
          description: '',
        },
        {
          type: TYPES.L4_ALL_SERVICES,
          settings: {
            forPeriod: true,
          },
          caption: 'L4 - ALL (BILLED/UNBILLED) SERVICES',
          description: '',
        },
      ],
    },

    {
      text: 'Level 5 - Trends & Data Mining',
      roles: ['SU', 'BILLER'],
      items: [
        // {
        //   type: TYPES.L5_TRENDS_REPORT,
        //   forPeriod: true,
        //   caption: 'L5 - TRENDS REPORT',
        //   description: '',
        // },

        {
          type: TYPES.L5_ALL_CLIENT_DATA,
          settings: {
            forPeriod: false,
          },
          caption: 'L5 - ALL CLIENT DATA',
          description: 'Generate all clients in all statuses, with all fields',
        },

        {
          type: TYPES.L5_HHAxBROKERxSYSTEM,
          settings: {
            forPeriod: false,
          },
          caption: 'L5 - HHA x BROKER x SYSTEM',
          description: '',
        },

        {
          type: TYPES.L5_PORTAL_ACTIVITY,
          settings: {
            forPeriod: true,
          },
          caption: 'L5 - PORTAL ACTIVITY',
          description: '',
        },

        {
          type: TYPES.L5_ELIGIBILITY_PIVOT,
          settings: {
            forPeriod: false,
          },
          caption: 'L5 - ELIGIBILITY PIVOT',
          description: '',
        },

        {
          type: TYPES.L5_MTM_INTEGRATION_FAILED_SERVICES_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L5 - MTM Integration Progress Report',
          description: '',
        },
      ],
    },

    {
      text: 'Level 6 - HR & Compliance',
      roles: ['SU', 'BILLER', 'HR_ADMIN'],
      items: [
        {
          type: TYPES.L6_EMPLOYEE_ATTENDANCE_CROSS_CHECK,
          settings: {
            forPeriod: true,
          },
          caption: 'L6 - Employee Attendance Cross-Check',
          description: '',
        },
      ],
    },

    {
      text: 'Level 7 - Executive Reports',
      roles: ['SU', 'BILLER'],
      items: [
        {
          type: TYPES.L7_AR_AGING_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - AR Aging',
          description:
            'Generated for MTM & CTS Claims in only "Approved, Processing (MTM), Transmitted (CTS)" statuses',
        },

        {
          type: TYPES.L7_AR_AGING_APPROVED_ONLY_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - AR Aging - Billed/Approved Only',
          description: 'Generated for MTM & CTS Claims in "Approved" only status',
        },

        // {
        //   type: TYPES.L7_MTM_CTS_AGING_SUMMARY_REPORT,
        //   settings: {
        //     forPeriod: true,
        //   },
        //   caption: 'L7 - MTM/CTS A/R Aging Report',
        //   description: '',
        // },

        {
          type: TYPES.L7_MTM_AGING_SUMMARY_REPORT,
          settings: {
            forPeriod: true,
            broker: 'MTM',
          },
          caption: 'L7 - AR Aging MTM (Data from MTM Claim File)',
          description: 'Generated only for MTM Services, based on data received back from MTM in the Claim Files',
        },

        {
          type: TYPES.L7_AGING_SUMMARY_REPORT,
          settings: {},
          caption: 'L7 - AR Aging Summary by Date of Service',
          description:
            'Not true AR Report in the sense that it`s generated based on Date Of Service' +
            ' rather than Date of Invoice Submitted',
        },

        // {
        //   type: TYPES.L7_SUBMITTED_CLAIMS_REPORT,
        //   settings: {
        //     forPeriod: true,
        //   },
        //   caption: 'L7 - Submitted Claims Report QB Import Old',
        //   description: 'Generates Excel Files with all the submitted (billed) claims for selected date to be imported into QB',
        // },
        {
          type: TYPES.L7_SUBMITTED_CLAIMS_REPORT_ALL,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - Submitted Claims Report QB Import',
          description:
            'Generates Excel Files with all the submitted (billed) claims for selected date to be imported into QB',
        },
        {
          type: TYPES.L7_SUBMITTED_CLAIMS_REPORT_ALL_MONTHLY,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - Submitted Claims Report QB Import (Monthly)',
          description:
            'Generates Excel Files with all the submitted (billed) claims for selected date to be imported into QB',
        },
        {
          type: TYPES.L7_MEALS_SUBMITTED_CLAIMS_REPORT,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - Meals Submitted Claims Report',
          description: '',
        },

        {
          type: TYPES.L7_MTM_BILLING_TIMELINE_ANALYSIS,
          settings: {
            forPeriod: true,
            broker: 'MTM',
          },
          caption: 'L7 - MTM BILLING TIMELINE ANALYSIS',
          description:
            'This report is based on MTM Remittance File and is used to analyse the time spread between Service Date, Invoice Date and Payment Date',
        },
        {
          type: TYPES.L7_MERCY_FLEET_RECEIVED_PAYMENTS,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - MERCY Fleet Received Payments',
          description: '',
        },
        {
          type: TYPES.L7_MERCY_FLEET_RECEIVED_PAYMENTS_BY_PD,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - MERCY Fleet Received Payments By Payment Date',
          description: 'MERCY Fleet Received Payments By Payment Date',
        },
        {
          type: TYPES.L7_MERCY_FLEET_RECEIVED_PAYMENTS_2021,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - MERCY Fleet Received Payments 2021 Reconciliation',
          description: 'MERCY Fleet Received Payments 2021 Reconciliation',
        },

        {
          type: TYPES.EXPORTS_INCONSISTENCY_CHECK,
          settings: {
            forPeriod: false,
          },
          caption: 'Exports Inconsistency Check',
          description: '',
        },

        {
          type: TYPES.L7_SERVICE_MISMATCH_DESTINATIONS,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - Destination Mismatch Services Report',
          description:
            'Billed signatures where destination address is different from the address provided in the ETD file',
        },

        {
          type: TYPES.L7_SIGNS_COLLECTED_APP,
          settings: {
            forPeriod: true,
          },
          caption: 'L7 - Signature Collected App Report',
          description: '',
        },
      ],
    },

    {
      text: 'Level 8 - Stakeholder Reports',
      roles: ['SU', 'BILLER'],
      items: [
        // {
        //   type: TYPES.L8_TRAILING_YEAR_PERFORMANCE,
        //   settings: {
        //     forPeriod: false,
        //   },
        //   caption: 'L8 - Trailing Year Performance Report',
        //   description: '',
        // },
        {
          type: TYPES.L8_TRAILING_STAKEHOLDER_INSIGHT,
          settings: {
            forPeriod: false,
          },
          caption: 'L8 - Trailing Year Performance Report',
          description: '',
        },

        {
          type: TYPES.L8_CURRENT_UNITS_VS_X_WEEKS_TRAILING,
          settings: {
            forPeriod: false,
            trailingWeeks: 8,
          },
          caption: 'L8 - 8 Weeks Trailing vs Last Week',
          description:
            'Typically Ran for period of last full week. Generates Excel File with Total Units & Daily Averages VS 8 Weeks Trailing Data',
        },
      ],
    },

    ///////////////////////////////////////////////////////////////////////////

    //
    // {
    //   type: 'GENERATE_UNASSIGNED_REPORTS',
    //   caption: 'Generate Unassigned Reports',
    //   description: 'Per facility report, shows unassigned TripID,' +
    //     ' meaning TripID was issued by MTM but client was not placed on manifest.',
    //   onClick: (e) => this.unassignedGen_onClick(e),
    //   notificationOptions: {},
    // },
    // {
    //   type: 'GENERATE_NO_TRIP_ID_CONSUMERS_REPORT',
    //   caption: 'Generate No Trip ID Consumers Report',
    //   description: 'List of active consumers with no Trip ID on manifest for selected date period.',
    //   onClick: (e) => this.noTripIdGen_onClick(e),
    //   notificationOptions: {},
    // },
  ];

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    public config: ConfigService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
  ) {
    super(logger);

    this.tenantType$ = this.config.tenantType$;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {}

  get isPeriodSelected() {
    return (
      this.fromDateBox &&
      this.toDateBox &&
      this.fromDateBox.value &&
      this.toDateBox.value &&
      this.fromDateBox.isValid &&
      this.toDateBox.isValid
    );
  }

  genPeriodReport(itm: IReportItem) {
    const fromIncl = this.selectedFromValue;
    const toIncl = this.selectedToValue;

    const fromMomentIncl = moment(fromIncl);
    const toMomentExcl = moment(toIncl).add(1, 'days');

    // console.log(from, to);

    const strFrom = moment(fromIncl).format('MM/DD');
    const strTo = moment(toIncl).format('MM/DD');
    const title = itm.caption + (oc(itm).settings.forPeriod() ? ` FOR ${strFrom}-${strTo}` : '');
    of(true)
      .pipe(
        tap(() => {
          notify(`${title} requested at ${moment().format('LTS')}.`);
          // notify('Report Requested');
          this.notification.generateNotification({
            title: 'Requested',
            opts: {
              body: `${title} requested at ${moment().format('LTS')}.`,
              requireInteraction: true,
              renotify: true,
              tag: title,
            },
          });
        }),
        switchMap(() =>
          this.pusher.rpc(itm.type, {
            ...oc(itm).settings({}),
            from: fromMomentIncl.format('YYYY-MM-DD'),
            to: toMomentExcl.format('YYYY-MM-DD'),
            useRunJob: true,
          }),
        ),
        tap(data => {
          const notification = merge(
            {
              title: 'Done!',
              opts: {
                body:
                  (false && data && data.url ? `<a href="${data.url}">${title}</a>` : title) +
                  ` ready at ${moment().format('LTS')}.`,
                requireInteraction: true,
                renotify: true,
                tag: title,
                // actions: [{action: 'open_url', title: 'Open'}],
              } as NotificationOptions,
            },
            itm.notification,
          );
          this.notification.generateNotification(notification);
        }),
        catchError(err => {
          const notification = merge(
            {
              title: 'Failed!',
              opts: {
                body: `${title} failed at ${moment().format('LTS')}.\n` + err.message,
                requireInteraction: true,
                renotify: true,
                tag: title,
              },
            },
            itm.notification,
          );
          this.notification.generateNotification(notification);
          notify(err.message, 'error', 15000);
          return of(null);
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }
}

import {
  MyUser
} from '../index';

declare var Object: any;
export interface CommunicationMessageInterface {
  "dateSent": Date;
  "method": string;
  "recipient": string;
  "recipientName"?: string;
  "normalizedRecipient"?: string;
  "sender": string;
  "origin": string;
  "status": string;
  "receiptRequired": boolean;
  "sentBy": number;
  "domain": string;
  "type": string;
  "batchId"?: string;
  "message": string;
  "responseMessage"?: string;
  "response"?: any;
  "meta"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: number;
  "id"?: any;
  sentByUser?: MyUser;
}

export class CommunicationMessage implements CommunicationMessageInterface {
  "dateSent": Date;
  "method": string;
  "recipient": string;
  "recipientName": string;
  "normalizedRecipient": string;
  "sender": string;
  "origin": string;
  "status": string;
  "receiptRequired": boolean;
  "sentBy": number;
  "domain": string;
  "type": string;
  "batchId": string;
  "message": string;
  "responseMessage": string;
  "response": any;
  "meta": any;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": number;
  "id": any;
  sentByUser: MyUser;
  constructor(data?: CommunicationMessageInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `CommunicationMessage`.
   */
  public static getModelName() {
    return "CommunicationMessage";
  }

  /**
  * This method creates an instance of CommunicationMessage for dynamic purposes.
  **/
  public static factory(data: CommunicationMessageInterface): CommunicationMessage{
    return new CommunicationMessage(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CommunicationMessage',
      plural: 'CommunicationMessages',
      path: 'CommunicationMessages',
      idName: 'id',
      properties: {
        "dateSent": {
          name: 'dateSent',
          type: 'Date'
        },
        "method": {
          name: 'method',
          type: 'string'
        },
        "recipient": {
          name: 'recipient',
          type: 'string'
        },
        "recipientName": {
          name: 'recipientName',
          type: 'string'
        },
        "normalizedRecipient": {
          name: 'normalizedRecipient',
          type: 'string'
        },
        "sender": {
          name: 'sender',
          type: 'string'
        },
        "origin": {
          name: 'origin',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "receiptRequired": {
          name: 'receiptRequired',
          type: 'boolean'
        },
        "sentBy": {
          name: 'sentBy',
          type: 'number'
        },
        "domain": {
          name: 'domain',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "batchId": {
          name: 'batchId',
          type: 'string'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "responseMessage": {
          name: 'responseMessage',
          type: 'string'
        },
        "response": {
          name: 'response',
          type: 'any'
        },
        "meta": {
          name: 'meta',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        sentByUser: {
          name: 'sentByUser',
          type: 'MyUser',
          model: 'MyUser',
          relationType: 'belongsTo',
                  keyFrom: 'sentBy',
          keyTo: 'id'
        },
      }
    }
  }
}

<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">
        Restore Manifest for "{{ dateDisplay() }}" from Change History (Choose a Row)
      </div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: -1em 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div mat-dialog-content style="padding-top: 3px">
  <dx-data-grid
    #grid
    height="400"
    [dataSource]="dso$ | async"
    [columns]="gridColumns"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [pager]="{ visible: false }"
    [selection]="gridSelection"
    [paging]="gridPaging"
    [filterRow]="gridFilterRow"
    [scrolling]="gridScrolling"
    (onSelectionChanged)="grid_onSelectionChanged($event)">
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

    <!-- Templates -->
    <ng-container *dxTemplate="let cellInfo of 'manifest_cell'">
      <a class="show-loop-link" (click)="showManifestDialog(cellInfo.data)">{{ cellInfo.value }}</a>
    </ng-container></dx-data-grid
  >
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    (click)="restoreManifest(grid?.selectedRowKeys)"
    color="primary"
    [disabled]="hasSignature || !grid?.selectedRowKeys?.length">
    Restore
  </button>
  <span *ngIf="hasSignature" style="margin-left: 1em; color: red"
    >Manifest can’t be restored after trips have been performed.</span
  >
</div>


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import Guid from 'devextreme/core/guid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { flatten, groupBy, isEmpty, isNil, isObjectLike, pickBy, set, uniqBy } from 'lodash-es';
import mapKeys from 'lodash-es/mapKeys';
import moment from 'moment';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { LoopBackStoreOptions } from '../../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { gqlMongoLoad } from '../../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll, hasAmbTrips } from '../../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../../shared/modules/my-common/services/pusher.service';
import { StateStoreService } from '../../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../../shared/modules/ui/components/abstract/a-base.component';
import { DlgSelectSignatureComponent } from '../../../../../shared/modules/ui/components/dlg-select-signature/dlg-select-signature.component';
import { PushNotificationsService } from '../../../../../shared/modules/ui/services/push-notifications.service';
import { UiService } from '../../../../../shared/modules/ui/services/ui.service';
import {
  Consumer,
  ConsumerView,
  ConsumerViewApi,
  Employee,
  EmployeeApi,
  Facility,
  FacilityApi,
  LoggerService,
  Signature,
  SignatureApi,
  SignatureConsUniqImgView,
  SignatureView,
  SignatureViewApi,
  TripManifest,
  TripManifestApi,
  Vehicle,
  VehicleApi,
} from '../../../../../shared/sdk';
import { DlgUnlockServiceComponent } from '../../../../operations/dialogs/dlg-unlock-service/dlg-unlock-service.component';
import { SERVICE_TYPE } from '../../../../trip-manifest/classes/enums';
import { DlgSelectDriverComponent } from '../../../../trip-manifest/dialogs/dlg-select-driver/dlg-select-driver.component';
import { DlgSelectVehicleComponent } from '../../../../trip-manifest/dialogs/dlg-select-vehicle/dlg-select-vehicle.component';
import { DlgEditServiceStatusComponent } from '../../../dialogs/dlg-cancel-service/dlg-edit-service-status.component';
import { DlgChangeAdcWithClientComponent } from '../../../dialogs/dlg-change-adc-w-client/dlg-change-adc-w-client.component';
import { DlgChangeAdcComponent } from '../../../dialogs/dlg-change-adc/dlg-change-adc.component';
import { DlgEditOriginDestComponent } from '../../../dialogs/dlg-edit-origin-dest/dlg-edit-origin-dest.component';
import { DlgEditServiceTypeComponent } from '../../../dialogs/dlg-edit-service-type/dlg-edit-service-type.component';
import { DlgEditTimesComponent } from '../../../dialogs/dlg-edit-times/dlg-edit-times.component';
import { DlgEditUnitsComponent } from '../../../dialogs/dlg-edit-units/dlg-edit-units.component';

@Component({
  selector: 'app-billing-common-v2',
  templateUrl: './billing-common-v2.component.html',
  styleUrls: ['./billing-common-v2.component.scss'],
})
export class BillingCommonV2Component extends ABaseComponent implements OnInit {
  dso: any = new DataSource([]);
  facilityDso$: Observable<DataSourceOptions> = of([]);
  consumerDso$: Observable<DataSourceOptions> = of([]);
  employeeDso$: Observable<DataSourceOptions> = of([]);
  vehicleDso$: Observable<DataSourceOptions> = of([]);

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  $cmpAddresses$: BehaviorSubject<(number | { key: number; tripId: string })[]> = new BehaviorSubject([]);
  $chkTripIDs$: BehaviorSubject<{ key: number; cId: number; tripId: string; date: string }[]> = new BehaviorSubject([]);

  serviceTypes = [...Object.values(SERVICE_TYPE), ...(hasAmbTrips() ? ['AMB_TRIP'] : [])];
  validationStates = [
    { v: 'PENDING', t: 'Not Validated' },
    { v: 'VALID', t: 'Valid' },
    { v: 'INVALID', t: 'Invalid' },
    { v: 'ISSUES', t: 'Issues' },

    { v: 'FULL_AUTO_VALIDATED', t: 'Full Auto Validated' },
    { v: 'PARTIAL_AUTO_VALIDATED', t: 'Partial Auto Validated' },
  ];

  ClaimStatuses = [
    'Billed',
    'Authorized',
    'Approved',
    'Processing',
    'Pending',
    'Transmitted',
    'Review',
    'Incomplete',
    'Paid',
    'Denied',
    'Cancelled',
  ];

  //region filter

  selectedFromValue?: Date = new Date();
  selectedToValue?: Date = new Date();

  facilityId?: number;
  driverId?: number;
  clientId?: number;

  validationState?: string;
  mci?: string;
  broker?: string;
  serviceType?: string;
  claimStatus?: string;
  mco?: string;
  hasUnusedTrips?: boolean;
  hasTripAssigned?: boolean;
  existTripIds?: boolean;
  hasClaim?: boolean;

  withDriverSigns = false;
  withEscortSigns = false;

  // billingStatus?: string;
  // claimState?: string;
  // clientName?: string;

  //endregion

  tripDsoMap: Map<number, any> = new Map();

  private vehicleDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  private driverDsMap: {
    [id: string]: {
      fullInstance?: any;
    };
  } = {};

  private dataCollection: string | undefined;

  //

  tripIdCellMouseOverMap = {};
  mealPhotoCellMouseOverMap = {};
  addrMismatchMouseOverMap = {};

  grid_stateStoring: any;

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  // @ViewChild('from', {static: true}) fromDateBox: DxDateBoxComponent;
  // @ViewChild('to', {static: true}) toDateBox: DxDateBoxComponent;

  constructor(
    protected logger: LoggerService,
    private ui: UiService,
    public config: ConfigService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private pusher: PusherService,
    private notification: PushNotificationsService,
    @Inject(HttpClient) private http: HttpClient,
    private dialog: MatDialog,
    private sapi: SignatureApi,
    private svapi: SignatureViewApi,
  ) {
    super(logger);

    this.grid_stateStoring = this.sss.buildOptions(
      'd5093326-fbaf-4ed6-add1-6f795728acc6',
      1000,
      'local',
      this.sss.resetState,
    );

    this.facilityDso$ = this.buildFacilityDataSource();
    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$filterEvent$
      .pipe(
        filter(arg => arg),
        tap(async () => {
          this.grid.instance.endCustomLoading();

          this.grid.instance.clearFilter();
          this.grid.instance.clearSelection();
          // await this.grid.instance.deselectAll();

          const curState = { ...this.grid.instance.state(), ...this.sss.resetState };
          // console.log('curState', curState);
          this.grid.instance.state(curState);

          this.dso = [];

          this.grid.instance.beginCustomLoading('Filtering...');
          this.grid.instance.repaint();
        }),
        switchMap(() =>
          this.buildDataSource().pipe(
            catchError(err => {
              notify(err.message, 'error', 5000);
              return of([]);
            }),
          ),
        ),
        tap(dso => {
          this.grid.instance.endCustomLoading();
          this.dso = dso;
          this.grid.instance.refresh();
        }),
        tap(async () => {
          // this.grid.instance.clearFilter();
          // this.grid.instance.clearSelection();
          // await this.grid.instance.deselectAll();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$cmpAddresses$
      .pipe(
        filter(keysOrObjs => keysOrObjs.length > 0),
        // delay(100),
        // mergeMap(identity),
        // tap(() => this.grid.instance.beginUpdate()),
        mergeMap(keysOrObjs => {
          return of(
            keysOrObjs.map(keyOrObj => {
              const key = isObjectLike(keyOrObj) ? (keyOrObj as any).key : keyOrObj;
              const tripId = isObjectLike(keyOrObj) ? (keyOrObj as any).tripId : undefined;
              return { key, tripId };
            }),
          ).pipe(
            tap(objs => {
              this.gridStore.push(
                objs.map(({ key, tripId }) => ({
                  type: 'update',
                  data: { _addresses: {}, _addrMismatch: '...' },
                  key,
                })),
              );
            }),
            mergeMap(async objs =>
              Promise.all(
                objs.map(({ key, tripId }) =>
                  this.sapi
                    .getManifestAndTripAddresses(key, tripId, hAll)
                    .pipe(
                      catchError(err => of(null)),
                      map(res => ({ key, tripId, res })),
                    )
                    .toPromise(),
                ),
              ),
            ),
            catchError(err => of([] as { key; tripId; res }[])),
            tap(results => {
              const changes = results.map(({ key, tripId, res }) => {
                const mismatch =
                  res &&
                  res.service &&
                  res.trip &&
                  (res.service.to || res.service.from) &&
                  (res.trip.to || res.trip.from)
                    ? {
                        to: res.service.to !== res.trip.to,
                        from: res.service.from !== res.trip.from,
                        both: res.service.to !== res.trip.to && res.service.from !== res.trip.from,
                        swapped: res.service.to === res.trip.from || res.service.from === res.trip.to,
                      }
                    : undefined;

                return {
                  type: 'update',
                  data: {
                    _addresses: res || {},
                    _addrMismatch: mismatch
                      ? mismatch.both
                        ? mismatch.swapped
                          ? 'swapped'
                          : 'both'
                        : mismatch.to
                          ? 'dest'
                          : mismatch.from
                            ? 'origin'
                            : 'valid'
                      : '',
                  },
                  key,
                };
              });
              this.gridStore.push(changes);
            }),
          );
        }),
        catchError(err => of(console.error(err))),
        // tap(() => this.grid.instance.endUpdate()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    this.$chkTripIDs$
      .pipe(
        filter(objs => objs.length > 0),
        // delay(100),
        // mergeMap(identity),
        // tap(() => this.grid.instance.beginUpdate()),
        mergeMap(objects => {
          return of(true).pipe(
            mergeMap(() =>
              combineLatest([
                of(objects).pipe(
                  tap(objs => {
                    this.gridStore.push(
                      objs.map(({ key }) => ({ type: 'update', data: { _tripIdsMultiAssigned: '...' }, key })),
                    );
                  }),
                ),

                of(this.grid.instance.getDataSource().items()).pipe(
                  map(items => {
                    return flatten(items.map(o => (o.items && o.key ? (o.items as any[]) : [o]))).map(o => ({
                      key: o.id,
                      date: o.vdate,
                      cId: o.consumerId,
                      tripId: oc(o)._trip._tripId(),
                    }));
                  }),
                  tap(objs => {
                    this.gridStore.push(
                      objs.map(({ key }) => ({ type: 'update', data: { _tripIdsMultiAssigned: '...' }, key })),
                    );
                  }),
                ),

                Promise.all(
                  uniqBy(
                    objects.filter(o => !isNil(o.tripId)),
                    o => [o.cId, o.date].join(':'),
                  ).map(({ cId, date }) =>
                    this.sapi
                      .find<Signature>(
                        {
                          where: { vdate: date, consumerId: cId },
                          fields: { id: true, consumerId: true, vdate: true, vTripId: true },
                        },
                        hAll,
                      )
                      .pipe(
                        catchError(err => of([] as Signature[])),
                        map(docs =>
                          docs.map(doc => ({ key: doc.id, cId: doc.consumerId, date: doc.vdate, tripId: doc.vTripId })),
                        ),
                        tap(objs => {
                          this.gridStore.push(
                            objs.map(({ key }) => ({ type: 'update', data: { _tripIdsMultiAssigned: '...' }, key })),
                          );
                        }),
                      )
                      .toPromise(),
                  ),
                ),

                Promise.all(
                  uniqBy(
                    objects.filter(o => !isNil(o.tripId)),
                    o => [o.cId, o.date].join(':'),
                  ).map(({ cId, date }) =>
                    gqlMongoLoad(
                      this.dss,
                      this.dataCollection,
                      { select: { id: true, consumerId: true, vdate: true, '_trip._tripId': true } },
                      [
                        {
                          $match: {
                            vdate: date,
                            consumerId: cId,
                          },
                        },
                      ],
                    )
                      .pipe(
                        catchError(err => of([])),
                        map(docs =>
                          docs.map(doc => ({
                            key: doc.id,
                            cId: doc.consumerId,
                            date: doc.vdate,
                            tripId: oc(doc)._trip._tripId(),
                          })),
                        ),
                        tap(objs => {
                          this.gridStore.push(
                            objs.map(({ key }) => ({ type: 'update', data: { _tripIdsMultiAssigned: '...' }, key })),
                          );
                        }),
                      )
                      .toPromise(),
                  ),
                ),
              ]),
            ),

            map(([objs1, objs2, objs3, objs4]) => {
              const objs = uniqBy([...objs1, ...objs2, ...flatten(objs3), ...flatten(objs4)], 'key');
              const byTripId = groupBy(
                objs.filter(o => !isNil(o.tripId)),
                o => o.tripId,
              );
              return objs.map(({ key, tripId }) => ({
                key,
                res: (byTripId[tripId] ? byTripId[tripId].length > 1 : false) as any,
              }));
            }),

            catchError(err => of([] as { key; res }[])),

            delay(100),

            tap(results => {
              const changes = results.map(({ key, res }) => ({
                type: 'update',
                data: { _tripIdsMultiAssigned: res },
                key,
              }));
              this.gridStore.push(changes);
            }),
          );
        }),
        catchError(err => of(console.error(err))),
        // tap(() => this.grid.instance.endUpdate()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  // region data sources

  private buildDataSource() {
    const self = this;

    return of(true).pipe(
      map(() => {
        const fromIncl = this.selectedFromValue;
        const toExcl = this.selectedToValue;

        const fromMoment = fromIncl && moment(fromIncl);
        const toMoment = toExcl && moment(toExcl).add(1, 'days');

        if (!fromMoment || !toMoment) {
          throw new Error('Period should be defined');
        }

        if (toMoment.diff(fromMoment, 'months') > 1) {
          throw new Error('Period should be less or equal to 1 month');
        }

        const strFrom = fromMoment && fromMoment.format('YYYY-MM-DD');
        const strTo = toMoment && toMoment.format('YYYY-MM-DD');

        return {
          fromIncl: strFrom,
          toExcl: strTo,

          mci: this.mci,
          mco: this.mco,
          broker: this.broker,

          validationState: this.validationState,
          serviceType: this.serviceType,
          hasTrip: this.hasTripAssigned,
          hasClaim: this.hasClaim,
          claimStatus: this.claimStatus,

          hasUnusedTrips: this.hasUnusedTrips,
          existTripIds: this.existTripIds,

          facilityIds: this.facilityId ? [this.facilityId] : [],
          driverIds: this.driverId ? [this.driverId] : [],
          clientIds: this.clientId ? [this.clientId] : [],

          withDriverSigns: this.withDriverSigns,
          withEscortSigns: this.withEscortSigns,
        };
      }),

      switchMap(_filter => this.pusher.rpc('GET_CLAIMS_V2', { ..._filter, useRunService: true }, true, hAll)),

      map(coll => {
        this.dataCollection = coll;
        return this.dss.createMongoStore(coll, undefined, {
          key: 'id',
          onPush(changes: Array<{ type; data; key; index }>) {
            // changes.filter(ch => ch.type === 'update').forEach(ch => void self.gridStore.update(ch.key, ch.data));
            // console.log('changes:', changes);
          },
        });
      }),
      // switchMap(store => store.load()),

      map(store => {
        this.tripDsoMap.clear();

        return {
          store,
          // reshapeOnPush: true,
          map: (doc: any) => {
            // console.log('map');

            this.tripDsoMap.set(doc.id, doc._trips);

            doc.getBatchCount = function () {
              const _self = this;
              return oc(_self)._batchIds([]).length;
            }.bind(doc);

            doc.getBatchStatus = function () {
              const _self = this;
              return oc(_self)._currBatchStatus() || oc(_self)._lastBatchStatus();
            }.bind(doc);

            doc._locked = function () {
              const _self = this;
              return (
                oc(_self).meta.locked() ||
                oc(_self)._trips([]).length === 0 ||
                oc(_self)._trip.__claim.__status() === 'Paid'
              );
            }.bind(doc);

            doc._trip = oc(doc)._trip(doc._possibleTrip);
            (doc._trips as any[]).forEach(t => set(t, '__claim.__status', oc(t).__claim.__status(null)));

            //

            this.validateTimes(doc);

            return doc;
          },
          postProcess: (groupsOrItems: any[]) => {
            // console.log('postProcess', data);
            const data = flatten(groupsOrItems.map(itm => (itm.key && itm.items ? itm.items : [itm])));
            this.$cmpAddresses$.next(data.map(doc => ({ key: doc.id, tripId: oc(doc)._trip._tripId() })));
            this.$chkTripIDs$.next(
              data.map(doc => ({ key: doc.id, cId: doc.consumerId, date: doc.vdate, tripId: oc(doc)._trip._tripId() })),
            );

            return groupsOrItems;
          },
        } as DataSourceOptions;
      }),
    );
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] } },
      order: ['typeOrder DESC', 'type', 'shortname'],
    };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(Consumer, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildEmployeeDataSource() {
    const so = this.dss.getStoreOptions(Employee, undefined, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = { 'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1' };

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  private buildVehicleDataSource() {
    const so = this.dss.getStoreOptions(Vehicle, undefined, false) as LoopBackStoreOptions<any, any>;
    // so.customHeaders = {'X-Current-Tenant': this.facilityId ? '' + this.facilityId : '-1'};
    so.customHeaders = hAll;

    const dso: DataSourceOptions = {
      store: new CustomStore(so),
    } as DataSourceOptions;
    return of(dso);
  }

  //endregion

  private get gridStore(): CustomStore | undefined {
    return oc(this.grid).instance() ? this.grid.instance.getDataSource().store() : undefined;
  }

  private async storeUpdate(key, values, repaint = false) {
    this.gridStore.push([{ type: 'update', data: values, key }]);
    return this.gridStore.update(key, values).then(res => {
      if (repaint) {
        this.grid.instance.repaintRows([this.grid.instance.getRowIndexByKey(key)]);
      }
      return res;
    });
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  validateTimes(doc: any, minDiff = 5, maxDiff = 65) {
    // const doc = await this.gridStore.byKey(key);
    const brokerDiff = 60;

    const arrTime = oc(doc).arrivedTime() ? moment(oc(doc).arrivedTime(), 'HH:mm:ss') : null;
    const puTime = oc(doc).pickupTime() ? moment(oc(doc).pickupTime(), 'HH:mm:ss') : null;
    const doTime = oc(doc).dropoffTime() ? moment(oc(doc).dropoffTime(), 'HH:mm:ss') : null;

    const lgtcPu = oc(doc)._trip.pu_time() ? moment(oc(doc)._trip.pu_time(), 'HH:mm') : null;

    const puDoWarnings: any = {
      warn1: puTime && doTime && doTime.diff(puTime, 'minutes', true) < minDiff,
      warn2: puTime && doTime && doTime.diff(puTime, 'minutes', true) > maxDiff,
      warn3: puTime && lgtcPu && Math.abs(lgtcPu.diff(puTime, 'minutes', true)) > brokerDiff,
    };

    puDoWarnings.title = Object.entries(puDoWarnings)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => {
        return {
          warn1: `DO and PU diff less than ${minDiff} min. `,
          warn2: `DO and PU diff more than ${maxDiff} min. `,
          warn3: `LGTC Broker PU (${oc(doc)._trip.pu_time()}) and Driver PU diff more than ${brokerDiff} min. `,
        }[k];
      })
      .join('\n');

    doc._puDoWarnings = puDoWarnings;
  }

  from_onValueChanged(e) {
    // console.log(e);
    this.selectedToValue = moment(e.value).toDate();
  }

  facility_onSelectionChanged(e) {
    // console.log(e.selectedItem);

    this.consumerDso$ = this.buildConsumerDataSource();
    this.employeeDso$ = this.buildEmployeeDataSource();
    this.vehicleDso$ = this.buildVehicleDataSource();
  }

  tripId_onSelectionChanged(cellInfo, e) {
    // this runs on init grid to
    // console.log(cellInfo, e.selectedItem);
    // console.log('tripId_onSelectionChanged', oc(cellInfo).data._trip._tripId(), oc(e.selectedItem)._tripId());
    // const newTrip = e.selectedItem;
    // this.$cmpAddresses$.next([{ key: cellInfo.key, tripId: oc(newTrip)._tripId() }]);
  }

  tripId_onValueChanged(cellInfo, e) {
    // this not runs on init grid
    // console.log('tripId_onValueChanged', oc(cellInfo).data._trip());

    const newTripId = e.value;
    const newTrip = cellInfo.data._trips.find(t => t._tripId === newTripId);

    // console.log('tripId_onValueChanged', newTrip);

    void this.storeUpdate(cellInfo.key, { _trip: oc(newTrip)(null) });
    this.$cmpAddresses$.next([{ key: cellInfo.key, tripId: oc(newTrip)._tripId() }]);
    this.$chkTripIDs$.next([
      {
        key: cellInfo.data.id,
        cId: cellInfo.data.consumerId,
        date: cellInfo.data.vdate,
        tripId: oc(newTrip)._tripId(),
      },
    ]);
    this.grid.instance.repaintRows([cellInfo.rowIndex]);
  }

  grid_onCellClick(e) {
    if (e.rowType === 'data' && e.column.caption === 'Trip ID') {
      e.event.stopImmediatePropagation();
    }
  }

  grid_onCellHoverChanged(e) {
    // if (e.rowType === 'data' && e.column.caption === 'Trip ID') {
    //   if (e.eventType === 'mouseover') {
    //     this.tripIdCellMouseOverMap[e.key] = true;
    //   } else if (e.eventType === 'mouseout') {
    //     this.tripIdCellMouseOverMap[e.key] = false;
    //   }
    // }
  }

  grid_onSelectionChanged(e) {
    // console.log('grid_onSelectionChanged');
    // this.$cmpAddresses$.next(e.selectedRowKeys);
    // this.$cmpAddresses$.next(
    //   (e.currentSelectedRowKeys as number[])
    //     .map(key => [key, (e.selectedRowsData as any[]).find(data => data.id === key)])
    //     .map(([key, data]) => ({ key, tripId: oc(data)._trip._tripId() })),
    // );
  }

  grid_onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      // {
      //   // disabled: this.$showFromBroker$.value,
      //   name: 'changeAdc',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     // icon: 'fas fa-folder-plus',
      //     text: 'Change ADC',
      //     hint: 'Change ADC for selected',
      //     onClick: this.grid_toolbar_changeAdc_onClick.bind(this),
      //   },
      // },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'assignTripIds',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          // icon: 'fas fa-folder-plus',
          text: 'Assign TripIDs to Trips',
          hint: 'Assign TripIDs to Trips',
          onClick: this.grid_toolbar_assignTripIds_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildBatch',
        locateInMenu: 'auto',
        widget: 'dxDropDownButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-folder-plus',
          text: 'Build Paratransit Batch',
          hint: 'Build Paratransit Claims Batch',
          items: ['by Facility', 'All Together'],
          onItemClick: this.grid_toolbar_buildBatch_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildClaims',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-folder-plus',
          text: 'Build Billed Claims',
          hint: 'Build Billed Claims (Create fake billed claims)',
          onClick: this.grid_toolbar_buildBilledClaims_onClick.bind(this),
        },
      },
      // {
      //   // disabled: this.$showFromBroker$.value,
      //   name: 'buildBatch',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     icon: 'fas fa-folder-plus',
      //     text: 'Build Meals Batch',
      //     hint: 'Build Meals Claims Batch',
      //     onClick: this.grid_toolbar_buildMealsBatch_onClick.bind(this),
      //   }
      // },
      // {
      //   // disabled: this.$showFromBroker$.value,
      //   name: 'buildMealsReq',
      //   locateInMenu: 'auto',
      //   widget: 'dxButton',
      //   location: 'after',
      //   sortIndex: 30,
      //   showText: 'always',
      //   options: {
      //     icon: 'fas fa-build',
      //     text: 'Meals Trips Req',
      //     hint: 'Build Meals Trips Request Files',
      //     onClick: this.grid_toolbar_buildMealsReq_onClick.bind(this),
      //   }
      // },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildCtsOrderReq',
        locateInMenu: 'auto',
        widget: 'dxDropDownButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-build',
          text: 'Build CTS Report',
          hint: 'Build CTS Export Report',
          items: ['by Facility', 'All Together'],
          onItemClick: this.grid_toolbar_buildCtsReport_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildMtmAuthsToAddReq',
        locateInMenu: 'auto',
        widget: 'dxDropDownButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-build',
          text: 'Build MTM Auths Request',
          hint: 'Build MTM Auths Request',
          items: ['by Facility', 'All Together'],
          onItemClick: this.grid_toolbar_buildMtmAuthsToAdd_onClick.bind(this),
        },
      },
      {
        // disabled: this.$showFromBroker$.value,
        name: 'buildSchoolReport',
        locateInMenu: 'auto',
        widget: 'dxButton',
        location: 'after',
        sortIndex: 30,
        showText: 'always',
        options: {
          icon: 'fas fa-file-signature',
          text: 'Build School Report',
          hint: 'Build School Report PDF',
          onClick: this.grid_toolbar_buildSchoolReport_onClick.bind(this),
        },
      },
      {
        name: 'refresh',
        locateInMenu: 'auto',
        location: 'after',
        sortIndex: 99,
        widget: 'dxButton',
        showText: 'inMenu',
        options: {
          icon: 'refresh',
          text: 'Refresh',
          hint: 'Refresh',
          onClick: () => e.component.refresh(),
        },
      },
    );
  }

  grid_onContextMenuPreparing(e) {
    if (e.row && e.row.rowType === 'data' && !e.row.isEditing) {
      // this.logger.log(e);
      // const eId = get(e.row.data, HelperService.REC_FIELD_MAP.employeeId);
      // const cId = get(e.row.data, HelperService.REC_FIELD_MAP.consumerId);

      const doc: SignatureView & { _rec; _puDoWarnings } = e.row.data;
      const key = e.row.key;

      e.items = [
        {
          text: 'Change Arr/PU/DO Time',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgEditTimesComponent, {
                  hasBackdrop: true,
                  data: { signature: doc },
                })
                .afterClosed()
                .pipe(
                  switchMap(v =>
                    iif(
                      () => !!v,
                      of(v).pipe(
                        tap(() => this.ui.showLoading()),
                        map(res => ({
                          // scheduledTime: res.scheduledTime ? moment(res.scheduledTime).format('HH:mm:ss') : null,
                          arrivedTime: res.arrivedTime ? moment(res.arrivedTime).format('HH:mm:ss') : null,
                          pickupTime: res.pickupTime ? moment(res.pickupTime).format('HH:mm:ss') : null,
                          dropoffTime: res.dropoffTime ? moment(res.dropoffTime).format('HH:mm:ss') : null,
                        })),
                        // tap((res) => console.log(res)),

                        switchMap(values => {
                          return this.sapi.patchAttributes(doc.id, values, hAll).pipe(
                            switchMap(async () => {
                              const modDoc = { ...doc, ...values };
                              this.validateTimes(modDoc);
                              return this.storeUpdate(key, { ...values, _puDoWarnings: modDoc._puDoWarnings });
                            }),
                          );
                        }),
                        //
                        catchError(err => of(notify(err.message, 'error', 5000))),
                        tap(() => this.ui.hideLoading()),
                      ),
                    ),
                  ),
                )
                .toPromise();
          },
        },
        {
          text: 'Change Signature Image',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open<
                  any,
                  any,
                  {
                    data: SignatureConsUniqImgView;
                    toAllSignatures?: boolean;
                    toClient?: boolean;
                  }
                >(DlgSelectSignatureComponent, {
                  hasBackdrop: true,
                  data: { signature: doc },
                })
                .afterClosed()
                .pipe(
                  switchMap(v =>
                    iif(
                      () => !!oc(v).data(),
                      of(v).pipe(
                        tap(() => this.ui.showLoading()),
                        switchMap(res => {
                          // if (this.data.signature) {
                          return this.sapi
                            .patchAttributes(doc.id, { imgFileId: res.data.imgFileId }, hAll)
                            .pipe(switchMap(() => this.storeUpdate(key, { imgFileId: res.data.imgFileId })));
                          // } else if (this.data.consumer) {
                          //   return this.consApi.patchAttributes(
                          //     this.data.consumer.id,
                          //     {signImgFileId: res.data.imgFileId},
                          //     hAll,
                          //   );
                          // }
                        }),
                        //
                        catchError(err => of(notify(err.message, 'error', 5000))),
                        tap(() => this.ui.hideLoading()),
                      ),
                    ),
                  ),
                )
                .toPromise();
          },
        },
        {
          text: 'Change Vehicle',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgSelectVehicleComponent, {
                  width: '450px',
                  maxHeight: '650px',
                  hasBackdrop: true,
                  data: {
                    recIds: [doc.vehicleId],
                    filter: {},
                  },
                })
                .afterClosed()
                .pipe(
                  filter(keys => keys !== false && keys && keys.length === 1),
                  tap(() => this.ui.showLoading()),
                  map(keys => keys[0]),
                  switchMap(vId => {
                    return this.sapi.patchAttributes(doc.id, { vehicleId: vId }, hAll).pipe(
                      switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                      switchMap(sv => this.storeUpdate(key, sv)),
                    );
                  }),
                  //
                  catchError(err => of(notify(err.message, 'error', 5000))),
                  tap(() => this.ui.hideLoading()),
                )
                .toPromise();
          },
        },
        {
          text: 'Change Driver',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgSelectDriverComponent, {
                  width: '450px',
                  maxHeight: '650px',
                  hasBackdrop: true,
                  data: {
                    recIds: [doc.employeeId],
                    filter: {
                      or: [
                        { tenantId: doc.tenantId },
                        { tenantIds: { $json_e_c: { $: JSON.stringify(doc.tenantId) } } },
                      ],
                    },
                    skipTenantCheck: true,
                  },
                })
                .afterClosed()
                .pipe(
                  filter(keys => keys !== false && keys && keys.length === 1),
                  tap(() => this.ui.showLoading()),
                  map(keys => keys[0]),
                  switchMap(eId => {
                    return this.sapi.patchAttributes(doc.id, { employeeId: eId }, hAll).pipe(
                      switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                      switchMap(sv => this.storeUpdate(key, sv)),
                    );
                  }),
                  //
                  catchError(err => of(notify(err.message, 'error', 5000))),
                  tap(() => this.ui.hideLoading()),
                )
                .toPromise();
          },
        },
        {
          text: 'Change Service Type / Marker',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgEditServiceTypeComponent, {
                  hasBackdrop: true,
                  data: { signature: doc },
                })
                .afterClosed()
                .pipe(
                  switchMap(v =>
                    iif(
                      () => !!v,
                      of(v).pipe(
                        tap(() => this.ui.showLoading()),
                        switchMap(({ serviceType, marker }) => {
                          return this.sapi.updateServiceTypeAndMarker(doc.id, serviceType, marker, hAll).pipe(
                            // switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                            switchMap(sv => this.storeUpdate(key, sv)),
                          );
                        }),
                        catchError(err => of(notify(err.message, 'error', 5000))),
                        tap(() => this.ui.hideLoading()),
                      ),
                    ),
                  ),
                )
                .toPromise();
          },
        },
        {
          text: 'Change Origin / Destination',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else {
              let rec = oc(doc)._rec();

              void this.dialog
                .open(DlgEditOriginDestComponent, {
                  hasBackdrop: true,
                  width: '750px',
                  data: {
                    signature: doc,
                    origin: oc(rec).o(),
                    destination: oc(rec).d(),
                  },
                })
                .afterClosed()
                .pipe(
                  switchMap((v: { o; d }) =>
                    iif(
                      () => !!v,
                      of(v).pipe(
                        tap(() => this.ui.showLoading()),

                        switchMap(() =>
                          this.dss.getApi<TripManifestApi>(TripManifest).find<TripManifest>(
                            {
                              where: { date: doc.vdate },
                              fields: ['id', 'tenantId', 'date'],
                              limit: 1,
                            },
                            (headers: HttpHeaders) => {
                              headers = headers.append('X-Current-Tenant', '' + doc.tenantId);
                              return headers;
                            },
                          ),
                        ),

                        switchMap(([m]) => {
                          if (m && rec) {
                            rec = { ...rec, o: v.o, d: v.d, rid: new Guid().toString() };
                            return this.dss
                              .getApi<TripManifestApi>(TripManifest)
                              .safeSaveRecord(m.id, rec, hAll)
                              .pipe(switchMap(() => this.storeUpdate(key, { _rec: rec })));
                          } else return of(null);
                        }),
                        catchError(err => of(notify(err.message, 'error', 5000))),
                        tap(() => this.ui.hideLoading()),
                      ),
                    ),
                  ),
                )
                .toPromise();
            }
          },
        },
        {
          text: 'Change Meals Units',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgEditUnitsComponent, {
                  hasBackdrop: true,
                  data: { signature: doc },
                })
                .afterClosed()
                .pipe(
                  filter(v => !isEmpty(v)),
                  tap(() => this.ui.showLoading()),
                  switchMap(o => {
                    o = pickBy(
                      mapKeys(
                        o,
                        (v, k) =>
                          ({
                            W1759: 'HOT',
                            W1760: 'FROZEN',
                            W1761: 'SANDWICH',
                            W1762: 'EMERGENCY',
                            W1764: 'SPECIAL',
                          })[k] || k,
                      ),
                      (v, k) => v > 0,
                    );

                    return this.sapi.patchAttributes(doc.id, { meta: { mealDroppedCountList: o } }, hAll).pipe(
                      switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                      switchMap(sv => this.storeUpdate(key, sv)),
                    );
                  }),
                  //
                  catchError(err => of(notify(err.message, 'error', 5000))),
                  tap(() => this.ui.hideLoading()),
                )
                .toPromise();
          },
        },
        {
          text: 'Edit Service Record Status',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgEditServiceStatusComponent, {
                  width: '400px',
                  // maxHeight: '650px',
                  hasBackdrop: true,
                  data: doc,
                })
                .afterClosed()
                .pipe(
                  switchMap(v =>
                    iif(
                      () => !!v,
                      of(v).pipe(
                        tap(() => this.ui.showLoading()),
                        switchMap(({ status, note }) => {
                          return this.sapi
                            .patchAttributes(
                              doc.id,
                              {
                                status,
                                note,
                              },
                              hAll,
                            )
                            .pipe(
                              switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                              switchMap(sv => {
                                return this.storeUpdate(key, {
                                  ...sv,
                                  _cancelled: !!(['R', 'V', 'C'].includes(sv.type) || sv.status.startsWith('X')),
                                });
                              }),
                            );
                        }),

                        catchError(err => of(notify(err.message, 'error', 5000))),
                        tap(() => this.ui.hideLoading()),
                      ),
                    ),
                  ),
                )
                .toPromise();
          },
        },
        {
          text: 'Change ADC',
          onItemClick: () => {
            if (oc(doc).meta.locked()) {
              notify('Service is locked', 'warning', 5000);
            } else
              void this.dialog
                .open(DlgChangeAdcWithClientComponent, {
                  width: '750px',
                  maxHeight: '650px',
                  hasBackdrop: true,
                  data: { sv: doc },
                })
                .afterClosed()
                .pipe(
                  filter((pair: [Facility, ConsumerView]) => pair && pair.length && !!pair[0] && !!pair[1]),
                  tap(() => this.ui.showLoading()),
                  switchMap(([f, cv]) => {
                    return this.sapi.patchAttributes(doc.id, { tenantId: f.id, consumerId: cv.id }, hAll).pipe(
                      switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                      switchMap(sv =>
                        this.storeUpdate(key, {
                          ...sv,

                          _client: {
                            mco: cv.activeMco,
                            broker: cv.activeBroker,
                          },

                          _tenant: {
                            name: f.shortname,
                          },
                        }),
                      ),
                    );
                  }),
                  tap(() => this.$cmpAddresses$.next([doc.id])),
                  catchError(err => of(notify(err.message, 'error', 5000))),
                  tap(() => this.ui.hideLoading()),
                )
                .toPromise();
          },
        },
        {
          text: 'Unlock',
          onItemClick: () => {
            void this.dialog
              .open(DlgUnlockServiceComponent, {
                width: '450px',
                hasBackdrop: true,
                data: { signature: doc },
              })
              .afterClosed()
              .pipe(
                switchMap(v =>
                  iif(
                    () => !!v && !isEmpty(v),
                    of(v).pipe(
                      tap(() => this.ui.showLoading()),

                      switchMap(note => {
                        return this.sapi.unlock(doc.id, note, hAll).pipe(
                          switchMap(() => this.svapi.findById<SignatureView>(doc.id, {}, hAll)),
                          switchMap(sv => this.storeUpdate(key, sv)),
                          tap(() => this.grid.instance.repaintRows([e.rowIndex])),
                        );
                      }),
                      //
                      catchError(err => of(notify(err.message, 'error', 5000))),
                      tap(() => this.ui.hideLoading()),
                    ),
                  ),
                ),
              )
              .toPromise();
          },
        },
      ];
    }
  }

  grid_onCellPrepared(e) {
    // console.log(e);
    if (e.rowType === 'data' && e.column.dataField === '_addrMismatch') {
      if (oc(e).data._addrMismatch('') === '...') {
        (e.cellElement as HTMLElement).classList.add('cell-blue');
      } else if (oc(e).data._addrMismatch('') === 'valid') {
        (e.cellElement as HTMLElement).classList.add('cell-green');
      } else if (!isEmpty(oc(e).data._addrMismatch(''))) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-warning');
      } else {
        (e.cellElement as HTMLElement).classList.remove('cell-blue', 'cell-trip-warning');
      }
    }

    if (e.rowType === 'data' && e.column.dataField === '_tripIdsMultiAssigned') {
      if (oc(e).data._tripIdsMultiAssigned('') === '...') {
        (e.cellElement as HTMLElement).classList.add('cell-blue');
      } else if (oc(e).data._tripIdsMultiAssigned() === true) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-danger');
      }
    }

    if (e.rowType === 'data' && e.column.dataField === '_trip._tripId') {
      // const items = this.grid.instance.getDataSource().items();
      // const count = items.filter((i) => oc(i)._trip._tripId() === oc(e).data._trip._tripId()).length;
      // if (count > 1) (e.cellElement as HTMLElement).classList.add('cell-trip-warning');

      (e.cellElement as HTMLElement).classList.remove('cell-trip-warning');

      if (!oc(e).data.vTripId()) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-warning');
      }

      if (oc(e).data._trip._cancelled() === true || oc(e).data._trip['Trip Status']('').startsWith('X')) {
        (e.cellElement as HTMLElement).classList.add('cell-trip-warning');
      }
    }

    if (e.rowType === 'data') {
      const _lockedFn = () => {
        (e.cellElement as HTMLElement).classList.remove('cell-locked');
        if (oc(e).data.meta.locked()) {
          (e.cellElement as HTMLElement).classList.add('cell-locked');
        }
      };

      _lockedFn();
      e.watch(() => e.data.meta.locked, _lockedFn);
    }
  }

  ////////////////////////////////////////////////

  grid_toolbar_assignTripIds_onClick() {
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    void of(...selectedPairs)
      .pipe(
        tap(() => this.ui.showLoading()),
        switchMap(async ([sId, tripId]) => {
          // console.log('assign', sId, tripId);

          const s = await this.sapi.findById<Signature>(sId, {}, hAll).toPromise();
          await this.sapi.patchAttributes(sId, { meta: { ...s.meta, tripId: oc(tripId)(null) } }, hAll).toPromise();
          const sv = await this.svapi.findById<SignatureView>(sId, {}, hAll).toPromise();
          await this.storeUpdate(sv.id, sv);
        }),
        //
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    // of()
    //   .pipe(
    //     tap(() => this.ui.showLoading()),
    //     switchMap(async () => {
    //       for await (const pair of selectedPairs) {
    //         const [sId, tripId]: [number, string] = pair;
    //
    //         await api
    //           .findById<Signature>(sId, {}, hAll)
    //           .pipe(
    //             switchMap(async s => {
    //               await api.patchAttributes(sId, { meta: { ...s.meta, tripId } }, hAll).toPromise();
    //               const sv = await vapi.findById<SignatureView>(sId, {}, hAll).toPromise();
    //               this.gridStore.push([{ type: 'update', data: { ...sv }, key: sv.id }]);
    //             }),
    //           )
    //           .toPromise();
    //       }
    //     }),
    //     catchError(err => of(notify(err.message, 'error', 5000))),
    //     tap(() => this.ui.hideLoading()),
    //     takeUntil(this.$onDestroy$),
    //   )
    //   .subscribe();
  }

  grid_toolbar_changeAdc_onClick() {
    if (this.grid.instance.getSelectedRowKeys().length === 0) return;

    const fapi = this.dss.getApi<FacilityApi>(Facility);
    const cvapi = this.dss.getApi<ConsumerViewApi>(ConsumerView);

    void this.dialog
      .open(DlgChangeAdcComponent, {
        width: '750px',
        maxHeight: '650px',
        hasBackdrop: true,
        data: {
          keys: this.grid.instance.getSelectedRowsData().map((sv: SignatureView) => sv.tenantId),
        },
      })
      .afterClosed()
      .pipe(
        filter((f: Facility) => !!f),
        tap(() => this.ui.showLoading()),
        switchMap(async f => {
          const pairs = await Promise.all(
            this.grid.instance.getSelectedRowsData().map(async (row: SignatureView) => {
              return cvapi
                .find<ConsumerView>(
                  {
                    where: {
                      and: [
                        { tenantId: f.id },
                        {
                          or: [
                            {
                              and: [{ mci: { neq: null } }, { mci: oc(row).consumer_mci() }],
                            },
                            {
                              and: [
                                { person_firstname: oc(row).consumer_person_firstname() },
                                { person_lastname: oc(row).consumer_person_lastname() },
                                { person_dob: oc(row).consumer_person_dob() },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  hAll,
                )
                .pipe(
                  switchMap(([cv]) =>
                    iif(
                      () => !!cv,
                      of(cv).pipe(
                        switchMap(() => this.sapi.patchAttributes(row.id, { tenantId: f.id, consumerId: cv.id }, hAll)),
                        switchMap(() => this.svapi.findById<SignatureView>(row.id, {}, hAll)),
                        switchMap(sv =>
                          this.storeUpdate(row.id, {
                            ...sv,

                            _client: {
                              mco: cv.activeMco,
                              broker: cv.activeBroker,
                            },

                            _tenant: {
                              name: f.shortname,
                            },
                          }),
                        ),
                        tap(() => this.$cmpAddresses$.next([row.id])),
                        map(() => [row.id, true]),
                      ),
                      of([row.id, false]),
                    ),
                  ),
                )
                .toPromise();
            }),
          );

          // this.grid.instance.refresh();

          this.grid.instance.selectRows(
            pairs.filter(([, replaced]) => !replaced).map(([key]) => key),
            false,
          );

          alert(
            'Selected signatures cannot be changed automatically.' +
              ' Corresponding client not found in target facility.',
          );
        }),
        //
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.ui.hideLoading()),
      )
      .toPromise();
  }

  grid_toolbar_buildBilledClaims_onClick() {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());

    const sItems = this.grid.instance.getSelectedRowsData();

    // console.log(this.grid.instance.getDataSource().sort());

    if (sItems.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    if (sItems.some(s => s.vServiceType !== SERVICE_TYPE.MEALS && isEmpty(s.vTripId))) {
      notify('There are some services with no TripID assigned', 'error', 5000);
      return;
    }

    const selectedPairs = sItems.map(s => [s.id, s.vTripId] as [number, string]);

    notify('Billed Claims Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Billed Claims Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_BILLED_CLAIMS', {
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        switchMap(url => this.http.get(url, { responseType: 'json', withCredentials: false })),
        switchMap((claims: any[]) => Promise.all(claims.map(cl => this.storeUpdate(cl._sId, { '_trip.__claim': cl })))),
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(console.log);
  }

  grid_toolbar_buildBatch_onClick(e: { itemData }) {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    // console.log(this.grid.instance.getDataSource().sort());

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    if (selectedPairs.some(([sId, tId]) => !tId)) {
      notify('There are some services with no TripID assigned', 'error', 5000);
      return;
    }

    notify('Batch Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Batch Generation Done!',
      requireInteraction: true,
    };

    void Promise.all(selectedPairs.map(([sId]) => this.storeUpdate(sId, { _currBatchStatus: 'PENDING' })));

    this.pusher
      .rpc('BUILD_CLAIMS_BATCH', {
        // signatureIds: selectedIds,
        // tripIds: selectedTripIds,
        pairs: selectedPairs,
        byFacility: e.itemData === 'by Facility',
        useRunService: true,
      })
      .pipe(
        switchMap(() =>
          Promise.all(selectedPairs.map(([sId]) => this.storeUpdate(sId, { _currBatchStatus: 'IN BATCH' }))),
        ),
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();

    // this.ui.openEditDialog({
    //   modelId: null,
    //   ModelClass: Consumer,
    //   FormComponentClass: ConsumerFormComponent,
    // });
  }

  grid_toolbar_buildMealsBatch_onClick() {
    // const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();
    // const selectedTripIds: any[] = this.grid.instance.getSelectedRowsData().map((d) => oc(d)._trip._tripId());
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    // console.log(this.grid.instance.getDataSource().sort());

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Meals Batch Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Meals Batch Generation Done!',
      requireInteraction: true,
    };

    void Promise.all(selectedPairs.map(([sId]) => this.storeUpdate(sId, { _currBatchStatus: 'PENDING' })));

    this.pusher
      .rpc('BUILD_CLAIMS_BATCH', {
        // signatureIds: selectedIds,
        // tripIds: selectedTripIds,
        pairs: selectedPairs,
        serviceType: 'MEALS',
        useRunService: true,
      })
      .pipe(
        switchMap(() =>
          Promise.all(selectedPairs.map(([sId]) => this.storeUpdate(sId, { _currBatchStatus: 'IN BATCH' }))),
        ),
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => console.log(res));
  }

  grid_toolbar_buildCtsReport_onClick(e: { itemData }) {
    const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();

    if (selectedIds.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'CTS Order Batch Generation Done!',
      requireInteraction: true,
    };

    void Promise.all(selectedIds.map(sId => this.storeUpdate(sId, { _currBatchStatus: 'PENDING' })));

    this.pusher
      .rpc('BUILD_CTS_ORDER_BATCH', {
        signatureIds: selectedIds,
        byFacility: e.itemData === 'by Facility',
        useRunService: true,
      })
      .pipe(
        switchMap(() => Promise.all(selectedIds.map(sId => this.storeUpdate(sId, { _currBatchStatus: 'IN BATCH' })))),
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_buildMtmAuthsToAdd_onClick(e: { itemData }) {
    const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();

    if (selectedIds.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'MTM Auths to Add Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_MTM_AUTHS_TO_ADD', {
        signatureIds: selectedIds,
        byFacility: e.itemData === 'by Facility',
        useRunService: true,
      })
      .pipe(
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_toolbar_buildMealsReq_onClick() {
    const selectedIds: any[] = this.grid.instance.getSelectedRowKeys();

    if (selectedIds.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Build Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Meals Trips Request Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_MEALS_TRIPS_REQ', {
        signatureIds: selectedIds,
        useRunService: true,
      })
      .pipe(
        tap(({ urls }) => (urls || []).forEach(url => window.open(url))),
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(res => console.log(res));
  }

  grid_toolbar_buildSchoolReport_onClick() {
    const selectedPairs: any[] = this.grid.instance.getSelectedRowsData().map(s => [s.id, oc(s)._trip._tripId()]);

    if (selectedPairs.length === 0) {
      notify('No trips selected', 'error', 5000);
      return;
    }

    notify('Report Requested');

    const notificationOptions: NotificationOptions = {
      body: 'Report Generation Done!',
      requireInteraction: true,
    };

    this.pusher
      .rpc('BUILD_SCHOOL_REPORT_RPC', {
        pairs: selectedPairs,
        useRunService: true,
      })
      .pipe(
        tap((uris: string[]) => (uris || []).forEach(uri => window.open(uri))),
        //
        tap(() => this.notification.generateNotification({ title: 'Done!', opts: notificationOptions })),
        catchError(err => of(notify(err.message, 'error', 5000))),
        takeUntil(this.$onDestroy$),
      )
      .subscribe(console.log);
  }

  ///////////////////////

  vehicleData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.vehicleDsMap[id]) {
      self.vehicleDsMap[id] = {};
    }

    if (!self.vehicleDsMap[id].fullInstance) {
      const inst$ = this.dss.getApi<VehicleApi>(Vehicle).findById(id, {}, hAll);

      self.vehicleDsMap[id].fullInstance = inst$;
    }

    return self.vehicleDsMap[id].fullInstance;
  }

  driverData(id) {
    const self = this;

    if (!id) {
      return of({});
    }

    if (!self.driverDsMap[id]) {
      self.driverDsMap[id] = {};
    }

    if (!self.driverDsMap[id].fullInstance) {
      const inst$ = this.dss.getApi<EmployeeApi>(Employee).findById(
        id,
        {
          include: [{ person: { contact: ['addresses', 'phones', 'emails'] } }],
        },
        hAll,
      );

      self.driverDsMap[id].fullInstance = inst$;
    }

    return self.driverDsMap[id].fullInstance;
  }
}

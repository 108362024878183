import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { takeUntil, tap } from 'rxjs/operators';
import { Document, DocumentType, LastDocument, LoggerService, Person, Vehicle } from '../../../../shared/sdk';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import moment from 'moment';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { BASE_COLUMNS } from '../../classes/enums';
import { DocumentFormComponent } from 'src/app/modules/document/components/document-form/document-form.component';
import { API_BASE_URL } from 'src/app/config';
import { DlgVehicleDocumentGridComponent } from 'src/app/modules/vehicle/components/dlg-vehicle-document-grid/dlg-vehicle-document-grid.component';

@Component({
  selector: 'app-vehicle-grid',
  templateUrl: './vehicle-grid.component.html',
  styleUrls: ['./vehicle-grid.component.scss'],
})
export class VehicleGridComponent extends ABaseComponent implements OnInit {
  filter = null;

  dso: DataSourceOptions;
  filterDso = [
    { title: 'Expried', value: [0, 'weeks'] },
    { title: 'Show Missing Documents', value: [0, '', 'missing'] },
    { title: 'Expiring Next Week', value: [1, 'weeks'] },
    { title: 'Expiring Next Two weeks', value: [2, 'weeks'] },
    { title: 'Expiring Next Month', value: [1, 'months'] },
    { title: 'Expiring Next Two Months', value: [2, 'months'] },
    { title: 'Expiring Next Three Months', value: [3, 'months'] },
    { title: 'Expiring Next Six Months', value: [6, 'months'] },
    { title: 'Expiring Next Year', value: [1, 'years'] },
  ];

  gridColumns: any[];
  columnsMap = {};
  last: any[];
  docTypes: any[];

  grid_stateStoring: any;

  @Output() mySelected: EventEmitter<Vehicle[]> = new EventEmitter<Vehicle[]>();

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    protected logger: LoggerService,
    public config: ConfigService,
    private common: CommonService,
    private ui: UiService,
    private dss: DataSourceService,
    private sss: StateStoreService,
    private gridHelper: GridHelperService,
    protected dialog: MatDialog,
  ) {
    super(logger);

    this.buildDso();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  repaint(): void {
    this.grid && this.grid.instance && this.grid.instance.repaint();
  }

  expiring_onSelectionChanged(e) {
    this.grid.instance.refresh();
  }

  addDoc_onClick(vehicle: Vehicle, col) {
    const { documentTypeId } = (col && this.columnsMap[col.caption]) || ({} as any);
    this.ui
      .openEditDialog({
        modelId: null,
        title: `${vehicle.internalId} | New Document`,
        inputs: {
          objectType: Vehicle.getModelName(),
          objectId: vehicle.id,
          documentTypeId,
        },
        ModelClass: Document,
        FormComponentClass: DocumentFormComponent,
      })
      .afterClosed()
      .toPromise()
      .then(payroll => {
        if (payroll) this.grid.instance.refresh();
      });
  }

  relatedDocs_onClick(vehicle: Vehicle) {
    void this.dialog
      .open<any, any, number>(DlgVehicleDocumentGridComponent, {
        hasBackdrop: true,
        data: {
          title: `${vehicle.internalId} | Related Documents`,
          model: vehicle,
        },
      })
      .afterClosed()
      .toPromise()
      .then(payroll => {
        this.grid.instance.refresh();
      });
  }

  dateFormat = date => moment(date).format('MM/DD/YYYY');

  isAlerted = cellInfo => {
    const { expAlert, missingDocAlert } = this.columnsMap[cellInfo.column.caption] || ({} as any);
    return this.isFiltered(cellInfo.data, cellInfo.value, expAlert, missingDocAlert);
  };

  isFiltered = (vehicle: Vehicle, doc, expAlert, missingDocAlert) => {
    if (!this.filter) return false;
    const [i, key, type] = this.filter;
    if (type === 'missing') return vehicle.state === 'ACTIVE' && missingDocAlert && !doc;
    if (!doc || !doc.value || !expAlert) return false;
    const until = moment().add(i, key).format('YYYY-MM-DD');
    const d = moment(doc.value).format('YYYY-MM-DD');
    return d <= until;
  };

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      notifyErrors: true,
    });
  }

  grid_onSelectionChanged(event: any): void {
    this.mySelected.emit(event.selectedRowsData);
  }

  async buildDso() {
    const docTypesFilter = { where: { inactive: 0, objectFQN: 'VEHICLE' }, include: ['customFields'] };
    const docsFilter = {
      where: { objectType: 'Vehicle' },
      include: ['lastFile'],
    };

    const so = this.dss.getStoreOptions(Vehicle, Vehicle, false);
    so.customHeaders = headersAllTenantsAppend;
    const store = new CustomStore(so);

    dxStoreLoadHooks(store, undefined, async (args: any[], [vehs]: any[]) => {
      const mode: 'expiring' | 'missing' = this.filter && this.filter[2] === 'missing' ? 'missing' : 'expiring';

      if (!this.last || !this.docTypes) {
        [this.last, this.docTypes] = await Promise.all([
          gqlMongoLoad(this.dss, 'VehicleGeotab', {}, this.getLastGeoTabAggregate()).pipe().toPromise(),
          this.dss.getApi(DocumentType).find<DocumentType>(docTypesFilter, headersAllTenantsAppend).toPromise(),
        ]);
      }

      const docs = await this.dss
        .getApi(LastDocument)
        .find<LastDocument>(docsFilter, headersAllTenantsAppend)
        .toPromise();
      const mapLast = this.last.reduce(
        (p, v) => ({ ...p, [v.internalId]: { lastDate: moment(v.lastDate, 'YYYYMMDD') } }),
        {},
      );
      const mapDocs = docs.reduce((p, { name: n, objectId, expField, expDate: value, documentTypeId, lastFile: f }) => {
        const url = f && `${API_BASE_URL}/api/MyFiles/${f.id}/redirectToSignedUrl`;
        const name = n.slice(0, 8);
        return {
          ...p,
          [objectId]: { ...p[objectId], [`docName${documentTypeId}`]: { url, name }, [expField]: { url, value } },
        };
      }, {});

      if (!this.gridColumns) {
        this.gridColumns = [...BASE_COLUMNS];
        this.docTypes.forEach(({ name, customFields, id: documentTypeId, expAlert, missingDocAlert }) => {
          let dataField =
            (customFields.find(({ type }) => type === 'EXPIRATION_DATE') || {}).field || `docName${documentTypeId}`;
          this.columnsMap[name] = { dataField, name, documentTypeId, expAlert, missingDocAlert };
          this.gridColumns.push({
            dataField,
            caption: name,
            cellTemplate: 'exp_cell',
            calculateDisplayValue: v =>
              (v[dataField] && (v[dataField].name || this.dateFormat(v[dataField].value))) || '',
          });
        });
        this.grid_stateStoring = this.sss.buildOptions(`04bbfe0a-b69e-42ad-a1a4-57b77e1543f1`, 1000, 'local');
      }

      const res = vehs.map(e => ({ ...e, ...mapLast[e.internalId], ...mapDocs[e.id] }));
      const columns = Object.values(this.columnsMap) as any[];
      if (this.filter)
        return [
          res.filter(emp =>
            columns.find(({ dataField, expAlert, missingDocAlert }) =>
              this.isFiltered(emp, emp[dataField], expAlert, missingDocAlert),
            ),
          ),
        ];

      return [res];
    });

    this.dso = { store } as DataSourceOptions;
  }

  getLastGeoTabAggregate() {
    return [
      { $match: { month: { $gte: +moment().subtract(30, 'days').format('YYYYMM') } } },
      { $unwind: '$days' },
      { $project: { internalId: 1, distance: '$days.distance', day: '$days.day' } },
      { $match: { distance: { $gte: 1 } } },
      { $sort: { day: 1 } },
      { $group: { _id: '$internalId', last: { $last: '$$ROOT' } } },
      {
        $project: { internalId: '$last.internalId', lastDate: '$last.day' },
      },
    ];
  }
}


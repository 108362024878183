<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">Trip History for {{ data.trip.tId }}</div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: -1em 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div mat-dialog-content style="padding-top: 3px">
  <dx-data-grid
    #grid
    height="400"
    [dataSource]="dso$ | async"
    [columnChooser]="{ enabled: false }"
    [searchPanel]="{ visible: false }"
    [hoverStateEnabled]="true"
    [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
    [pager]="{ visible: false }"
    [selection]="gridSelection"
    [paging]="gridPaging"
    [filterRow]="gridFilterRow"
    [scrolling]="gridScrolling"
    (onSelectionChanged)="grid_onSelectionChanged($event)">
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>

    <dxi-column caption="Date" dataField="ctime" dataType="datetime" allowFiltering="false"></dxi-column>
    <dxi-column caption="Device" dataField="device"></dxi-column>
    <dxi-column caption="User" dataField="user"></dxi-column>
    <dxi-column caption="Tool" dataField="caller"></dxi-column>
    <dxi-column caption="Service Type" dataField="st"></dxi-column>
    <dxi-column caption="Origin" dataField="o" cellTemplate="updated"></dxi-column>
    <dxi-column caption="Destination" dataField="d" cellTemplate="updated"></dxi-column>
    <dxi-column caption="Broker" dataField="b" cellTemplate="updated"></dxi-column>
    <dxi-column caption="Trip ID" dataField="tId" cellTemplate="updated"></dxi-column>
    <dxi-column
      caption="Driver"
      dataField="e"
      [calculateDisplayValue]="getDriverFullName"
      cellTemplate="updated"></dxi-column>
    <dxi-column caption="Vehicle" dataField="internalId" cellTemplate="updated"></dxi-column>
    <dxi-column
      caption="Escort"
      dataField="esc"
      [calculateDisplayValue]="getEscFullName"
      cellTemplate="updated"></dxi-column>
    <dxi-column caption="Trip" dataField="tr" cellTemplate="updated"></dxi-column>
    <dxi-column caption="Time" dataField="t" dataType="date" format="shortTime" cellTemplate="updated"></dxi-column>
    <dxi-column
      caption="Appointment Time"
      dataField="at"
      dataType="date"
      format="shortTime"
      cellTemplate="updated"></dxi-column>
    <dxi-column
      caption="Consumer"
      dataField="c"
      [calculateDisplayValue]="getConsumerFullName"
      cellTemplate="updated"></dxi-column>

    <ng-container *dxTemplate="let cellInfo of 'updated'">
      <span [innerHTML]="getUpdatedCell(cellInfo)"></span>
    </ng-container>
  </dx-data-grid>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    (click)="restoreManifest(grid?.selectedRowKeys)"
    color="primary"
    [disabled]="!grid?.selectedRowKeys?.length">
    Restore
  </button>
</div>


import { Pipe, PipeTransform } from '@angular/core';
import { chunk } from 'lodash-es';
import { utc } from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { gqlMongoDoc } from '../../../classes/loopback-custom-store/generic/store.utils';
import { DataSourceService } from '../services/datasource.service';

@Pipe({
  name: 'tripPopup',
})
export class TripPopupPipe implements PipeTransform {
  constructor(protected dss: DataSourceService) {}

  transform(tripId: any, broker: any, date: string, cols = 5, trip = null, issuesHtml = ''): Observable<string> {
    const col = (broker === 'LGTC' ? 'LGTC_' : '') + 'ExportsDataCache';
    const html = doc =>
      '<div style="text-align: left">' +
      (issuesHtml ? `<div style="color:red">Issues:</div><div>${issuesHtml}</div>` : '') +
      `<div><b>${doc._firstname} ${doc._lastname}</b> DOB: <b>${
        (oc(doc)._source(doc)['Date of Birth'] || oc(doc)._source(doc).dateOfBirth || '').split('T')[0]
      }</b>, MCI: <b>${doc._mci}</b>, Phone: <b>${
        oc(doc)._source(doc)["Member's Phone Number"] || oc(doc)._source(doc).memberPhoneNumber || ''
      }</b></div><br>` +
      '<div>Broker Trip Info:</div><table style="border: none"><tr>' +
      chunk(
        Object.entries<any>(oc(doc)._source(doc)).filter(
          ([k]) => !k.startsWith('_') && !['Trip Cost', 'tripCost'].includes(k),
        ),
        cols,
      )
        .map(
          chnk =>
            '<td>' +
            chnk
              .map(
                ([k, v]) =>
                  `<em>${k}</em>: <strong><span title="${v}" style="max-width: 250px; display: inline-block; overflow: hidden; text-overflow: ellipsis;">${v}</span></strong>`,
              )
              .join('</td><td>') +
            '</td>',
        )
        .join('</tr><tr>') +
      '</tr></table></div>';

    return trip
      ? of(html(trip))
      : gqlMongoDoc(this.dss, col, {
          _tripId: tripId,
          ...(date ? { _date: { $date: { v: utc(date, 'YYYY-MM-DD').toDate().toISOString() } } } : {}),
        }).pipe(
          map(doc => (doc ? html(doc) : 'No Trip')),
          catchError(err => of('')),
          startWith('...'),
        );
  }
}

<app-content-w-header header="Settings" xmlns="http://www.w3.org/1999/html">
  <div class="content" style="width: 100%; height: 100%; display: flex">
    <div style="flex: auto; position: relative">
      <div style="position: absolute; left: 0; right: 0; top: 0; bottom: 0">
        <dx-scroll-view>
          <mat-card>
            <div class="dx-fieldset-header">Destinations</div>
            <div class="dx-fieldset">
              <dx-data-grid [height]="400" [dataSource]="destinationsDso">
                <dxo-editing
                  mode="inline"
                  [allowAdding]="true"
                  [allowUpdating]="true"
                  [allowDeleting]="true"></dxo-editing>

                <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>
                <dxo-export [enabled]="true" fileName="destinations-export"></dxo-export>
                <!-- Columns -->

                <dxi-column caption="Name" dataField="name"></dxi-column>
                <dxi-column caption="Address" dataField="address"></dxi-column>

                <!-- Templates -->
              </dx-data-grid>
            </div>
          </mat-card>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</app-content-w-header>


import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Consumer, EmployeeView, LoggerService, MyUser, Vehicle, } from '../../../../shared/sdk';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { of } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
export class DlgTripHistoryComponent {
    constructor(dialogRef, data, logger, dss, config, ui, helper, dialog, sanitizer, consumerHelper) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.logger = logger;
        this.dss = dss;
        this.config = config;
        this.ui = ui;
        this.helper = helper;
        this.dialog = dialog;
        this.sanitizer = sanitizer;
        this.consumerHelper = consumerHelper;
        this.dso$ = of([]);
        this.rows = [];
        this.driversMap = {};
        this.consumersMap = {};
        this.gridColumns = [
            { dataField: 'ctime', caption: 'Date', dataType: 'datetime', allowFiltering: false },
            { dataField: 'device', caption: 'Device' },
            { dataField: 'user', caption: 'user' },
            { dataField: 'caller', caption: 'Tool' },
            { dataField: 'data.length', caption: 'Manifest', cellTemplate: 'manifest_cell', textAlign: 'center' },
        ];
        this.gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
        this.gridPaging = { enabled: true, pageSize: 10 };
        this.gridFilterRow = { visible: true };
        this.gridScrolling = { mode: 'infinite' };
        this.getDriverFullName = (trip) => {
            const empl = this.driversMap[trip.e];
            return empl ? `${empl.person_firstname} ${empl.person_lastname}` : '';
        };
        this.getEscFullName = (trip) => {
            const empl = this.driversMap[trip.esc];
            return empl ? `${empl.person_firstname} ${empl.person_lastname}` : '';
        };
        this.getConsumerFullName = (trip) => this.consumerHelper.displayExpr(this.consumersMap[trip.c]);
        this.errorStyle = 'background-color: #c1ffc1; color: #333; padding: 2px 3px; border-radius: 4px;margin-left: -3px;';
    }
    ngOnInit() {
        this.buildDso();
    }
    restoreManifest([record]) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!record) {
                this.dialogRef.close(false);
                return;
            }
            console.log('restoreManifest', record);
            this.ui.showLoading();
            yield this.helper.api.updateManifest(this.data.manifestId, record.data || []).toPromise();
            this.ui.hideLoading();
            this.dialogRef.close(true);
        });
    }
    getUpdatedCell(cellInfo) {
        const curRow = this.rows[cellInfo.rowIndex];
        const curVal = curRow && curRow[cellInfo.column.dataField];
        const nextRow = this.rows[cellInfo.rowIndex + 1];
        const nextVal = nextRow && nextRow[cellInfo.column.dataField];
        let html = `<span style="${nextRow && nextVal != curVal && this.errorStyle}">${cellInfo.text}</span>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    buildDso() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ui.showLoading();
            const stages = [{ $match: { entityId: this.data.manifestId } }, { $sort: { ctime: 1 } }];
            const logRows = yield gqlMongoLoad(this.dss, 'EntityActionLog_TripManifest', {}, stages).pipe().toPromise();
            let prevTrip = {};
            const rows = logRows
                .map(v => {
                const data = (v.entity && v.entity.after && v.entity.after.data) || [];
                const trip = data.find(({ id }) => id === this.data.trip.id);
                if (!trip)
                    return null;
                trip.rid = null; // exclude from comparison
                if (!Object.keys(trip).some(k => trip[k] !== prevTrip[k]))
                    return null;
                prevTrip = trip;
                return trip && Object.assign({}, v, trip, { entity: undefined });
            })
                .filter(v => v)
                .reverse();
            console.log('rows', rows);
            const users = yield this.dss
                .getApi(MyUser)
                .find({ where: { id: { inq: [...new Set(rows.map(r => r.userId))] } } })
                .toPromise();
            const usersMap = users.reduce((p, u) => (Object.assign({}, p, { [u.id]: u.username })), {});
            const [consumers, drivers, vehs] = yield Promise.all([
                this.dss
                    .getApi(Consumer)
                    .find({
                    where: { id: { inq: [...new Set(rows.map(({ c }) => c))] } },
                    include: [{ person: { contact: ['addresses', 'phones'] } }],
                }, headersAllTenantsAppend)
                    .toPromise(),
                this.dss
                    .getApi(EmployeeView)
                    .find({ where: { employeeId: { inq: [...new Set(rows.flatMap(({ e, esc }) => [e, esc]))] } } }, headersAllTenantsAppend)
                    .toPromise(),
                this.dss.getApi(Vehicle).find().toPromise(),
            ]);
            this.driversMap = drivers.reduce((p, d) => (Object.assign({}, p, { [d.id]: d })), {});
            this.consumersMap = consumers.reduce((p, c) => (Object.assign({}, p, { [c.id]: c })), {});
            const vehsMap = vehs.reduce((p, v) => (Object.assign({}, p, { [v.id]: v })), {});
            console.log('rows', rows);
            rows.forEach((r, i) => {
                r.device =
                    (r.headers && !r.headers['x-caller'] && ((r.headers['x-application-name'] && 'MobileApp') || 'WebPortal')) ||
                        'System';
                r.user = usersMap[r.userId];
                r.caller = r.headers && r.headers['x-caller'];
                r.internalId = (vehsMap[r.v] && vehsMap[r.v].internalId) || '';
            });
            this.dso$ = of(rows);
            this.rows = rows;
            this.ui.hideLoading();
        });
    }
    grid_onSelectionChanged(e) { }
}

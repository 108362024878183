import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoggerService, MyUser, MyUserApi, TripManifestRec } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { Observable, of } from 'rxjs';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';

@Component({
  selector: 'app-dlg-schedule-history-grid',
  templateUrl: './dlg-schedule-history-grid.component.html',
  styleUrls: ['./dlg-schedule-history-grid.component.scss'],
  providers: [ConsumerHelperService, HelperService],
})
export class DlgScheduleHistoryGridComponent implements OnInit {
  dso$: Observable<any[]> = of([]);
  constructor(
    private dialogRef: MatDialogRef<DlgScheduleHistoryGridComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected logger: LoggerService,
    public config: ConfigService,
    public helper: HelperService,
    public consumerHelper: ConsumerHelperService,
    private dss: DataSourceService,
    private ui: UiService,
  ) {
    this.buildDso();
  }

  ngOnInit() {}
  async buildDso() {
    this.ui.showLoading();
    const schedule = this.data.row.schedule;
    const stages = [
      { $match: { $or: [{ 'entity.id': schedule.id }, { 'entity.before.id': schedule.id }] } },
      { $sort: { ctime: -1 } },
    ];
    const logRows = await gqlMongoLoad(this.dss, 'EntityActionLog_DriverSchedule', {}, stages).pipe().toPromise();
    const rows = [schedule, ...logRows.slice(0, -1).map(v => v.entity.before)];
    const users: MyUser[] = await this.dss
      .getApi<MyUserApi>(MyUser)
      .find<MyUser>({
        where: { id: { inq: rows.map(r => r.updatedBy) } },
        include: [{ employee: ['person'] }],
      })
      .toPromise();
    const usersMap = users.reduce(
      (p, u) => ({ ...p, [u.id]: (u.employee && new FullNamePipe(this.config).transform(u.employee)) || u.username }),
      {},
    );
    rows.forEach((r, i) => {
      const h = logRows[i].headers;
      r.ctime = logRows[i].ctime;
      r.device = (h && ((h['x-application-name'] && `Tablet.${h['x-application-name']}`) || 'Web')) || 'System';
      r.user = usersMap[r.updatedBy];
    });

    this.dso$ = of(rows);
    this.ui.hideLoading();
  }
}


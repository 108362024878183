
declare var Object: any;
export interface ConsumerAddressViewInterface {
  "id": number;
  "activeBroker"?: string;
  "activeMco"?: string;
  "autoDispatch"?: number;
  "avoidEmployees"?: any;
  "brokerNotes"?: string;
  "c10nAgency"?: string;
  "clientSince"?: any;
  "contact_addresses_0_city"?: string;
  "contact_addresses_0_id"?: string;
  "contact_addresses_0_state"?: string;
  "contact_addresses_0_street"?: string;
  "contact_addresses_0_zip"?: string;
  "ctime": Date;
  "cwEmail"?: string;
  "cwFax"?: string;
  "cwName"?: string;
  "cwNote"?: string;
  "cwPhone"?: string;
  "data": any;
  "daysNotes"?: string;
  "defaultDestination"?: string;
  "dtime"?: Date;
  "dtime_str"?: string;
  "eligibility": any;
  "facility_id"?: number;
  "facility_legalName"?: string;
  "facility_shortname"?: string;
  "facility_type"?: string;
  "facilityID"?: string;
  "hash1": string;
  "hash2": string;
  "internalID"?: string;
  "keepStretcher"?: number;
  "lastEligibility"?: string;
  "lastEligibilityDate"?: any;
  "lastNote"?: string;
  "lastNote_author"?: string;
  "lastNote_dateTime"?: Date;
  "lastNote_followUpDate"?: any;
  "lastNote_id"?: number;
  "lastNote_infoBy"?: string;
  "lastNote_infoDate"?: any;
  "lastNote_meta"?: any;
  "lastNote_source"?: string;
  "lastNote_text"?: string;
  "lastNote_user_username"?: string;
  "lastNote_userId"?: number;
  "legacyCustomerID"?: string;
  "mci"?: string;
  "mealMeta": any;
  "notes"?: string;
  "offBoardingDuration"?: number;
  "onBoardingDuration"?: number;
  "persAlertsLast12M"?: number;
  "persAlertsLast30D"?: number;
  "persAlertsLast90D"?: number;
  "persLastSuccessfulTest"?: Date;
  "persNotes"?: string;
  "person_contact_id"?: number;
  "person_contactId"?: number;
  "person_data"?: any;
  "person_dob"?: any;
  "person_firstname"?: string;
  "person_id"?: number;
  "person_lastname"?: string;
  "person_middlename"?: string;
  "person_nickname"?: string;
  "person_notes"?: string;
  "person_sex"?: string;
  "personId": number;
  "preferredEmployees"?: any;
  "profileImgFileId"?: string;
  "program_id"?: number;
  "program_name"?: string;
  "programId"?: number;
  "repetitiveMode"?: string;
  "signImgFileId"?: string;
  "source"?: string;
  "specialInstrs"?: string;
  "status": string;
  "tcInformedConsentDocumentSignatureDate"?: any;
  "tcInformedConsentFile"?: string;
  "tenantId": number;
  "transpCodes": any;
  "transpInstrs"?: string;
  "utime": Date;
  "weekDays": any;
}

export class ConsumerAddressView implements ConsumerAddressViewInterface {
  "id": number;
  "activeBroker": string;
  "activeMco": string;
  "autoDispatch": number;
  "avoidEmployees": any;
  "brokerNotes": string;
  "c10nAgency": string;
  "clientSince": any;
  "contact_addresses_0_city": string;
  "contact_addresses_0_id": string;
  "contact_addresses_0_state": string;
  "contact_addresses_0_street": string;
  "contact_addresses_0_zip": string;
  "ctime": Date;
  "cwEmail": string;
  "cwFax": string;
  "cwName": string;
  "cwNote": string;
  "cwPhone": string;
  "data": any;
  "daysNotes": string;
  "defaultDestination": string;
  "dtime": Date;
  "dtime_str": string;
  "eligibility": any;
  "facility_id": number;
  "facility_legalName": string;
  "facility_shortname": string;
  "facility_type": string;
  "facilityID": string;
  "hash1": string;
  "hash2": string;
  "internalID": string;
  "keepStretcher": number;
  "lastEligibility": string;
  "lastEligibilityDate": any;
  "lastNote": string;
  "lastNote_author": string;
  "lastNote_dateTime": Date;
  "lastNote_followUpDate": any;
  "lastNote_id": number;
  "lastNote_infoBy": string;
  "lastNote_infoDate": any;
  "lastNote_meta": any;
  "lastNote_source": string;
  "lastNote_text": string;
  "lastNote_user_username": string;
  "lastNote_userId": number;
  "legacyCustomerID": string;
  "mci": string;
  "mealMeta": any;
  "notes": string;
  "offBoardingDuration": number;
  "onBoardingDuration": number;
  "persAlertsLast12M": number;
  "persAlertsLast30D": number;
  "persAlertsLast90D": number;
  "persLastSuccessfulTest": Date;
  "persNotes": string;
  "person_contact_id": number;
  "person_contactId": number;
  "person_data": any;
  "person_dob": any;
  "person_firstname": string;
  "person_id": number;
  "person_lastname": string;
  "person_middlename": string;
  "person_nickname": string;
  "person_notes": string;
  "person_sex": string;
  "personId": number;
  "preferredEmployees": any;
  "profileImgFileId": string;
  "program_id": number;
  "program_name": string;
  "programId": number;
  "repetitiveMode": string;
  "signImgFileId": string;
  "source": string;
  "specialInstrs": string;
  "status": string;
  "tcInformedConsentDocumentSignatureDate": any;
  "tcInformedConsentFile": string;
  "tenantId": number;
  "transpCodes": any;
  "transpInstrs": string;
  "utime": Date;
  "weekDays": any;
  constructor(data?: ConsumerAddressViewInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ConsumerAddressView`.
   */
  public static getModelName() {
    return "ConsumerAddressView";
  }

  /**
  * This method creates an instance of ConsumerAddressView for dynamic purposes.
  **/
  public static factory(data: ConsumerAddressViewInterface): ConsumerAddressView{
    return new ConsumerAddressView(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ConsumerAddressView',
      plural: 'ConsumerAddressViews',
      path: 'ConsumerAddressViews',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'number'
        },
        "activeBroker": {
          name: 'activeBroker',
          type: 'string'
        },
        "activeMco": {
          name: 'activeMco',
          type: 'string'
        },
        "autoDispatch": {
          name: 'autoDispatch',
          type: 'number'
        },
        "avoidEmployees": {
          name: 'avoidEmployees',
          type: 'any'
        },
        "brokerNotes": {
          name: 'brokerNotes',
          type: 'string'
        },
        "c10nAgency": {
          name: 'c10nAgency',
          type: 'string'
        },
        "clientSince": {
          name: 'clientSince',
          type: 'any'
        },
        "contact_addresses_0_city": {
          name: 'contact_addresses_0_city',
          type: 'string'
        },
        "contact_addresses_0_id": {
          name: 'contact_addresses_0_id',
          type: 'string'
        },
        "contact_addresses_0_state": {
          name: 'contact_addresses_0_state',
          type: 'string'
        },
        "contact_addresses_0_street": {
          name: 'contact_addresses_0_street',
          type: 'string'
        },
        "contact_addresses_0_zip": {
          name: 'contact_addresses_0_zip',
          type: 'string'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "cwEmail": {
          name: 'cwEmail',
          type: 'string'
        },
        "cwFax": {
          name: 'cwFax',
          type: 'string'
        },
        "cwName": {
          name: 'cwName',
          type: 'string'
        },
        "cwNote": {
          name: 'cwNote',
          type: 'string'
        },
        "cwPhone": {
          name: 'cwPhone',
          type: 'string'
        },
        "data": {
          name: 'data',
          type: 'any'
        },
        "daysNotes": {
          name: 'daysNotes',
          type: 'string'
        },
        "defaultDestination": {
          name: 'defaultDestination',
          type: 'string'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "dtime_str": {
          name: 'dtime_str',
          type: 'string'
        },
        "eligibility": {
          name: 'eligibility',
          type: 'any'
        },
        "facility_id": {
          name: 'facility_id',
          type: 'number'
        },
        "facility_legalName": {
          name: 'facility_legalName',
          type: 'string'
        },
        "facility_shortname": {
          name: 'facility_shortname',
          type: 'string'
        },
        "facility_type": {
          name: 'facility_type',
          type: 'string'
        },
        "facilityID": {
          name: 'facilityID',
          type: 'string'
        },
        "hash1": {
          name: 'hash1',
          type: 'string'
        },
        "hash2": {
          name: 'hash2',
          type: 'string'
        },
        "internalID": {
          name: 'internalID',
          type: 'string'
        },
        "keepStretcher": {
          name: 'keepStretcher',
          type: 'number'
        },
        "lastEligibility": {
          name: 'lastEligibility',
          type: 'string'
        },
        "lastEligibilityDate": {
          name: 'lastEligibilityDate',
          type: 'any'
        },
        "lastNote": {
          name: 'lastNote',
          type: 'string'
        },
        "lastNote_author": {
          name: 'lastNote_author',
          type: 'string'
        },
        "lastNote_dateTime": {
          name: 'lastNote_dateTime',
          type: 'Date'
        },
        "lastNote_followUpDate": {
          name: 'lastNote_followUpDate',
          type: 'any'
        },
        "lastNote_id": {
          name: 'lastNote_id',
          type: 'number'
        },
        "lastNote_infoBy": {
          name: 'lastNote_infoBy',
          type: 'string'
        },
        "lastNote_infoDate": {
          name: 'lastNote_infoDate',
          type: 'any'
        },
        "lastNote_meta": {
          name: 'lastNote_meta',
          type: 'any'
        },
        "lastNote_source": {
          name: 'lastNote_source',
          type: 'string'
        },
        "lastNote_text": {
          name: 'lastNote_text',
          type: 'string'
        },
        "lastNote_user_username": {
          name: 'lastNote_user_username',
          type: 'string'
        },
        "lastNote_userId": {
          name: 'lastNote_userId',
          type: 'number'
        },
        "legacyCustomerID": {
          name: 'legacyCustomerID',
          type: 'string'
        },
        "mci": {
          name: 'mci',
          type: 'string'
        },
        "mealMeta": {
          name: 'mealMeta',
          type: 'any'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "offBoardingDuration": {
          name: 'offBoardingDuration',
          type: 'number'
        },
        "onBoardingDuration": {
          name: 'onBoardingDuration',
          type: 'number'
        },
        "persAlertsLast12M": {
          name: 'persAlertsLast12M',
          type: 'number'
        },
        "persAlertsLast30D": {
          name: 'persAlertsLast30D',
          type: 'number'
        },
        "persAlertsLast90D": {
          name: 'persAlertsLast90D',
          type: 'number'
        },
        "persLastSuccessfulTest": {
          name: 'persLastSuccessfulTest',
          type: 'Date'
        },
        "persNotes": {
          name: 'persNotes',
          type: 'string'
        },
        "person_contact_id": {
          name: 'person_contact_id',
          type: 'number'
        },
        "person_contactId": {
          name: 'person_contactId',
          type: 'number'
        },
        "person_data": {
          name: 'person_data',
          type: 'any'
        },
        "person_dob": {
          name: 'person_dob',
          type: 'any'
        },
        "person_firstname": {
          name: 'person_firstname',
          type: 'string'
        },
        "person_id": {
          name: 'person_id',
          type: 'number'
        },
        "person_lastname": {
          name: 'person_lastname',
          type: 'string'
        },
        "person_middlename": {
          name: 'person_middlename',
          type: 'string'
        },
        "person_nickname": {
          name: 'person_nickname',
          type: 'string'
        },
        "person_notes": {
          name: 'person_notes',
          type: 'string'
        },
        "person_sex": {
          name: 'person_sex',
          type: 'string'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
        "preferredEmployees": {
          name: 'preferredEmployees',
          type: 'any'
        },
        "profileImgFileId": {
          name: 'profileImgFileId',
          type: 'string'
        },
        "program_id": {
          name: 'program_id',
          type: 'number'
        },
        "program_name": {
          name: 'program_name',
          type: 'string'
        },
        "programId": {
          name: 'programId',
          type: 'number'
        },
        "repetitiveMode": {
          name: 'repetitiveMode',
          type: 'string'
        },
        "signImgFileId": {
          name: 'signImgFileId',
          type: 'string'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "specialInstrs": {
          name: 'specialInstrs',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "tcInformedConsentDocumentSignatureDate": {
          name: 'tcInformedConsentDocumentSignatureDate',
          type: 'any'
        },
        "tcInformedConsentFile": {
          name: 'tcInformedConsentFile',
          type: 'string'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "transpCodes": {
          name: 'transpCodes',
          type: 'any'
        },
        "transpInstrs": {
          name: 'transpInstrs',
          type: 'string'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "weekDays": {
          name: 'weekDays',
          type: 'any'
        },
      },
      relations: {
      }
    }
  }
}

<div class="filter-panel">
  <dx-date-box #from width="100px" [(value)]="selectedDate" (onValueChanged)="calendar_onValueChanged($event)">
    <ng-container *dxTemplate="let data of 'cell'">
      {{ data.text }}
    </ng-container>
  </dx-date-box>
  <label>Notification:</label>
  <dx-select-box
    width="150px"
    [(value)]="selectedType"
    [items]="typeOptions"
    displayExpr="name"
    valueExpr="value"
    (onValueChanged)="onTypeChanged($event)"></dx-select-box>
  <label>Response Status:</label>
  <dx-select-box
    width="150px"
    [(value)]="selectedStatus"
    [items]="statusOptions"
    displayExpr="name"
    valueExpr="value"
    (onValueChanged)="onStatusChanged($event)"></dx-select-box>
</div>
<dx-data-grid
  #grid
  height="100%"
  [dataSource]="dso"
  [errorRowEnabled]="false"
  [stateStoring]="grid_stateStoring"
  (onInitialized)="grid_onInitialized($event)"
  (onCellPrepared)="grid_onCellPrepared($event)">
  <dxo-group-panel [visible]="true"></dxo-group-panel>

  <dxo-editing mode="form" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

  <dxo-paging pageSize="50"></dxo-paging>
  <dxo-pager [allowedPageSizes]="[20, 50]"></dxo-pager>

  <dxo-export [enabled]="true" fileName="auto-dispatch-export"></dxo-export>

  <!-- Columns -->

  <dxi-column
    caption="Date Sent"
    dataField="dateSent"
    dataType="date"
    format="shortDateShortTime"
    [allowSorting]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"></dxi-column>
  <dxi-column caption="Recipient Name" dataField="recipientName"></dxi-column>
  <dxi-column caption="Status" dataField="status" cellTemplate="status_cell"></dxi-column>
  <dxi-column caption="Recipient" dataField="recipient"></dxi-column>
  <dxi-column caption="Method" dataField="method"></dxi-column>
  <dxi-column caption="Sender" dataField="sender"></dxi-column>
  <dxi-column caption="Origin" dataField="origin"></dxi-column>
  <dxi-column caption="Receipt Required" dataField="receiptRequired"></dxi-column>
  <dxi-column
    caption="Sent By"
    dataField="sentByUser.username"
    [allowSorting]="false"
    [allowFiltering]="false"
    [allowHeaderFiltering]="false"></dxi-column>
  <dxi-column caption="Domain" dataField="domain"></dxi-column>
  <dxi-column caption="Notification" dataField="type"></dxi-column>
  <dxi-column caption="Message" dataField="message"></dxi-column>

  <!-- Templates -->
  <ng-container *dxTemplate="let cellInfo of 'status_cell'">
    <div title="{{ cellInfo.data.responseMessage || 'No response' }}">
      {{ cellInfo.value }}
    </div>
  </ng-container>
</dx-data-grid>


import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { PushNotificationsService } from 'src/app/shared/modules/ui/services/push-notifications.service';
import { MyUtils, MyUtilsApi } from 'src/app/shared/sdk';

@Component({
  selector: 'app-reports-consumer',
  templateUrl: './reports-consumer.component.html',
  styleUrls: ['./reports-consumer.component.scss'],
})
export class ReportsConsumerComponent implements OnInit {
  selectedFromValue: Date;
  selectedToValue: Date;
  isConsumersByDestinationButtonDisabled: boolean = false;

  constructor(protected dss: DataSourceService) {}

  ngOnInit() {
    this.checkExpiration();
  }

  checkExpiration(): void {
    const expirationTime = localStorage.getItem('isConsumersByDestinationButtonDisabledExpirationTime');
    if (expirationTime) {
      const expirationMoment = moment(expirationTime);
      const currentMoment = moment();
      this.isConsumersByDestinationButtonDisabled = currentMoment.isBefore(expirationMoment.add(10, 'minute'));
    }
  }

  async genPeriodReport() {
    const from = moment(this.selectedFromValue);
    const to = moment(this.selectedToValue);
    const weeks = to.diff(from, 'weeks');

    await this.dss.getApi<MyUtilsApi>(MyUtils).mtmTripsReport(to.format('YYYY-MM-DD'), weeks).toPromise();

    this.isConsumersByDestinationButtonDisabled = true;
    localStorage.setItem('isConsumersByDestinationButtonDisabledExpirationTime', moment().format());

    notify(
      'The report is in progress to be generated. To re-generate this report, please go back to this page in 10 minutes.',
      'info',
      20000,
    );
  }
}


import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Consumer, ConsumerApi, DriverSchedule, DriverScheduleApi, Employee, EmployeeDayService, EmployeeDayServiceApi, Facility, LoggerService, Signature, SignatureApi, VehicleCheckUp, VehicleCheckUpApi, } from '../../../../shared/sdk';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import CustomStore from 'devextreme/data/custom_store';
import { dxStoreLoadHooks, gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import moment from 'moment';
import { ScheduleFormComponent } from '../schedule-form/schedule-form.component';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { get } from 'lodash-es';
import { HelperService } from '../../services/helper.service';
import { DlgManifestGridComponent } from '../dlg-manifest-grid/dlg-manifest-grid.component';
import { BACKUP_DRIVER, FLEET_MANAGER, SCHEDULE_MODES, SCHEDULE_STATUSES } from '../../classes/enums';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { DlgAddEmployeeBackupComponent } from '../dlg-add-employee-backup/dlg-add-employee-backup.component';
import { environment } from 'src/environments/environment';
import { asShortDate, asWeekday } from 'src/app/shared/classes/utils/time.utils';
import { DxPopupComponent } from 'devextreme-angular';
import { DlgCalendarComponent } from '../dlg-calendar/dlg-calendar.component';
import { confirm } from 'devextreme/ui/dialog';
import { DlgScheduleHistoryGridComponent } from '../dlg-schedule-history-grid/dlg-schedule-history-grid.component';
import { DlgSendSchedulesComponent } from '../dlg-send-schedules/dlg-send-schedules.component';
const BASE_LOCATION = environment.baseLocation && JSON.parse(environment.baseLocation);
export class ScheduleGridComponent extends ABaseComponent {
    constructor(logger, config, ui, dss, employeeHelper, helper, gridHelper, dialog, router, auth) {
        super(logger);
        this.logger = logger;
        this.config = config;
        this.ui = ui;
        this.dss = dss;
        this.employeeHelper = employeeHelper;
        this.helper = helper;
        this.gridHelper = gridHelper;
        this.dialog = dialog;
        this.router = router;
        this.auth = auth;
        this.selectedDate = new Date();
        this.selectedRows = [];
        this.calendarMap = {};
        this.statuses = SCHEDULE_STATUSES;
        this.modes = SCHEDULE_MODES;
        this.modesLookup = [...this.modes, { ID: 'NO_SCHEDULE', Name: 'NO SCHEDULE' }];
        this.isBackUpEmployeeOnly = false;
        this.notBackUpEmployees = [];
        this.rows = [];
        this.isToastVisible = false;
        this.toastMessage = '';
        this.toastType = 'success'; // can be 'info', 'warning', 'error', or 'success'
        this.formLoaded = false;
        this.progress = 0;
        this.progressCount = 0;
        this.progressTotal = 0;
        this.startPrepareDuration = 40;
        this.manifestExistsDS = [
            { ID: true, Name: 'Yes' },
            { ID: false, Name: 'Not' },
        ];
        this.mySelected = new EventEmitter();
        this.getMapURL = v => {
            const location = `${v.latitude},${v.longitude}`;
            return `https://maps.google.com/?q=${location}&ll=${location}&z=11`;
        };
        this.formatTime = v => (v ? moment(v, 'HH:mm:ss').format('LT') : '');
        this.startTimeCalculatedAt = v => (v ? `(Calculated at: ${moment(v).format('M/D/YYYY LT')})` : '');
        this.calendarClass = ({ date, view }) => {
            const l = this.rows.length;
            if (view !== 'month' || this.isBackUpEmployeeOnly || !l)
                return '';
            const colors = [
                { cls: 'day-green', rate: 0.95 },
                { cls: 'day-yellow', rate: 0.8 },
                { cls: 'day-orange', rate: 0.5 },
                { cls: 'day-red', rate: 0.01 },
            ];
            const d = moment(date).format('YYYY-MM-DD');
            const r = (this.calendarMap[d] || 0) / l;
            return (colors.find(({ rate }) => r >= rate) || { cls: '' }).cls;
        };
        this.calendarTitle = ({ date, view }) => {
            const l = this.rows.length;
            if (view !== 'month' || this.isBackUpEmployeeOnly || !l)
                return '';
            const d = moment(date).format('YYYY-MM-DD');
            const c = this.calendarMap[d] || 0;
            const p = Math.ceil((c / l) * 100);
            return `${p > 100 ? 100 : p}%`;
        };
        this.getPhoneCellValue = (e) => {
            const phones = e.person.contact.phones || [];
            const phone = phones.find(p => (p.label || '').toLowerCase() == 'main') || phones[0];
            return (phone && phone.value) || '';
        };
        this.getManifestText = ({ manifest: m }) => (m ? `Manifest` : '');
        this.getFirstTripLocation = ({ firstTripConsumer: c }) => this.helper.getFullAddress(get(c, `person.contact.addresses[0]`));
        this.getLastTripLocation = ({ lastTripConsumer: c }) => this.helper.getFullAddress(get(c, `person.contact.addresses[0]`));
    }
    ngOnInit() {
        super.ngOnInit();
        this.isBackUpEmployeeOnly = this.router.url.includes('backup-drivers');
        this.buildDso();
        if (!this.isBackUpEmployeeOnly)
            this.buildCalendarMap();
    }
    repaint() {
        this.grid && this.grid.instance && this.grid.instance.repaint();
    }
    showToasts(msg, type = 'success') {
        this.toastMessage = msg;
        this.toastType = type;
        this.isToastVisible = true;
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            notifyErrors: true,
        });
    }
    status_onValueChanged(e, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { schedule, tenantId, facilityId: fid, id } = data;
            const d = { status: e.value };
            if (d.status === BACKUP_DRIVER) {
                d.startTime = '06:00:00';
                if (BASE_LOCATION) {
                    d.startLocationAddress = BASE_LOCATION.address;
                    d.startLocationCoords = BASE_LOCATION.coords;
                }
            }
            let sch = null;
            if (!schedule) {
                const date = `${moment(this.selectedDate).format('YYYY-MM-DD')}T16:00:00.000Z`;
                const facilityId = fid || tenantId || this.auth.getCurrentTenant();
                sch = yield this.dss
                    .getApi(DriverSchedule)
                    .create(Object.assign({ driverId: id, facilityId, date }, d))
                    .toPromise();
            }
            else
                sch = yield this.dss.getApi(DriverSchedule).patchAttributes(schedule.id, d).toPromise();
            data.schedule = sch;
            if (this.isBackUpEmployeeOnly)
                this.grid.instance.refresh();
        });
    }
    mode_onValueChanged(e, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { schedule } = data;
            const d = { mode: e.value };
            const sch = yield this.dss.getApi(DriverSchedule).patchAttributes(schedule.id, d).toPromise();
            data.schedule = sch;
        });
    }
    grid_onEditingStart(e) {
        e.cancel = true;
        const d = moment(this.selectedDate).format('M/D/YYYY');
        const n = this.employeeHelper.displayExpr(e.data);
        const m = e.data.manifest;
        const v = (m && m.firstTrip && `, Vehicle: #${e.data.internalId}`) || '';
        const f = (e.data.facility && `, from Manifest - Facility: ${e.data.facility.shortname}`) || '';
        const title = `Schedule for ${d} - ${n}${f}${v}`;
        this.ui
            .openEditDialog({
            modelId: (e.data.schedule && e.data.schedule.id) || null,
            inputs: {
                data: e.data,
                selectedDate: this.selectedDate,
                facilities: this.facilities,
                facilityMap: this.facilityMap,
            },
            ModelClass: DriverSchedule,
            FormComponentClass: ScheduleFormComponent,
            title,
        }, { minWidth: 1200 })
            .afterClosed()
            .toPromise()
            .then(schedule => {
            if (schedule)
                e.data.schedule = schedule;
        });
    }
    grid_onToolbarPreparing(e) {
        if (this.isBackUpEmployeeOnly)
            e.toolbarOptions.items.unshift({
                name: 'newBackUpEmployee',
                locateInMenu: 'auto',
                widget: 'dxButton',
                location: 'after',
                sortIndex: 30,
                showText: 'inMenu',
                options: {
                    icon: 'fas fa-plus',
                    text: 'Change Employee Status to “Backup”',
                    hint: 'Change Employee Status to “Backup”',
                    onClick: this.grid_toolbar_newBackUpEmployee_onClick.bind(this),
                },
            });
        else {
            e.toolbarOptions.items.unshift({
                name: 'recalculateStartTime',
                locateInMenu: 'auto',
                widget: 'dxButton',
                location: 'after',
                sortIndex: 30,
                showText: 'always',
                options: {
                    text: 'Re-calculate Start Time For All',
                    onClick: this.grid_toolbar_recalculateStartTime_onClick.bind(this),
                },
            }, {
                name: 'cloneSchedules',
                locateInMenu: 'auto',
                widget: 'dxButton',
                location: 'after',
                sortIndex: 30,
                showText: 'always',
                options: { text: `Clone Schedules`, onClick: this.cloneSchedules_onClick.bind(this) },
            }, {
                name: 'dropAllSchedules',
                locateInMenu: 'auto',
                widget: 'dxButton',
                location: 'after',
                // sortIndex: 99,
                showText: 'inMenu',
                options: {
                    type: 'danger',
                    icon: 'trash',
                    text: 'Drop All Employee Schedules',
                    hint: 'Drop All Employee Schedules',
                    onClick: this.dropAllSchedules_onClick.bind(this),
                },
            });
        }
    }
    grid_toolbar_newBackUpEmployee_onClick() {
        const dialogRef = this.dialog.open(DlgAddEmployeeBackupComponent, {
            minWidth: 650,
            data: { notBackUpEmployees: this.notBackUpEmployees },
        });
        dialogRef
            .afterClosed()
            .pipe(tap(selected => {
            if (selected)
                this.status_onValueChanged({ value: BACKUP_DRIVER }, selected);
        }), takeUntil(this.$onDestroy$))
            .subscribe();
    }
    recalculateStartTime_onClick(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.helper.isGoodToRecalculateStartTime(data)) {
                data.schedule = yield this.helper.updateStartTime(data, this.selectedDate, this.startPrepareDuration);
                this.showToasts(`Re-calculated Start Time for ${this.employeeHelper.displayExpr(data)}`);
            }
            else
                this.showToasts(`Start Time is already re-calculated for ${this.employeeHelper.displayExpr(data)}`, 'info');
        });
    }
    history_onClick(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const d = moment(this.selectedDate).format('M/D/YYYY');
            const title = `Employee Scheduling History for ${this.employeeHelper.displayExpr(data.person)} (${d})`;
            void this.dialog.open(DlgScheduleHistoryGridComponent, {
                hasBackdrop: true,
                data: { title, row: data },
                minWidth: 1200,
            });
        });
    }
    sendSchedules_onClick(preparedDataSchedules) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = { preparedDataSchedules, day: asShortDate(this.selectedDate) };
            const res = yield this.dialog
                .open(DlgSendSchedulesComponent, { hasBackdrop: true, data })
                .afterClosed()
                .toPromise();
            if (!res)
                return;
            const { sentCount } = yield this.dss
                .getApi(DriverSchedule)
                .sendSchedule(preparedDataSchedules)
                .toPromise();
            this.showToasts(`Sent Schedules ${sentCount} of ${preparedDataSchedules.length} successfully`);
        });
    }
    dropAllSchedules_onClick() {
        if (moment(this.selectedDate).isBefore(moment().startOf('day')))
            return this.showToasts('Cannot drop schedules for past dates', 'error');
        const date = moment(this.selectedDate).format('M/D/YYYY');
        confirm(`Are you sure you want to drop all employee schedules for ${date}?`, 'Confirm selection').then((dialogResult) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (dialogResult) {
                this.ui.showLoading();
                const d = asShortDate(this.selectedDate);
                yield this.dss.getApi(DriverSchedule).dropAll(d).toPromise();
                this.ui.hideLoading();
                this.showToasts(`Successfully Dropped All Schedules for ${date}`);
                this.buildCalendarMap();
                this.grid.instance.refresh();
            }
        }));
    }
    cloneSchedules_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const actionLabels = {
                prev: `Clone latest ${asWeekday(this.selectedDate)}`,
                date: 'Clone from the other date',
                cancel: 'Cancel',
            };
            const actions = [actionLabels.prev, actionLabels.date, actionLabels.cancel];
            const doActionAsync = (action) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.popupClone.instance.hide();
                const clone = (from, to) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    this.ui.showLoading();
                    yield this.dss.getApi(DriverSchedule).clone(asShortDate(from), asShortDate(to)).toPromise();
                    this.ui.hideLoading();
                    this.showToasts(`Successfully Cloned Schedules for ${asWeekday(to)}`);
                    this.buildCalendarMap();
                    this.grid.instance.refresh();
                });
                const getDate = (act, acls) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    switch (act) {
                        case acls.prev:
                            return moment(this.selectedDate).subtract(7, 'day').toDate();
                        case acls.date: {
                            const data = { calendarMap: this.calendarMap, length: this.rows.length };
                            return yield this.dialog.open(DlgCalendarComponent, { hasBackdrop: true, data }).afterClosed().toPromise();
                        }
                    }
                });
                const date = yield getDate(action, actionLabels);
                if (date)
                    yield clone(date, this.selectedDate);
            });
            this.popupClone.instance.beginUpdate();
            this.popupClone.container = this.grid.instance.element();
            this.popupClone.position = { my: 'center', at: 'center', of: `center` || null };
            this.popupClone.toolbarItems = actions.map(action => ({
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'center',
                options: { type: 'default', text: action, onClick: () => doActionAsync(action) },
            }));
            this.popupClone.instance.show();
            this.popupClone.instance.endUpdate();
        });
    }
    grid_toolbar_recalculateStartTime_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isGood = d => get(d, `schedule.mode`) === 'AUTO' && this.helper.isGoodToRecalculateStartTime(d);
            const selectedRows = this.rows.filter(isGood);
            this.progressTotal = selectedRows.length;
            for (const [i, d] of selectedRows.entries()) {
                d.schedule = yield this.helper.updateStartTime(d, this.selectedDate, this.startPrepareDuration);
                this.progressCount = i + 1;
                this.progress = (this.progressCount / this.progressTotal) * 100;
            }
            if (this.progressTotal) {
                this.showToasts(`Successfully Re-calculated Start Time for ${this.progressTotal} employees`);
            }
            else
                this.showToasts(`Start Time is already re-calculated for all employees`, 'info');
            this.progress = 0;
        });
    }
    manifest_onClick(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cIds = data.manifest.trips.reduce((p, { c }) => (Object.assign({}, p, { [c]: 1 })), {});
            const consumers = yield this.dss
                .getApi(Consumer)
                .find({ where: { id: { inq: Object.keys(cIds) } }, include: [{ person: { contact: ['addresses', 'phones'] } }] }, headersAllTenantsAppend)
                .toPromise();
            const consumersMap = consumers.reduce((p, c) => (Object.assign({}, p, { [c.id]: c })), {});
            const trips = [...data.manifest.trips.sort(({ t: t1 }, { t: t2 }) => (t1 < t2 ? -1 : t1 > t2 ? 1 : 0))];
            const manifest = Object.assign({}, data.manifest, { trips });
            const schedule = data.schedule;
            const d = moment(this.selectedDate).format('M/D/YYYY');
            const n = this.employeeHelper.displayExpr(data);
            let [start, finish] = ['', ''];
            if (schedule) {
                if (schedule.startTime)
                    start = `, Work Start Time: ${moment(schedule.startTime, 'HH:mm:ss').format('LT')}`;
                if (schedule.finishTime)
                    finish = `, Work Finish Time: ${moment(schedule.finishTime, 'HH:mm:ss').format('LT')}`;
            }
            const facilityName = data.facility && data.facility.name;
            const title = `${facilityName} Manifest for ${d} - ${n}${start}${finish}`;
            void this.dialog.open(DlgManifestGridComponent, {
                hasBackdrop: true,
                data: { title, manifest, consumersMap },
                minWidth: 1200,
            });
        });
    }
    grid_onRowRemoving(e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            e.cancel = true;
            if (!e.data.schedule)
                return;
            yield this.dss.getApi(DriverSchedule).deleteById(e.data.schedule.id).toPromise();
            e.data.schedule = null;
        });
    }
    grid_onCellPrepared(e) {
        if (e.rowType === 'data' && e.column.command === 'select') {
            if (!e.data.schedule || !e.data.schedule.startTime) {
                e.cellElement.innerHTML = '-';
                e.cellElement.title = 'No Schedule for this Employee';
            }
        }
    }
    grid_onSelectionChanged(e) {
        this.selectedRows = e.selectedRowsData.filter(r => r.schedule && r.schedule.startTime);
        this.mySelected.emit(e.selectedRowsData);
    }
    calendar_onValueChanged(e) {
        this.grid.instance.refresh();
    }
    buildDso() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const _a = (yield gqlMongoLoad(this.dss, 'AutoSchedulingSettings', {}, []).pipe().toPromise())[0] || {}, { _id: id } = _a, aSch = tslib_1.__rest(_a, ["_id"]);
            this.startPrepareDuration = aSch.startPrepareDuration || this.startPrepareDuration;
            const so = this.dss.getStoreOptions(Employee, Employee, false);
            so.customFilter = {
                where: { status: 'Active', employeePositionId: { inq: [39, 40, 273] } },
                include: [{ person: { contact: ['phones'] } }],
            };
            const store = new CustomStore(so);
            dxStoreLoadHooks(store, undefined, (args, [empls]) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                empls = empls.sort((e1, e2) => {
                    const n1 = this.employeeHelper.displayExpr(e1);
                    const n2 = this.employeeHelper.displayExpr(e2);
                    return n1 < n2 ? -1 : n1 > n2 ? 1 : 0;
                });
                const date = moment(this.selectedDate).format('YYYY-MM-DD');
                const [driverSchedules, dayService, checkins, signatures, vehicleGeotabs] = yield Promise.all([
                    this.dss
                        .getApi(DriverSchedule)
                        .getDriverSchedulesWithDetails(date, headersAllTenantsAppend),
                    ...(this.isBackUpEmployeeOnly
                        ? [
                            this.dss
                                .getApi(EmployeeDayService)
                                .find({ where: { employeeId: { inq: empls.map(e => e.id) }, date, marker: 'PUNCH-IN:1' } }, headersAllTenantsAppend),
                            this.dss.getApi(VehicleCheckUp).find({
                                where: { employeeId: { inq: empls.map(e => e.id) }, vdate: +date.replace(/\-/g, '') },
                                include: ['vehicle'],
                            }, headersAllTenantsAppend),
                            this.dss
                                .getApi(Signature)
                                .find({ where: { employeeId: { inq: empls.map(e => e.id) }, vdate: date } }, headersAllTenantsAppend),
                            this.getLastGeoTabAggregate(),
                        ]
                        : [[], [], [], []]),
                ].map(p => (Array.isArray(p) ? p : p.toPromise())));
                const { schedules, facilities, manifestsMap, consumers, vehiclesMap } = driverSchedules;
                const schedulesMap = schedules.reduce((p, v) => (Object.assign({}, p, { [v.driverId]: v })), {});
                if (this.isBackUpEmployeeOnly) {
                    this.notBackUpEmployees = empls
                        .filter(e => !schedulesMap[e.id] || ![BACKUP_DRIVER, FLEET_MANAGER].includes(schedulesMap[e.id].status))
                        .map(e => (Object.assign({}, e, { schedule: schedulesMap[e.id] })));
                    empls = empls.filter(e => schedulesMap[e.id] && [BACKUP_DRIVER, FLEET_MANAGER].includes(schedulesMap[e.id].status));
                }
                this.facilities = facilities;
                this.facilityMap = facilities.reduce((p, v) => (Object.assign({}, p, { [v.id]: v })), {});
                const emplsMap = empls.reduce((p, e) => (Object.assign({}, p, { [e.id]: e })), {});
                const consumersMap = consumers.reduce((p, v) => (Object.assign({}, p, { [v.id]: v })), {});
                const dayServiceMap = dayService.reduce((p, v) => (Object.assign({}, p, { [v.employeeId]: v })), {});
                const checkinsMap = checkins.reduce((p, v) => (Object.assign({}, p, { [v.employeeId]: v })), {});
                const signaturesMap = signatures.reduce((p, v) => (Object.assign({}, p, { [v.employeeId]: (p[v.employeeId] || 0) + 1 })), {});
                const vehicleGeotabsMap = vehicleGeotabs.reduce((p, v) => (Object.assign({}, p, { [v.internalId]: v })), {});
                const res = empls.map(e => {
                    const schedule = schedulesMap[e.id];
                    if (schedule && !schedule.mode)
                        schedule.mode = 'AUTO';
                    const scheduleMode = (schedule && schedule.mode) || 'NO_SCHEDULE';
                    const dayService = dayServiceMap[e.id];
                    const checkin = checkinsMap[e.id];
                    const vehicleGeotab = checkin ? vehicleGeotabsMap[checkin.vehicle.internalId] : null;
                    const signatureCount = signaturesMap[e.id] || 0;
                    const manifest = manifestsMap[e.id];
                    const facilityId = get(manifest, 'firstTrip.facilityId') || (schedule && schedule.startFacilityId);
                    const facility = facilityId && this.facilityMap[facilityId];
                    const firstTripConsumer = get(manifest, 'firstTrip.c') && consumersMap[manifest.firstTrip.c];
                    const lastTripConsumer = get(manifest, 'lastTrip.c') && consumersMap[manifest.lastTrip.c];
                    const internalId = get(manifest, 'firstTrip.v') && vehiclesMap[manifest.firstTrip.v];
                    const manifestExists = !!manifest;
                    const emplPhone = this.getPhoneCellValue(e);
                    const firstTripLocaction = this.getFirstTripLocation({ firstTripConsumer });
                    const lastTripLocaction = this.getLastTripLocation({ lastTripConsumer });
                    const partnerId = (get(manifest, 'trips') || []).flatMap(t => [t.e, t.esc]).find(pId => pId !== e.id);
                    const partner = emplsMap[partnerId];
                    return Object.assign({}, Object.assign({}, e, { schedule, scheduleMode, dayService, checkin, vehicleGeotab, signatureCount }), { manifest, facilityId, facility, firstTripConsumer, lastTripConsumer, manifestExists }, { emplPhone, firstTripLocaction, lastTripLocaction, internalId, partner });
                });
                this.rows = res;
                return [res];
            }));
            this.dso = { store };
        });
    }
    buildCalendarMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const start = moment().subtract(2, 'month').startOf('month').startOf('day').toISOString(true);
            const end = moment().add(1, 'month').endOf('month').endOf('day').toISOString(true);
            const $and = [
                { $gte: ['$date', { $dateFromString: { dateString: start } }] },
                { $lte: ['$date', { $dateFromString: { dateString: end } }] },
            ];
            const stages = [
                { $match: { $expr: { $and }, status: 'DRIVER' } },
                { $group: { _id: { $dateToString: { format: '%Y-%m-%d', date: '$date' } }, count: { $sum: 1 } } },
            ];
            const cnts = yield gqlMongoLoad(this.dss, 'DriverSchedule', {}, stages).pipe().toPromise();
            this.calendarMap = cnts.reduce((p, v) => (Object.assign({}, p, { [v._id]: v.count })), {});
        });
    }
    getLastGeoTabAggregate() {
        const month = +moment(this.selectedDate).format('YYYYMM');
        const stages = [{ $match: { month } }, { $project: { internalId: 1, lastPosition: 1 } }];
        return gqlMongoLoad(this.dss, 'VehicleGeotab', {}, stages).pipe();
    }
}

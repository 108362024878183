/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consumer-notes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/devextreme-angular/ui/button/devextreme-angular-ui-button.ngfactory";
import * as i3 from "devextreme-angular/core";
import * as i4 from "devextreme-angular/ui/button";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "./consumer-notes.component";
import * as i8 from "@angular/material/dialog";
var styles_ConsumerNotesComponent = [i0.styles];
var RenderType_ConsumerNotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsumerNotesComponent, data: {} });
export { RenderType_ConsumerNotesComponent as RenderType_ConsumerNotesComponent };
function View_ConsumerNotesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "dx-button", [["hint", "Expand Grid"], ["icon", "fas fa-expand-arrows-alt"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.expand_onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DxButtonComponent_0, i2.RenderType_DxButtonComponent)), i1.ɵprd(512, null, i3.DxTemplateHost, i3.DxTemplateHost, []), i1.ɵprd(512, null, i3.WatcherHelper, i3.WatcherHelper, []), i1.ɵprd(512, null, i3.NestedOptionHost, i3.NestedOptionHost, []), i1.ɵdid(5, 7323648, null, 0, i4.DxButtonComponent, [i1.ElementRef, i1.NgZone, i3.DxTemplateHost, i3.WatcherHelper, i3.NestedOptionHost, i5.TransferState, i1.PLATFORM_ID], { hint: [0, "hint"], icon: [1, "icon"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"]))], function (_ck, _v) { var currVal_0 = "Expand Grid"; var currVal_1 = "fas fa-expand-arrows-alt"; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_ConsumerNotesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵted(7, null, [" : ", " : ", " ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "td", [["valign", "top"], ["width", "1%"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumerNotesComponent_2)), i1.ɵdid(10, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_5 = _v.context.first; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.text; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.dateTime, "yyyy/MM/dd HH:mm")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_v.context.$implicit.author || "Unknown"); var currVal_3 = _v.context.$implicit.text; var currVal_4 = _co.info(_v.context.$implicit); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_ConsumerNotesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "table", [], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { wrapped: 0, unwrapped: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsumerNotesComponent_1)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.wrap, !_co.wrap); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.model == null) ? null : _co.model.relatedNotes); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ConsumerNotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consumer-notes", [], null, null, null, View_ConsumerNotesComponent_0, RenderType_ConsumerNotesComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConsumerNotesComponent, [i8.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsumerNotesComponentNgFactory = i1.ɵccf("app-consumer-notes", i7.ConsumerNotesComponent, View_ConsumerNotesComponent_Host_0, { model: "model", wrap: "wrap" }, {}, []);
export { ConsumerNotesComponentNgFactory as ConsumerNotesComponentNgFactory };

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { FacilityAddress, LoggerService } from '../../../../shared/sdk';
import { HelperService as EmployeeHelperService } from '../../../employee/services/helper.service';
import { DataSourceOptions } from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-consumer-settings',
  templateUrl: './consumer-settings.component.html',
  styleUrls: ['./consumer-settings.component.scss'],
  providers: [EmployeeHelperService],
})
export class ConsumerSettingsComponent extends ABaseComponent implements OnInit {
  processing = false;
  processingSch = false;
  form: FormGroup;
  formSchedulingSettings: FormGroup;

  gmailPushAuthUrl = '';
  enabledComponents = { autoDispatch: false, mtmTrips: false, router: false };
  manifestSettings = {
    showSubscriptionTrips: false,
    highlightSubscriptionTrips: false,
    showDropOffTime: false,
    saveMtmTripsToManifest: false,
  };
  assignedEmail = '';
  refresh_token = '';

  destinationsDso: DataSourceOptions;

  constructor(
    protected logger: LoggerService,
    protected common: CommonService,
    protected fb: FormBuilder,
    protected dss: DataSourceService,
    public config: ConfigService,
    public employeeHelper: EmployeeHelperService,
  ) {
    super(logger);
    this.destinationsDso = this.buildDestinationsDso();
  }
  ngOnInit() {}

  buildDestinationsDso() {
    const so = this.dss.getStoreOptions(FacilityAddress, null, false);
    so.noSql = true;
    so.customFilter = { order: 'name' };
    const store = new CustomStore(so);
    return { store } as DataSourceOptions;
  }
}

<app-content-w-header header="Generate Report">
  <mat-card class="toolbar subheader">
    <div class="dx-fieldset">
      <dx-toolbar>
        <dxi-item [location]="'before'" locateInMenu="auto">
          <div style="display: flex; align-items: center; min-width: 200px">
            <dx-date-box #from [(value)]="selectedFromValue"></dx-date-box>
            <span>&nbsp;&ndash;&nbsp;</span>
            <dx-date-box #to [(min)]="selectedFromValue" [(value)]="selectedToValue"></dx-date-box>
          </div>
        </dxi-item>
      </dx-toolbar>
    </div>
  </mat-card>

  <dx-scroll-view class="content">
    <ng-container>
      <br /><br />
      <div class="grid">
        <mat-card>
          <div class="dx-fieldset">
            <ng-container>
              <div class="dx-fieldset-header">Reports</div>
            </ng-container>

            <ng-container>
              <div>
                <ng-container>
                  <br />

                  <div id="consumersByDestination" style="display: inline-block">
                    <dx-button
                      [disabled]="isConsumersByDestinationButtonDisabled"
                      style="text-align: left"
                      type="default"
                      width="280px"
                      text="Consumers By Destination"
                      (onClick)="genPeriodReport()"></dx-button>
                  </div>

                  <dx-popover
                    target="#consumersByDestination"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                    minWidth="400px"
                    maxWidth="600px"
                    position="right">
                    <div>This report will generate a report for the selected period.</div>
                  </dx-popover>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </mat-card>
      </div>
    </ng-container>
  </dx-scroll-view>
  <app-reports-form></app-reports-form>
</app-content-w-header>


import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { head, isEmpty, isNil, round, uniq, uniqBy } from 'lodash-es';
import fromPairs from 'lodash-es/fromPairs';
import last from 'lodash-es/last';
import sortBy from 'lodash-es/sortBy';
import sum from 'lodash-es/sum';
import moment, { utc } from 'moment';
import { combineLatest, iif, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { getDistinct$, gqlMongoLoad } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { hAll } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { DlgShowJsonComponent } from '../../../../shared/modules/ui/components/dlg-show-json/dlg-show-json.component';
import { FilterJson } from '../../../../shared/modules/ui/pipes/filter-json.pipe';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { ActiveClientSignatureView, AuthServiceApi, Consumer, Facility, LoggerService, } from '../../../../shared/sdk';
import { DlgEditClaimComponent } from '../../dialogs/dlg-edit-claim/dlg-edit-claim.component';
import { AuthClaimService, } from '../../services/auth-claim.service';
export class AuthClaimComponent extends ABaseComponent {
    constructor(logger, config, common, ui, dss, api, dialog, gridHelper, pusher, service) {
        super(logger);
        this.logger = logger;
        this.config = config;
        this.common = common;
        this.ui = ui;
        this.dss = dss;
        this.api = api;
        this.dialog = dialog;
        this.gridHelper = gridHelper;
        this.pusher = pusher;
        this.service = service;
        this.claimStatusDs = this.service.getClaimStatuses();
        this.procedureCodes = this.service.getProcedureCodes();
        this.e9yCodeDs$ = getDistinct$(this.dss, 'ChcEligibilityDataCache', 'response.tradingPartnerServiceId');
        this.e9yNameDs$ = getDistinct$(this.dss, 'ChcEligibilityDataCache', 'response.payer.name');
        this.dataSources = {
            e9ies: [],
            authsPrev: [],
            authsNext: [],
            auths: [],
            clSet: [],
            signs: [],
            nnClaims: [],
            chcStatuses: [],
        };
        this.facilityDso = [];
        this.memberDso = [];
        this.e9yDso = {};
        this.clSetDso = {};
        this.selectedDate = moment().subtract(1, 'month').toDate();
        // maxDate: Date = moment().subtract(1, 'month').endOf('month').toDate();
        this.selectedCodes = [];
        this.withSignsOnly = false;
        this.auths_selectedRowKeys = [];
        this.min = this.moments.som.toDate();
        this.max = this.moments.eom.toDate();
        this.appointments = [];
        this.selectedPeriod = [];
        this.getE9ies$ = () => {
            const collection = 'ChcEligibilityDataCache';
            const { yyyymm } = this.moments;
            const aggregate = [
                {
                    $match: {
                        'request.mci': oc(this.selectedMember).last._mci(),
                        // 'request.memberID': oc(this.selectedMember).last.MemberID(),
                        'request.yyyymm': yyyymm,
                        active: true,
                    },
                },
            ];
            return gqlMongoLoad(this.dss, collection, { select: { 'response.x12': 0 } }, aggregate);
        };
        this.getMemberAuths$ = (yyyymm) => {
            yyyymm = yyyymm || this.moments.yyyymm;
            const som = utc(yyyymm + '01', 'YYYYMMDD').startOf('month');
            const eom = som.clone().endOf('month');
            const lastAuth = oc(this.selectedMember).last();
            const collection = 'ExportsAuthsDataCache';
            const pipeline = [
                {
                    $match: {
                        _inactive: { $ne: true },
                        _mci: oc(lastAuth)._mci(),
                        Code: {
                            $in: !isEmpty(this.selectedCodes) ? this.selectedCodes : this.service.getProcedureCodes(),
                        },
                        StartDT: { $lte: { $date: { v: utc(eom).toISOString() } } },
                        EndDT: { $gte: { $date: { v: utc(som).toISOString() } } },
                    },
                },
                { $project: { _dates: 0, _source: 0 } },
                {
                    $sort: {
                        StartDT: 1,
                        _ctime: 1,
                    },
                },
            ];
            return gqlMongoLoad(this.dss, collection, {}, pipeline).pipe(switchMap(auths => this.api.filterAuths(auths)), map((auths) => {
                auths.forEach(auth => {
                    auth.__claims = oc(this.selectedMember.claims)([]).filter(cl => cl.auth._id === auth._id);
                });
                return auths;
            }));
        };
        this.getClaimsStatuses$ = () => {
            const collection = 'ChcClaimsStatusDataCache';
            const activeAuthMoment = utc(moment(this.selectedDate).format('YYYY-MM-DD'));
            const aggregate = [
                {
                    $match: {
                        _tenantId: oc(this.selectedMember).last._tenantId(),
                        _mci: oc(this.selectedMember).last._mci(),
                        _fromServiceDate: { $date: { v: activeAuthMoment.startOf('month').toDate().toISOString() } },
                    },
                },
            ];
            return gqlMongoLoad(this.dss, collection, {}, aggregate);
        };
        this.getNavinetStatuses$ = () => {
            const collection = 'Navinet_KFCHC_ClaimStatusImports';
            const { som, eom } = this.moments;
            const aggregate = [
                {
                    $match: {
                        _mci: oc(this.selectedMember).last._mci(),
                        SVCStartDT: { $lte: { $date: { v: eom.clone().toISOString() } } },
                        SVCEndDT: { $gte: { $date: { v: som.clone().toISOString() } } },
                    },
                },
            ];
            return gqlMongoLoad(this.dss, collection, {}, aggregate);
        };
        this.getClaimSet$ = () => {
            const collection = 'MealClaims';
            const { yyyymm, som, eom } = this.moments;
            const aggregate = [
                {
                    $match: {
                        'meta.mci': oc(this.selectedMember).last._mci(),
                        'meta.yyyymm': yyyymm,
                        'meta.code': { $in: !isEmpty(this.selectedCodes) ? this.selectedCodes : this.service.getProcedureCodes() },
                    },
                },
            ];
            return gqlMongoLoad(this.dss, collection, {}, aggregate);
        };
        this.getMemberSigns$ = () => {
            return this.dss
                .getApi(Consumer)
                .find({
                where: {
                    // tenantId: oc(this.selectedMember).last._tenantId(),
                    mci: oc(this.selectedMember).last._mci(),
                },
                fields: { id: true, mci: true },
            }, hAll)
                .pipe(switchMap((clients) => clients.length
                ? this.dss.getApi(ActiveClientSignatureView).find({
                    where: {
                        and: [
                            { vServiceType: 'MEALS' },
                            { vdate: { gte: this.moments.somw.format('YYYY-MM-DD') } },
                            { vdate: { lte: this.moments.eomw.format('YYYY-MM-DD') } },
                            // { tenantId: this.selectedAuth._tenantId },
                            { consumerId: { inq: clients.map(c => c.id) } },
                        ],
                    },
                }, hAll)
                : of([])));
        };
        this.openClaimDialog$ = (useCells) => this.dialog
            .open(DlgEditClaimComponent, {
            hasBackdrop: true,
            width: '750px',
            data: {
                date: this.selectedDate,
                member: this.selectedMember,
                authId: this.auths_selectedRowKeys[0],
                cells: useCells ? this.scheduler.selectedCellData : [],
            },
        })
            .afterClosed()
            .pipe(switchMap(claimId => iif(() => !!claimId, this.api
            .updateAuthMemberDs(this.moments.yyyymm, this.selectedCodes, claimId, this.dsCollection)
            .pipe(tap(() => this.memberGrid.instance.refresh())), of(null))), takeUntil(this.$onDestroy$));
        this.e9y_calculateFilterExpression = function (filterValue, selectedFilterOperation, target) {
            const column = this;
            // console.log(column.dataField, filterValue, selectedFilterOperation, target);
            if (isNil(filterValue)) {
                filterValue = [];
            }
            // if (selectedFilterOperation === '=') {
            //     const filterExpression = [['__e9y', 'contains', filterValue]];
            //     return filterExpression;
            // }
            return column.defaultCalculateFilterExpression(filterValue, selectedFilterOperation, target);
        };
        this.claims_calculateFilterExpression = function (filterValue, selectedFilterOperation, target) {
            const column = this;
            // console.log(column.dataField, filterValue, selectedFilterOperation, target);
            if (isNil(filterValue)) {
                filterValue = [];
            }
            // if (selectedFilterOperation === '=') {
            //     const filterExpression = [
            //       ['__claims_codes', 'contains', filterValue],
            //       'or',
            //       ['__claims_statuses', 'contains', filterValue],
            //       'or',
            //       ['__claims_authUnits', 'contains', filterValue],
            //     ];
            //     return filterExpression;
            // }
            return column.defaultCalculateFilterExpression(filterValue, selectedFilterOperation, target);
        };
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: 'e279feb0-ca02-40fc-b2e1-440051510cb5',
        };
        this.authGrid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '7c013900-9cb0-4425-b6a7-c0e456fc3240',
        };
        this.nnClaims_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '08cc0151-98f5-4c01-8382-76f2a65c702f',
        };
        this.resourceDs = this.service.getResourceDataSet();
        this.counters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
        this.subCounters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
        this.facilityDso = this.buildFacilityDataSource();
    }
    get moments() {
        const m = moment(this.selectedDate);
        return {
            yyyymm: Number(m.clone().format('YYYYMM')),
            som: m.clone().startOf('month'),
            eom: m.clone().endOf('month'),
            somw: m.clone().startOf('month').startOf('week'),
            eomw: m.clone().endOf('month').endOf('week'),
        };
    }
    ngOnInit() {
        super.ngOnInit();
    }
    dateBox_onValueChanged(e) {
        this.selectedDate = e.value;
    }
    codeBox_onValueChanged(e) {
        this.selectedCodes = e.value;
    }
    filter_onClick(e) {
        this.memberGrid.instance.selectRows([], false);
        this.authGrid.instance.selectRows([], false);
        this.min = this.moments.som.toDate();
        this.max = this.moments.eom.toDate();
        void this.buildDataSource();
    }
    fillChcEligibility() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const date = this.moments.som.format('YYYY-MM-DD');
            this.ui.showLoading();
            yield this.config
                .hasAnyRole$(['SU', 'BILLER'])
                .pipe(switchMap(hasRole => iif(() => hasRole, this.pusher.rpc('FILL_CHC_ELIGIBILITIES', { date, useRunJob: true }, true, hAll), of(null))), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                .toPromise();
        });
    }
    processClaims() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.selectedCodes.length !== 1)
                return notify('Please select single Procedure Code', 'error', 5000);
            this.ui.showLoading();
            yield this.config
                .hasAnyRole$(['SU', 'BILLER'])
                .pipe(switchMap(hasRole => iif(() => hasRole, this.pusher.rpc('PROCESS_MEAL_CLAIMS', {
                yyyymm: this.moments.yyyymm,
                code: this.selectedCodes[0],
                useRunService: true,
            }, true, hAll), of(null))), catchError(err => of(notify(err.message, 'error', 5000))), tap(() => this.ui.hideLoading()))
                .toPromise();
        });
    }
    grid_onInitialized(e) {
        this.gridHelper.handle(e.component, {
            flatToTreeObject: false,
            copyIdsOnSaving: false,
            selectRowOnEdit: false,
            notifyErrors: true,
            onGridRefresh: () => {
                void this.buildDataSource();
            },
        });
        // void this.buildDataSource();
    }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            name: 'selectDate',
            locateInMenu: 'auto',
            widget: 'dxDateBox',
            location: 'before',
            sortIndex: 0,
            showText: 'inMenu',
            options: {
                firstDayOfWeek: 1,
                calendarOptions: {
                    maxZoomLevel: 'year',
                    minZoomLevel: 'decade',
                },
                value: this.selectedDate,
                displayFormat: 'MMMM yyyy',
                pickerType: 'calendar',
                type: 'date',
                useMaskBehavior: true,
                onValueChanged: this.dateBox_onValueChanged.bind(this),
            },
        }, {
            name: 'selectCode',
            locateInMenu: 'auto',
            widget: 'dxTagBox',
            location: 'before',
            sortIndex: 0,
            showText: 'inMenu',
            options: {
                value: this.selectedCodes,
                multiline: false,
                wrapItemText: true,
                dataSource: this.service.getProcedureCodes(),
                onValueChanged: this.codeBox_onValueChanged.bind(this),
            },
        }, 
        // {
        //   name: 'withSigns',
        //   locateInMenu: 'auto',
        //   widget: 'dxSwitch',
        //   location: 'before',
        //   sortIndex: 0,
        //   showText: 'always',
        //   options: {
        //     width: '60px',
        //     switchedOnText: 'Has Signs',
        //     switchedOffText: 'All',
        //     value: this.withSignsOnly,
        //     onValueChanged: $e => (this.withSignsOnly = $e.value),
        //   },
        // },
        {
            name: 'filter',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'before',
            sortIndex: 0,
            showText: 'always',
            options: {
                icon: 'fas fa-filter',
                text: 'Filter',
                hint: 'Filter',
                onClick: this.filter_onClick.bind(this),
            },
        }, {
            name: 'fillChcE9y',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'after',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-fill',
                text: 'Fill E9y',
                hint: 'Fill All Members Eligibility from CHC for the selected month',
                onClick: () => this.fillChcEligibility(),
            },
        }, {
            name: 'processClaims',
            locateInMenu: 'auto',
            widget: 'dxButton',
            location: 'after',
            sortIndex: 30,
            showText: 'always',
            options: {
                icon: 'fas fa-claim',
                text: 'Process Claims',
                hint: 'Process Meal Claims for the selected month',
                onClick: () => this.processClaims(),
            },
        });
    }
    grid_onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.__claims_statuses.length > 0) {
                if (e.data.__claims_statuses.every(s => ['BILLED', 'PENDED', 'ACCEPTED', 'PAID', 'REJECTED'].includes(s)))
                    e.cellElement.classList.add('meal-cell-violet');
                if (e.data.__claims_statuses.every(s => ['PENDED', 'ACCEPTED', 'PAID', 'REJECTED'].includes(s)))
                    e.cellElement.classList.add('meal-cell-blue');
                if (e.data.__claims_statuses.every(s => s === 'PAID'))
                    e.cellElement.classList.add('meal-cell-green');
                if (e.data.__claims_statuses.some(s => ['FAILED'].includes(s)))
                    e.cellElement.classList.add('meal-cell-brown');
                else if (e.data.__claims_statuses.some(s => s.includes('PARTIAL')))
                    e.cellElement.classList.add('meal-cell-brown');
                else if (e.data.__claims_statuses.some(s => s === 'REJECTED'))
                    e.cellElement.classList.add('meal-cell-red');
            }
        }
    }
    grid_onSelectionChanged(e) {
        this.selectedMember = e.selectedRowsData[0];
        this.memberGrid.instance.beginCustomLoading('Loading...');
        void this.fillUpMemberData().finally(() => this.memberGrid.instance.endCustomLoading());
    }
    authGrid_onSelectionChanged(e) {
        this.auths_selectedRowKeys = e.selectedRowKeys;
        this.fillUpScheduler();
    }
    authGrid_onContextMenuPreparing(e) {
        if (e.row && e.row.rowType === 'data') {
            e.items = [
                {
                    text: 'Submit Claim',
                    onItemClick: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        yield e.component.selectRows([e.row.key], false);
                        void this.openClaimDialog$(false).toPromise();
                    }),
                },
            ];
        }
    }
    authGrid_onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.__claims.length > 0 && e.data.__claims.every(cl => cl.claim.status === 'BILLED'))
                e.cellElement.classList.add('meal-cell-blue');
            if (e.data._replaced)
                e.cellElement.classList.add('meal-cell-gray');
        }
    }
    nnClaims_onCellPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.ClaimStatus.includes('PENDED')) {
                e.cellElement.classList.add('meal-cell-violet');
            }
            else if (e.data.ClaimStatus.includes('ACCEPTED')) {
                e.cellElement.classList.add('meal-cell-blue');
            }
            else if (e.data.ClaimStatus.includes('FINALIZED')) {
                e.cellElement.classList.add('meal-cell-green');
            }
            else if (e.data.ClaimStatus.includes('ADJUSTED')) {
                e.cellElement.classList.add('meal-cell-green');
            }
            if (e.data.DENYAMT > 0) {
                e.cellElement.classList.add('meal-cell-brown');
            }
            if (!isEmpty(e.data.DISEXPCD)) {
                e.cellElement.classList.add('meal-cell-brown');
            }
        }
    }
    scheduler_mouseup(e) {
        if (e.button === 0) {
            this.subCounters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
            // console.log(this.scheduler.selectedCellData);
            const dates = this.scheduler.selectedCellData.map(c => moment(c.startDate).format('YYYY-MM-DD'));
            this.selectedPeriod = [head(dates), last(dates)];
            this.subCounters = fromPairs(Object.keys(this.subCounters).map(k => [
                k,
                {
                    a: sum(this.appointments
                        .filter((a) => a.type === 'auth')
                        .filter((a) => a.code === k)
                        .filter(a => dates.includes(moment(a.startDate).format('YYYY-MM-DD')))
                        .map((a) => a.count)),
                    s: sum(this.appointments
                        .filter((a) => a.type === 'sign')
                        .filter((a) => a.code === k)
                        .filter(a => dates.includes(moment(a.startDate).format('YYYY-MM-DD')))
                        .map((a) => a.count)),
                    c1: sum(this.appointments
                        .filter((a) => a.type === 'claim')
                        .filter((a) => a.code === k)
                        .filter(a => dates.includes(moment(a.startDate).format('YYYY-MM-DD')))
                        .map((a) => round(Number(oc(a).data.service.submittedUnits(0))))),
                    c2: sum(this.appointments
                        .filter((a) => a.type === 'claim')
                        .filter((a) => a.code === k)
                        .filter(a => dates.includes(moment(a.startDate).format('YYYY-MM-DD')))
                        .map((a) => {
                        const srv = oc(a).data.service();
                        const rate = Number(oc(srv).submittedAmount(0)) / Number(oc(srv).submittedUnits(0));
                        return round(Number(oc(srv).amountPaid(0)) / rate);
                    })),
                },
            ]));
        }
    }
    scheduler_onAppointmentDblClick(e) {
        // console.log(e);
        e.cancel = true;
        this.dialog.open(DlgShowJsonComponent, {
            hasBackdrop: true,
            data: {
                json: e.appointmentData.data,
                title: {
                    auth: 'Authorization',
                    sign: 'Signature',
                    claim: 'Claim Detail',
                }[e.appointmentData.type],
            },
        });
    }
    scheduler_onCellContextMenu(e) {
        // e.cancel = true;
        const scheduler = e.component;
        this.scheduler_ctxMenuDisabled = false;
        this.scheduler_ctxMenuItems = [
            {
                text: 'Submit Claim',
                onItemClick: $e => {
                    // console.log($e, this.schedule_selectedCellData, this.auths_selectedRowKeys);
                    if (this.auths_selectedRowKeys.length !== 1) {
                        return notify('Please select one Authorization', 'error', 5000);
                    }
                    void this.openClaimDialog$(true).toPromise();
                },
            },
        ];
        // console.log(e);
        // console.log(scheduler);
        // console.log(this.schedule_selectedCellData);
    }
    buildDataSource() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.memberGrid.instance.beginCustomLoading('Building Dataset...');
            yield this.pusher
                .rpc('GET_MEAL_AUTHS_MEMBERS', {
                yyyymm: this.moments.yyyymm,
                codes: !isEmpty(this.selectedCodes) ? this.selectedCodes : this.procedureCodes,
                withSignsOnly: this.withSignsOnly,
                useRunService: true,
            }, true, hAll)
                .pipe(switchMap(collection => {
                this.dsCollection = collection;
                const mongoSchema = {
                    'last.StartDT': 'datetime',
                    'last.EndDT': 'datetime',
                    'last.MemberDOB': 'datetime',
                    'last._ctime': 'datetime',
                    'last._rtime': 'datetime',
                };
                return of({
                    store: this.dss.createMongoStore(collection, mongoSchema),
                });
            }), catchError(err => {
                notify(err.message, 'error', 5000);
                return of([]);
            }), tap(ds => (this.memberDso = ds)), tap(() => this.memberGrid.instance.endCustomLoading()))
                .toPromise();
            // const collection = 'ExportsAuthsDataCache';
            // const pipeline = [
            //   {
            //     $match: {
            //       _inactive: { $ne: true },
            //
            //       Code: {
            //         $in: !isEmpty(this.selectedCode) ? this.selectedCode : this.service.getProcedureCodes(),
            //       },
            //
            //       ...(activeAuthMoment
            //         ? {
            //             StartDT: { $lte: { $date: { v: activeAuthMoment.clone().endOf('month').toDate().toISOString() } } },
            //             EndDT: { $gte: { $date: { v: activeAuthMoment.clone().startOf('month').toDate().toISOString() } } },
            //           }
            //         : {}),
            //     },
            //   },
            //   { $project: { _dates: 0, _source: 0 } },
            //   {
            //     $sort: {
            //       StartDT: -1,
            //       _ctime: -1,
            //     },
            //   },
            //   {
            //     $group: {
            //       _id: {
            //         _broker: '$_broker',
            //         _clientId: '$_clientId',
            //       },
            //       last: { $first: '$$CURRENT' },
            //       authCount: { $count: {} },
            //     },
            //   },
            //   {
            //     $lookup: {
            //       from: 'ChcEligibilityDataCache',
            //       let: { auth_memberID: '$last.MemberID' },
            //       pipeline: [
            //         {
            //           $match: {
            //             $expr: {
            //               $and: [
            //                 { $eq: ['$request.memberID', '$$auth_memberID'] },
            //                 {
            //                   $eq: ['$request.yyyymm', Number(activeAuthMoment.clone().startOf('month').format('YYYYMM'))],
            //                 },
            //               ],
            //             },
            //           },
            //         },
            //         { $project: { 'response.tradingPartnerServiceId': 1 } },
            //       ],
            //       as: 'e9y',
            //     },
            //   },
            //   {
            //     $lookup: {
            //       from: 'MealClaims',
            //       let: { auth_mci: '$last._mci' },
            //       pipeline: [
            //         {
            //           $match: {
            //             $expr: {
            //               $and: [
            //                 { $eq: ['$mci', '$$auth_mci'] },
            //                 {
            //                   $eq: ['$yyyymm', Number(activeAuthMoment.clone().startOf('month').format('YYYYMM'))],
            //                 },
            //               ],
            //             },
            //           },
            //         },
            //         { $project: { code: 1, _status: 1, authUnits: 1 } },
            //       ],
            //       as: 'claims',
            //     },
            //   },
            //   {
            //     $sort: {
            //       'last.StartDT': -1,
            //       'last._ctime': -1,
            //     },
            //   },
            // ];
            //
            // await gqlMongoLoad(this.dss, collection, {}, pipeline)
            //   .pipe(
            //     map((items: any[]) => {
            //       (items || []).forEach(itm => {
            //         itm.e9y = sortBy(
            //           uniqBy<any>((itm.e9y || []).reverse(), o => oc(o).request.yyyymmdd()),
            //           o => oc(o).request.yyyymmdd(),
            //         );
            //         itm.__e9y = (itm.e9y || []).map(o => oc(o).response.tradingPartnerServiceId());
            //         itm.__claims_codes = (itm.claims || []).map(o => o.code);
            //         itm.__claims_statuses = (itm.claims || []).map(o => o._status);
            //         itm.__claims_authUnits = (itm.claims || []).map(o => o.authUnits);
            //       });
            //       return items;
            //     }),
            //     tap(ds => this.$memberDso$.next(ds)),
            //     tap(() => this.memberGrid.instance.endCustomLoading()),
            //   )
            //   .toPromise();
        });
    }
    buildFacilityDataSource() {
        const so = this.dss.getStoreOptions(Facility, undefined, false);
        so.customFilter = {
            where: { type: { inq: ['MEALS'] } },
            order: ['typeOrder DESC', 'type', 'shortname'],
        };
        return {
            store: new CustomStore(so),
        };
    }
    fillUpMemberData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.authGrid.instance.selectRows([], false);
            this.auths_selectedRowKeys = [];
            this.e9yDso = {};
            this.clSetDso = {};
            this.authDso = [];
            this.nnClaimsDso = [];
            this.dataSources = {
                e9ies: [],
                authsPrev: [],
                authsNext: [],
                auths: [],
                clSet: [],
                signs: [],
                nnClaims: [],
                chcStatuses: [],
            };
            this.min = this.moments.som.toDate();
            this.max = this.moments.eom.toDate();
            this.appointments = [];
            this.counters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
            this.subCounters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
            if (this.selectedMember) {
                yield combineLatest([
                    this.getE9ies$(),
                    // this.getMemberAuths$(Number(this.moments.som.subtract(1, 'month').format('YYYYMM'))).toPromise(),
                    of([]),
                    // this.getMemberAuths$(Number(this.moments.som.add(1, 'month').format('YYYYMM'))).toPromise(),
                    of([]),
                    this.getMemberAuths$(),
                    this.getClaimSet$(),
                    this.getMemberSigns$(),
                    this.getNavinetStatuses$(),
                    this.getClaimsStatuses$(),
                ])
                    .pipe(tap(([e9ies, authsPrev, authsNext, auths, clSet, signs, nnClaims, chcStatuses]) => {
                    this.dataSources = { e9ies, authsPrev, authsNext, auths, clSet, signs, nnClaims, chcStatuses };
                    this.e9yDso = fromPairs(uniqBy(sortBy(oc(e9ies)([]), o => oc(o).request.yyyymmdd()), o => oc(o).request.payerID()).map((o) => [
                        [oc(o).response.tradingPartnerServiceId(), oc(o).response.payer.name()].join(':'),
                        o,
                    ]));
                    this.authDso = auths.filter(auth => utc(auth.StartDT).isSameOrBefore(utc(this.moments.eom.format('YYYY-MM-DD'))) &&
                        utc(auth.EndDT).isSameOrAfter(utc(this.moments.som.format('YYYY-MM-DD'))));
                    this.clSetDso = fromPairs(clSet.map((o) => [
                        [
                            isNaN(Number(oc(o).claim.userId())) ? 'a' : 'u',
                            oc(o).meta.code(),
                            oc(o).claim.request.tradingPartnerServiceId(),
                            oc(o).claim.status(),
                        ].join(':'),
                        o,
                    ]));
                    this.nnClaimsDso = nnClaims;
                    this.fillUpScheduler();
                }))
                    .toPromise();
            }
        });
    }
    fillUpScheduler() {
        this.min = this.moments.som.toDate();
        this.max = this.moments.eom.toDate();
        this.appointments = [];
        this.counters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
        this.subCounters = fromPairs(this.service.getProcedureCodes().map(code => [code, { a: 0, s: 0, c1: 0, c2: 0 }]));
        const { auths, signs, chcStatuses } = this.dataSources;
        const keys = this.authGrid.instance.getSelectedRowKeys();
        const isFiltered = keys.length > 0;
        const filteredAuths = auths
            .filter(auth => (isFiltered ? keys.includes(auth._id) : !auth._replaced))
            .filter(auth => !isEmpty(oc(auth)._manifest({})));
        const filteredCodes = uniq(filteredAuths.map(auth => auth.Code));
        const _map1 = {
            Su: 'Su',
            Mo: 'M',
            Tu: 'T',
            We: 'W',
            Th: 'Th',
            Fr: 'F',
            Sa: 'Sa',
        };
        const _map2 = {
            HOT: 'W1759',
            FROZEN: 'W1760',
            SANDWICH: 'W1761',
            EMERGENCY: 'W1762',
            SPECIAL: 'W1764',
        };
        const appointmentsAuth = [];
        uniqBy(filteredAuths, auth => [
            auth.AuthNumberFacets,
            auth.Code,
            utc(auth.StartDT).format('YYYY-MM-DD'),
            utc(auth.EndDT).format('YYYY-MM-DD'),
        ].join('-')).forEach(auth => {
            const rrule = this.service.getAuthRRule(auth);
            rrule.all().forEach(d => appointmentsAuth.push({
                data: auth,
                type: 'auth',
                code: auth.Code,
                count: oc(auth)._manifest({})[_map1[moment(d).format('dd')]] || 0,
                rescode: 'a:' + auth.Code,
                //
                visible: true,
                allDay: true,
                startDate: moment(d).format('YYYY-MM-DD'),
                endDate: moment(d).format('YYYY-MM-DD'),
                text: `A:${auth.Code}: ` + oc(auth)._manifest({})[_map1[moment(d).format('dd')]],
                description: Object.entries(new FilterJson().transform(auth))
                    .map(([k, v]) => `${k}: ${v}`)
                    .join('\n'),
            }));
        });
        const appointmentsSign = [];
        signs.forEach(s => {
            Object.entries(s.meta.mealDroppedCountList)
                .filter(([k, v]) => (isFiltered ? filteredCodes.includes(oc(_map2[k])(k)) : true))
                .filter(([k, v]) => !isNil(v) && v > 0)
                .forEach(([k, v]) => appointmentsSign.push({
                data: s,
                type: 'sign',
                code: oc(_map2[k])(k),
                count: oc(v)(0),
                rescode: 's' + (s.validationState === 'VALID' ? ':' : '?:') + oc(_map2[k])(k),
                //
                visible: true,
                allDay: true,
                startDate: s.vdate,
                endDate: s.vdate,
                text: `S${s.validationState === 'VALID' ? '' : '?'}:${oc(_map2[k])(k)}: ${v}`,
                description: [].join('\n'),
            }));
        });
        const appointmentsClStatus = [];
        chcStatuses.forEach(clDoc => {
            oc(clDoc).claims([]).forEach(cl => {
                const clDates = oc(cl).claimStatus.claimServiceDate('')
                    .split('-')
                    .filter(p => !isEmpty(p))
                    .map(p => moment(p, 'YYYYMMDD').format('YYYY-MM-DD'));
                oc(cl).serviceDetails([])
                    .filter(sd => (isFiltered ? filteredCodes.includes(oc(sd).service.procedureId()) : true))
                    .forEach(sd => {
                    const srv = oc(sd).service();
                    const rate = Number(oc(srv).submittedAmount(0)) / Number(oc(srv).submittedUnits(0));
                    const paidUnits = round(Number(oc(srv).amountPaid(0)) / rate);
                    appointmentsClStatus.push({
                        data: sd,
                        type: 'claim',
                        code: oc(srv).procedureId(),
                        count: Number(oc(srv).submittedUnits(0)),
                        rescode: 'c:' + oc(srv).procedureId(),
                        //
                        visible: true,
                        allDay: true,
                        startDate: head(clDates),
                        endDate: last(clDates),
                        text: `C:${oc(srv).procedureId()}: ${Number(oc(srv).submittedUnits(0))}/${paidUnits}`,
                        description: [].join('\n'),
                    });
                });
            });
        });
        this.appointments = [...appointmentsAuth, ...appointmentsSign, ...appointmentsClStatus];
        this.counters = fromPairs(Object.keys(this.counters).map(k => [
            k,
            {
                a: sum(this.appointments
                    .filter((a) => a.type === 'auth')
                    .filter((a) => a.code === k)
                    .filter(a => moment(a.startDate).format('YYYYMM') === '' + this.moments.yyyymm)
                    .map((a) => a.count)),
                s: sum(this.appointments
                    .filter((a) => a.type === 'sign')
                    .filter((a) => a.code === k)
                    .filter(a => moment(a.startDate).format('YYYYMM') === '' + this.moments.yyyymm)
                    .map((a) => a.count)),
                c1: sum(this.appointments
                    .filter((a) => a.type === 'claim')
                    .filter((a) => a.code === k)
                    .filter(a => moment(a.startDate).format('YYYYMM') === '' + this.moments.yyyymm)
                    .map((a) => round(Number(oc(a).data.service.submittedUnits(0))))),
                c2: sum(this.appointments
                    .filter((a) => a.type === 'claim')
                    .filter((a) => a.code === k)
                    .filter(a => moment(a.startDate).format('YYYYMM') === '' + this.moments.yyyymm)
                    .map((a) => {
                    const srv = oc(a).data.service();
                    const rate = Number(oc(srv).submittedAmount(0)) / Number(oc(srv).submittedUnits(0));
                    return round(Number(oc(srv).amountPaid(0)) / rate);
                })),
            },
        ]));
    }
    schedulerCtxMenu_OnItemClick(e) {
        e.itemData.onItemClick(e);
    }
}

import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import notify from 'devextreme/ui/notify';
import { MyUser, MyUserApi } from '../../../../shared/sdk';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { ActivatedRoute, Router } from '@angular/router';
export class SigninFormComponent {
    constructor(fb, userApi, route, router) {
        this.fb = fb;
        this.userApi = userApi;
        this.route = route;
        this.router = router;
        this.passHide = true;
        this.mode = 'SIGN_IN';
        this.resetPasswordToken = null;
        this.mySubmit = new EventEmitter();
        this._isSubmitting = false;
        this._error = null;
        this.buildForm();
    }
    set mfaSecret(value) {
        if (value)
            this.form.get('mfaSecret').reset(value);
    }
    get isSubmitting() {
        return this._isSubmitting;
    }
    set isSubmitting(value) {
        this._isSubmitting = value;
        if (value) {
            this.form.disable();
        }
        else {
            this.form.enable();
        }
    }
    get error() {
        return (this._error && this._error.message) || this._error;
    }
    set error(value) {
        this._error = value;
        this.error && notify(this.error, 'error', 5000);
    }
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params['reset-password']) {
                this.resetPasswordToken = params['reset-password'];
                this.mode = 'RESET_PASSWORD';
            }
        });
    }
    form_ngSubmit() {
        if (this.form.valid) {
            [this.form]
                .filter((form) => form.valid)
                .map((form) => new MyUser(form.value))
                .forEach((user) => this.mySubmit.emit(user));
        }
        return false;
    }
    reset_onClick() {
        this.form.reset();
    }
    getErrorMessage(form, field, name) {
        if (form.get(field).hasError('required'))
            return `${name} is required`;
        if (form.get(field).hasError('email'))
            return `Invalid ${name}`;
        return '';
    }
    getEmail() {
        const { email } = this.signedUser;
        const [start, end] = email.split('@');
        return `${start.slice(0, 3)}*@*****${end.slice(-7)}`;
    }
    showForgotPasswordForm() {
        this.mode = 'FORGOT_PASSWORD';
    }
    hideForgotPasswordForm() {
        this.mode = 'SIGN_IN';
        this.forgotPasswordForm.reset();
        this.error = null;
    }
    forgotPasswordSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.forgotPasswordForm.valid) {
                this.isSubmitting = true;
                console.log('Forgot Password:', this.forgotPasswordForm.value);
                try {
                    yield this.userApi.sendPasswordResetEmail(this.forgotPasswordForm.value.email).toPromise();
                    notify('Password reset email sent', 'success', 5000);
                    this.hideForgotPasswordForm();
                }
                catch (err) {
                    this.error = err;
                }
                this.isSubmitting = false;
            }
        });
    }
    hideResetPasswordForm() {
        this.resetPasswordForm.reset();
        this.mode = 'SIGN_IN';
        this.resetPasswordToken = null;
        this.error = null;
        this.router.navigate([], { queryParams: { 'reset-password': null }, queryParamsHandling: 'merge' });
    }
    resetPasswordSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.resetPasswordForm.valid) {
                this.isSubmitting = true;
                console.log('Reset Password:', this.resetPasswordForm.value);
                try {
                    const newPassword = this.resetPasswordForm.value.password;
                    yield this.userApi.resetPasswordByToken(this.resetPasswordToken, newPassword).toPromise();
                    notify('Password reset successfully', 'success', 5000);
                    this.hideResetPasswordForm();
                }
                catch (err) {
                    this.error = err;
                }
                this.isSubmitting = false;
            }
        });
    }
    // Custom validator to check if passwords match
    passwordsMatchValidator() {
        return (control) => {
            const password = control.get('password').value;
            const passwordConfirmation = control.get('passwordConfirmation').value;
            return password && passwordConfirmation && password !== passwordConfirmation ? { passwordsMismatch: true } : null;
        };
    }
    buildForm() {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            mfaSecret: [''],
            mfaToken: [''],
            mfaRememberForThisBrowser: [''],
            browserFingerprint: getBrowserFingerprint(),
        });
        this.forgotPasswordForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
        this.resetPasswordForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            passwordConfirmation: ['', [Validators.required, Validators.minLength(8)]],
        }, { validators: this.passwordsMatchValidator() });
    }
}


declare var Object: any;
export interface ContainerInterface {
  "id"?: string;
}

export class Container implements ContainerInterface {
  "id": string;
  constructor(data?: ContainerInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Container`.
   */
  public static getModelName() {
    return "Container";
  }

  /**
  * This method creates an instance of Container for dynamic purposes.
  **/
  public static factory(data: ContainerInterface): Container{
    return new Container(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Container',
      plural: 'Containers',
      path: 'Containers',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
      },
      relations: {
      }
    }
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import cloneDeep from 'lodash-es/cloneDeep';
import fromPairs from 'lodash-es/fromPairs';
import isEmpty from 'lodash-es/isEmpty';
import sum from 'lodash-es/sum';
import { utc } from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { hAll, isAlpha } from '../../../../shared/classes/utils/utils';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { AuthService, AuthServiceApi, ConsumerView, ConsumerViewApi } from '../../../../shared/sdk';
import { AuthClaimService } from '../../services/auth-claim.service';

@Component({
  selector: 'app-dlg-auth-edit',
  templateUrl: './dlg-auth-edit.component.html',
  styleUrls: ['./dlg-auth-edit.component.scss'],
  providers: [AuthClaimService],
})
export class DlgAuthEditComponent implements OnInit {
  @ViewChild('form', { static: false }) form: DxFormComponent;

  submitting = false;

  authFormData: any = {};

  authCodes = this.service
    .getProcedureCodes()
    .map(c => ({ _id: c }))
    .map(c => ({ ...c, t: this.service.codeDisplayExpr(c) }));

  frequencyDso = ['Daily', 'Weekly', 'Monthly', 'Yearly'];

  consumerDso: DataSourceOptions = [];

  isGridBoxOpened: boolean;
  noteDisabled: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DlgAuthEditComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: { auth: any },
    private dss: DataSourceService,
    public service: AuthClaimService,
  ) {
    this.consumerDso = this.buildConsumerDataSource();

    this.setFormData(data.auth);
  }

  ngOnInit() {}

  private buildConsumerDataSource() {
    const so = this.dss.getStoreOptions(ConsumerView, ConsumerView, false);
    so.customFilter = {
      where: { facility_type: { inq: ['MEALS', 'TC'] } },
      fields: { eligibility: false },
    };
    so.customHeaders = hAll;

    return {
      store: new CustomStore(so),
      sort: [{ selector: 'facility_shortname' }, { selector: 'person_firstname' }, { selector: 'person_lastname' }],
    } as DataSourceOptions;
  }

  gridBox_displayExpr(item: ConsumerView) {
    return (
      item &&
      [
        `[${item.facility_shortname}]`,
        item.person_firstname,
        item.person_lastname,
        '| DOB:' + utc(item.person_dob).format('L'),
        '| MCI:' + item.mci,
      ].join(' ')
    );
  }

  onGridBoxOptionChanged(e) {
    if (e.name === 'value') {
      this.isGridBoxOpened = false;
      this.ref.detectChanges();
    }
  }

  onFieldDataChanged(e: { dataField; value }) {
    if (e.dataField === 'Frequency') {
      if (e.value === 'Weekly') {
        this.noteDisabled = false;
      } else {
        this.noteDisabled = true;

        this.authFormData._manifest = {};
        this.authFormData.Note = '';
      }
    }
  }

  weekly_ngModelChange(e) {
    if (this.authFormData.Frequency === 'Weekly') {
      const _map = {
        su: 'Su',
        mo: 'M',
        tu: 'T',
        we: 'W',
        th: 'Th',
        fr: 'F',
        sa: 'Sa',
      };

      this.authFormData.Note =
        Object.entries(_map)
          .map(([k, v]) => `${v}-${e[k]}`)
          .join(' ') +
        ' Tot-' +
        sum(Object.values(e));
    } else {
      this.authFormData.Note = '';
    }
  }

  reset_click(e, form: DxFormComponent): void {
    this.setFormData(this.data.auth);
    // form.instance.resetValues();
  }

  cancel_click(e, form: DxFormComponent): void {
    this.dialogRef.close(false);
  }

  submit_click(e, form: DxFormComponent): void {
    const validateRes = form.instance.validate();

    if (validateRes.isValid) {
      this.submitting = true;
      void this.beforeSubmit()
        .pipe(
          switchMap(data => this.dss.getApi<AuthServiceApi>(AuthService).saveAuthorization(data, hAll)),
          tap(res => this.dialogRef.close(res)),
          catchError(err => of(notify(err.message, 'error', 5000))),
          tap(() => (this.submitting = false)),
        )
        .toPromise();
    }
  }

  private setFormData(data) {
    const auth = cloneDeep(oc(data)({}));

    if (auth._consumerId) auth._consumerIds = [auth._consumerId];
    if (auth.StartDT) auth.StartDT = utc(auth.StartDT).format('YYYY-MM-DD');
    if (auth.EndDT) auth.EndDT = utc(auth.EndDT).format('YYYY-MM-DD');

    if (isEmpty(auth.Frequency)) auth.Frequency = isAlpha() ? 'Monthly' : 'Weekly';

    this.noteDisabled = auth.Frequency !== 'Weekly';

    {
      const _map = {
        Su: 'su',
        M: 'mo',
        T: 'tu',
        W: 'we',
        Th: 'th',
        F: 'fr',
        Sa: 'sa',
      };

      if (auth.Note)
        auth._manifest = fromPairs(
          oc(auth.Note as string)('')
            .split(' ')
            .map(pair => pair.split('-') as [string, string])
            .map(([k, v]) => [_map[k] || k, Number(v)]),
        );
    }

    this.authFormData = auth;
  }

  private beforeSubmit(): Observable<any> {
    const [cId] = this.authFormData._consumerIds;

    return this.dss
      .getApi<ConsumerViewApi>(ConsumerView)
      .findById<ConsumerView>(cId, {}, hAll)
      .pipe(
        map(cons => {
          return {
            _id: this.authFormData._id,

            _broker: this.authFormData._broker,
            _clientId: this.authFormData.MemberID,
            _mci: cons.mci,
            _tenantId: cons.tenantId,
            _consumerId: cons.id,

            MedicaidID: cons.mci,
            FirstName: cons.person_firstname,
            LastName: cons.person_lastname,
            Name: [cons.person_firstname, cons.person_lastname].join(' '),
            MemberDOB: cons.person_dob,
            // Phone: '',

            StartDT: this.authFormData.StartDT,
            EndDT: this.authFormData.EndDT,

            MemberID: this.authFormData.MemberID,
            AuthNumberFacets: this.authFormData.AuthNumberFacets,
            Code: this.authFormData.Code,
            Frequency: this.authFormData.Frequency,
            AuthUnitsApproved: this.authFormData.AuthUnitsApproved,
            Note: this.authFormData.Note,

            ServiceCoordinatorName: this.authFormData.ServiceCoordinatorName,
            ServiceCoordinatorPhone: this.authFormData.ServiceCoordinatorPhone,
            ServiceCoordinatorEmail: this.authFormData.ServiceCoordinatorEmail,
          };
        }),
      );
  }
}

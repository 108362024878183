<dx-data-grid
  width="100%"
  height="100%"
  [dataSource]="ds"
  [columns]="gridColumns"

  [editing]="{ allowAdding: false, allowUpdating: false, allowDeleting: false }"
  [columnChooser]="{ enabled: false }"
  [searchPanel]="{ visible: false }"
  [hoverStateEnabled]="true"
  [pager]="{ visible: false }"
  [paging]="gridPaging"
  [scrolling]="gridScrolling"
  [showBorders]="true"
  [filterRow]="{ visible: false }"
  [filterPanel]="{ visible: false }"
  [sorting]="{ mode: 'none' }"
  [wordWrapEnabled]="true"
></dx-data-grid>

<div mat-dialog-title>
  <mat-toolbar>
    <mat-toolbar-row>
      <div style="flex: auto; text-overflow: ellipsis">{{ title }}</div>

      <button
        mat-icon-button
        type="button"
        [mat-dialog-close]="false"
        matTooltip="Close"
        color="primary"
        style="margin: -1em 0">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div class="content">
  <div class="list">
    <div class="list-item" *ngFor="let s of data.preparedDataSchedules">
      {{ emplName(s.person) }}
      <a href="tel:{{ s.emplPhone }}">{{ s.emplPhone }}</a>
      <!--  -->
      : Start Time {{ startTime(s.schedule) }}, Vehicle #{{ s.internalId }}
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="sendSchedules()">Send</button>
</div>


import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Consumer, EmployeeView, LoggerService, MyUser, Signature, } from '../../../../shared/sdk';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
import { of } from 'rxjs';
import { HelperService } from '../../services/helper.service';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import moment from 'moment';
import { DlgManifestGridComponent } from 'src/app/modules/clone-log/components/dlg-manifest-grid/dlg-manifest-grid.component';
export class DlgRestoreManifestComponent {
    constructor(dialogRef, data, logger, dss, config, ui, helper, dialog) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.logger = logger;
        this.dss = dss;
        this.config = config;
        this.ui = ui;
        this.helper = helper;
        this.dialog = dialog;
        this.dso$ = of([]);
        this.gridColumns = [
            { dataField: 'ctime', caption: 'Date', dataType: 'datetime', allowFiltering: false },
            { dataField: 'device', caption: 'Device' },
            { dataField: 'user', caption: 'user' },
            { dataField: 'caller', caption: 'Tool' },
            { dataField: 'data.length', caption: 'Manifest', cellTemplate: 'manifest_cell', textAlign: 'center' },
        ];
        this.gridSelection = { mode: 'single', showCheckBoxesMode: 'none' };
        this.gridPaging = { enabled: true, pageSize: 10 };
        this.gridFilterRow = { visible: true };
        this.gridScrolling = { mode: 'infinite' };
        this.hasSignature = false;
    }
    ngOnInit() {
        this.setHasSignature();
        this.buildDso();
    }
    dateDisplay() {
        return moment(this.data.date).format('dddd, MMM D, YYYY');
    }
    restoreManifest([record]) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!record) {
                this.dialogRef.close(false);
                return;
            }
            console.log('restoreManifest', record);
            this.ui.showLoading();
            yield this.helper.api.updateManifest(this.data.manifestId, record.data || []).toPromise();
            this.ui.hideLoading();
            this.dialogRef.close(true);
        });
    }
    showManifestDialog(row) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ui.showLoading();
            const [consumers, drivers] = yield Promise.all([
                this.dss
                    .getApi(Consumer)
                    .find({
                    where: { id: { inq: [...new Set(row.data.map(({ c }) => c))] } },
                    include: [{ person: { contact: ['addresses', 'phones'] } }],
                }, headersAllTenantsAppend)
                    .toPromise(),
                this.dss
                    .getApi(EmployeeView)
                    .find({ where: { employeeId: { inq: [...new Set(row.data.map(({ e }) => e))] } } }, headersAllTenantsAppend)
                    .toPromise(),
            ]);
            this.ui.hideLoading();
            const driversMap = drivers.reduce((p, d) => (Object.assign({}, p, { [d.id]: d })), {});
            const consumersMap = consumers.reduce((p, c) => (Object.assign({}, p, { [c.id]: c })), {});
            const d = moment(row.ctime).format('M/D/YYYY h:mm A');
            const title = `Manifest for ${d}`;
            void this.dialog.open(DlgManifestGridComponent, {
                hasBackdrop: true,
                data: { title, manifest: row, consumersMap, driversMap },
                minWidth: 1200,
            });
        });
    }
    setHasSignature() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (moment(this.data.date, 'YYYY-MM-DD').isAfter(moment()))
                return;
            if (moment(this.data.date, 'YYYY-MM-DD').isBefore(moment().subtract(1, 'day'))) {
                this.hasSignature = true;
                return;
            }
            const signature = yield this.dss
                .getApi(Signature)
                .findOne({ where: { vdate: this.data.date }, fields: { id: true } })
                .toPromise();
            this.hasSignature = !!signature;
        });
    }
    buildDso() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ui.showLoading();
            const stages = [{ $match: { entityId: this.data.manifestId } }, { $sort: { ctime: -1 } }];
            const logRows = yield gqlMongoLoad(this.dss, 'EntityActionLog_TripManifest', {}, stages).pipe().toPromise();
            const rows = logRows.map(v => (Object.assign({}, v, { entity: undefined }, v.entity.after)));
            const users = yield this.dss
                .getApi(MyUser)
                .find({ where: { id: { inq: [...new Set(rows.map(r => r.userId))] } } })
                .toPromise();
            const usersMap = users.reduce((p, u) => (Object.assign({}, p, { [u.id]: u.username })), {});
            rows.forEach((r, i) => {
                r.device =
                    (r.headers && ((r.headers['x-application-name'] && `Tablet.${r.headers['x-application-name']}`) || 'Web')) ||
                        'System';
                r.user = usersMap[r.userId];
                r.caller = r.headers && r.headers['x-caller'];
            });
            this.dso$ = of(rows);
            this.ui.hideLoading();
        });
    }
    grid_onSelectionChanged(e) { }
}

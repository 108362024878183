import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import notify from 'devextreme/ui/notify';
import isEmpty from 'lodash-es/isEmpty';
import { utc } from 'moment';
import { Consumer } from '../../../../shared/sdk';
import { DlgAttendanceNotesGridComponent } from '../../dialogs/dlg-attendance-notes-grid/dlg-attendance-notes-grid.component';
export class ConsumerNotesComponent {
    constructor(dialog) {
        this.dialog = dialog;
        this.wrap = true;
    }
    ngOnInit() { }
    info(note) {
        const info = note
            ? [
                [note.infoBy ? `by ${note.infoBy}` : '', note.infoDate ? `on ${utc(note.infoDate).format('YYYY/MM/DD')}` : '']
                    .filter(chnk => !isEmpty(chnk))
                    .join(' '),
                note.followUpDate ? `Follow up on ${utc(note.followUpDate).format('YYYY/MM/DD')}` : '',
            ]
                .filter(chnk => !isEmpty(chnk))
                .join(', ')
            : '';
        return !isEmpty(info) ? `(${info})` : '';
    }
    expand_onClick() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dialog
                .open(DlgAttendanceNotesGridComponent, {
                // width: '250px',
                height: '600px',
                hasBackdrop: true,
                data: { model: this.model },
            })
                .afterClosed()
                .toPromise()
                .catch(err => notify(err.message || err, 'error', 5000));
        });
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import { LoopBackStoreOptions } from '../../../../shared/classes/loopback-custom-store/generic/store-options/LoopBackStoreOptions';
import { hAll } from '../../../../shared/classes/utils/utils';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { Consumer, Note } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-attendance-notes-grid',
  templateUrl: './dlg-attendance-notes-grid.component.html',
  styleUrls: ['./dlg-attendance-notes-grid.component.scss'],
})
export class DlgAttendanceNotesGridComponent implements OnInit {
  ds: DataSourceOptions;
  gridColumns: any[] = [
    { dataField: 'dateTime', dataType: 'date', format: 'shortDateShortTime', width: 130 },
    { dataField: 'author', width: 90 },
    { dataField: 'text' },
    { dataField: 'infoBy', width: 90 },
    {
      dataField: 'infoDate',
      dataType: 'date',
      format: 'shortDate',
      customizeText: this.config.customizeShortDateText,
      width: 80,
    },
    {
      dataField: 'followUpDate',
      dataType: 'date',
      format: 'shortDate',
      customizeText: this.config.customizeShortDateText,
      width: 80,
    },
  ];

  gridPaging = { enabled: true, pageSize: 20 };
  gridScrolling = { mode: 'infinite' };

  constructor(
    private dialogRef: MatDialogRef<DlgAttendanceNotesGridComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      model: Consumer;
    },
    private dss: DataSourceService,
    public config: ConfigService,
  ) {}

  ngOnInit() {
    this.ds = this.buildDataSource();
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(Note, Note, false) as LoopBackStoreOptions<any, any>;
    so.customHeaders = hAll;
    so.customFilter = {
      where: { consumerId: this.data.model.id },
      order: ['dateTime DESC'],
    };

    return {
      store: new CustomStore(so),
    } as DataSourceOptions;
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxTextAreaComponent } from 'devextreme-angular/ui/text-area';
import { DxComponent } from 'devextreme-angular/core';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import notify from 'devextreme/ui/notify';
import compact from 'lodash-es/compact';
import uniqBy from 'lodash-es/uniqBy';
import { utc } from 'moment/moment';
import { oc } from 'ts-optchain';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { Consumer, LoggerService } from '../../../../shared/sdk';
import { AuthClaimService } from '../../services/auth-claim.service';

@Component({
  selector: 'app-dlg-notify-coord',
  templateUrl: './dlg-notify-coord.component.html',
  styleUrls: ['./dlg-notify-coord.component.scss'],
  providers: [AuthClaimService],
})
export class DlgNotifyCoordComponent extends ABaseComponent implements OnInit {
  @ViewChild('targetGroup', { static: false }) validationGroup: DxValidationGroupComponent;
  @ViewChild('bodyInput', { static: false }) bodyInput: DxTextAreaComponent;

  codes = this.service.getProcedureCodes().map(c => ({ _id: c }));

  emails = uniqBy(
    compact<string>([
      oc(this.auth).ServiceCoordinatorEmail(), //
      oc(this.client).cwEmail(), //
    ]),
    v => v.toLowerCase(),
  );

  code: string = oc(this.auth).Code();

  to: string;

  get subject() {
    return `Request for Service Authorization for Client ${this.clientName} (MCI ${this.clientMci})`;
  }

  get body() {
    return (
      `Dear ${this.coordinatorName}, \n\n` +
      `I\`m reaching out to ask you to issue service Authorization for client ${this.clientName}, please see details below\n\n` +
      // 'I`m reaching out to notify you that the PERS Service Authorization for the participant listed ' +
      // 'below will expire on [date].\n' +
      // 'Could you please provide a renewed Service Authorization for this member or let us know ' +
      // 'how to proceed? \n' +
      `Service: ${this.code} - ${this.service.getCodeMap()[this.code]} \n` +
      `Participant Name: ${this.clientName} \n` +
      `Date of Birth: ${utc(this.clientDob).format('L')} \n` +
      `MCI Number: ${this.clientMci} \n\n` +
      'Best Regards.'
    );
  }

  get auth() {
    return oc(this.data).cellInfo.data.last.__lastCodeAuth.last();
  }

  get client() {
    return oc(this.data).cellInfo.data.last() as Consumer;
  }

  get coordinatorName() {
    return oc(this.auth).ServiceCoordinatorName() || oc(this.client).cwName();
  }

  get clientName() {
    return oc(this.auth).Name() || [oc(this.client).person.firstname(), oc(this.client).person.lastname()].join(' ');
  }

  get clientDob() {
    return oc(this.auth).MemberDOB() || oc(this.client).person.dob();
  }

  get clientMci() {
    return oc(this.auth).MedicaidID() || oc(this.client).mci();
  }

  constructor(
    protected logger: LoggerService,
    public service: AuthClaimService,
    private dialogRef: MatDialogRef<DlgNotifyCoordComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cellInfo: any;
    },
  ) {
    super(logger);
  }

  ngOnInit() {}

  copyToClipboard(input: DxComponent) {
    const el = input.instance.element().querySelector('.dx-texteditor-input');

    el.select();
    document.execCommand('copy');
    el.setSelectionRange(0, 0);
    notify('Copied to clipboard', 'success', 2000);
  }

  submit() {
    const result = this.validationGroup.instance.validate();
    if (!result.isValid) return;

    alert('not implemented');
    this.dialogRef.close();
  }

  onCodeChanged(e) {
    this.bodyInput.instance.reset();
  }
}

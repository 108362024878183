import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoggerService, MyUser, MyUserApi } from '../../../../shared/sdk';
import { HelperService as ConsumerHelperService } from '../../../consumer/services/helper.service';
import { HelperService } from '../../services/helper.service';
import { gqlMongoLoad } from 'src/app/shared/classes/loopback-custom-store/generic/store.utils';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { of } from 'rxjs';
import { UiService } from 'src/app/shared/modules/ui/services/ui.service';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
import { ConfigService } from 'src/app/shared/modules/my-common/services/config.service';
export class DlgScheduleHistoryGridComponent {
    constructor(dialogRef, data, logger, config, helper, consumerHelper, dss, ui) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.logger = logger;
        this.config = config;
        this.helper = helper;
        this.consumerHelper = consumerHelper;
        this.dss = dss;
        this.ui = ui;
        this.dso$ = of([]);
        this.buildDso();
    }
    ngOnInit() { }
    buildDso() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ui.showLoading();
            const schedule = this.data.row.schedule;
            const stages = [
                { $match: { $or: [{ 'entity.id': schedule.id }, { 'entity.before.id': schedule.id }] } },
                { $sort: { ctime: -1 } },
            ];
            const logRows = yield gqlMongoLoad(this.dss, 'EntityActionLog_DriverSchedule', {}, stages).pipe().toPromise();
            const rows = [schedule, ...logRows.slice(0, -1).map(v => v.entity.before)];
            const users = yield this.dss
                .getApi(MyUser)
                .find({
                where: { id: { inq: rows.map(r => r.updatedBy) } },
                include: [{ employee: ['person'] }],
            })
                .toPromise();
            const usersMap = users.reduce((p, u) => (Object.assign({}, p, { [u.id]: (u.employee && new FullNamePipe(this.config).transform(u.employee)) || u.username })), {});
            rows.forEach((r, i) => {
                const h = logRows[i].headers;
                r.ctime = logRows[i].ctime;
                r.device = (h && ((h['x-application-name'] && `Tablet.${h['x-application-name']}`) || 'Web')) || 'System';
                r.user = usersMap[r.updatedBy];
            });
            this.dso$ = of(rows);
            this.ui.hideLoading();
        });
    }
}

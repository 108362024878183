import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import { ConfigService } from '../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../shared/modules/ui/components/abstract/a-base.component';
import { CommunicationMessage, LoggerService } from '../../shared/sdk';
import DataSourceOptions = DevExpress.data.DataSourceOptions;
import CustomStore from 'devextreme/data/custom_store';
import moment from 'moment';

@Component({
  selector: 'app-com-center',
  templateUrl: './com-center.component.html',
  styleUrls: ['./com-center.component.scss'],
})
export class ComCenterComponent extends ABaseComponent implements OnInit, OnDestroy {
  dso: DataSourceOptions;
  intervalId: any;
  selectedDate: Date = new Date();
  @Input() domain = 'Consumer';
  @Input() typeOptions = [];
  @Output() mySelected: EventEmitter<any[]> = new EventEmitter<any[]>();

  grid_stateStoring: any;

  selectedType = '';
  selectedStatus = '';
  statusOptions = [
    { name: 'All', value: '' },
    { name: 'Sent', value: 'Sent' },
    { name: 'Confirmed', value: 'Confirmed' },
    { name: 'Unrecognized', value: 'Unrecognized' },
    { name: 'Pending', value: 'Pending' },
  ];

  @ViewChild(DxDataGridComponent, { static: true }) grid: DxDataGridComponent;

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    private dss: DataSourceService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: '941bf2a7-8b8a-4282-8b1c-f843b7c07165',
    };
  }

  ngOnInit() {
    this.dso = this.buildDso();
    this.intervalId = setInterval(() => {
      this.dso = this.buildDso();
    }, 60000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  repaint(): void {}

  grid_onInitialized(e) {}

  calendar_onValueChanged(e) {
    this.selectedDate = e.value;
    this.dso = this.buildDso();
  }

  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      if (e.data.status === 'Unrecognized') (e.cellElement as HTMLElement).classList.add('cell-red');
      if (e.data.status === 'Confirmed') (e.cellElement as HTMLElement).classList.add('cell-green');
    }
  }

  onTypeChanged(e) {
    this.selectedType = e.value;
    this.dso = this.buildDso();
  }

  onStatusChanged(e) {
    this.selectedStatus = e.value;
    this.dso = this.buildDso();
  }

  buildDso() {
    const so = this.dss.getStoreOptions(CommunicationMessage, null, false);
    const start = moment(this.selectedDate).startOf('day').toDate();
    const end = moment(this.selectedDate).endOf('day').toDate();
    so.noSql = true;
    const type = this.selectedType ? { type: this.selectedType } : {};
    const status = this.selectedStatus ? { status: this.selectedStatus } : {};
    so.customFilter = {
      where: { domain: this.domain, dateSent: { between: [start, end] }, ...type, ...status },
      order: 'updatedAt DESC',
      include: ['sentByUser'],
    };
    const store = new CustomStore(so);

    return { store } as DataSourceOptions;
  }
}

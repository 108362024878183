<app-content-w-header header="Authorizations Expirations">
  <div class="content" style="display: flex; flex-direction: column; width: 100%; height: 100%">
    <mat-card class="toolbar">
      <div class="dx-fieldset" style="display: flex">

        <div class="dx-fieldset" style="display: flex; align-items: center">
          <label>Facility</label>
          <dx-select-box
            width="250px"
            [dataSource]="groupedFacilityDso"
            [(value)]="selectedFacilityId"
            valueExpr="id"
            displayExpr="shortname"
            itemTemplate="facilityItem"
            [grouped]="true"
            [showClearButton]="true"
            [searchEnabled]="true"
            placeholder="All"
          >
            <div *dxTemplate="let f of 'facilityItem'">
              <strong>{{ f.name }}</strong> [{{ f.type }}: {{ f.shortname }}]
            </div>
          </dx-select-box>
        </div>

        <div class="dx-fieldset" style="display: flex; align-items: center">
          <label>Client Status</label>
          <dx-select-box
            width="100px"
            [dataSource]="statusDso"
            [(value)]="selectedClientStatus"
            [showClearButton]="true"
            placeholder="All"
          ></dx-select-box>
        </div>

        <div class="dx-fieldset" style="display: flex; align-items: center">
          <label>Code</label>
          <dx-select-box
            width="150px"
            [dataSource]="codeDso"
            valueExpr="_id"
            [displayExpr]="service.codeDisplayExpr"
            [(value)]="selectedCode"
            [showClearButton]="true"
            placeholder="All"
          ></dx-select-box>
        </div>

        <div class="dx-fieldset" style="display: flex; align-items: center">
          <label>Filter</label>
          <dx-select-box
            width="200px"
            [dataSource]="expireDso"
            [(value)]="selectedFilter"
            [showClearButton]="true"
            placeholder="All"
          ></dx-select-box>
        </div>

        <!--        <dxi-item location="after" locateInMenu="auto">-->
        <div style="display: inline-block">
          <dx-button
            style="text-align: left" type="default" text="Filter"
            (onClick)="filter()"
          ></dx-button>
          <!--            [disabled]="!selectedClientStatus || !selectedCode || !selectedFilter"-->
        </div>
        <!--        </dxi-item>-->

        <!--      </dx-toolbar>-->
      </div>
    </mat-card>

    <div style="flex: auto; display: flex; position: relative">
      <div style="flex: auto; position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: flex">
        <dx-data-grid
          #grid
          width="100%"
          height="100%"
          [searchPanel]="{ visible: false }"
          [headerFilter]="{ visible: true }"
          [filterRow]="{ visible: true }"
          [filterPanel]="{ visible: true }"
          [columnResizingMode]="'widget'"
          [columnAutoWidth]="true"
          [dataSource]="dso"
          [dateSerializationFormat]="null"

          (onInitialized)="grid_onInitialized($event)"
          (onCellPrepared)="grid_onCellPrepared($event)"
          (onToolbarPreparing)="grid_onToolbarPreparing($event)"
          (onContextMenuPreparing)="grid_onContextMenuPreparing($event)"
        >
          <!--          [stateStoring]="grid_stateStoring"-->

          <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>

          <dxo-editing
            mode="cell"
            [allowAdding]="false"
            [allowUpdating]="false"
            [allowDeleting]="false"
          ></dxo-editing>

          <!--    <dxo-sorting mode="none"></dxo-sorting>-->

          <dxo-group-panel [visible]="true"></dxo-group-panel>

          <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

          <!-- region Columns -->

          <dxi-column type="buttons" caption="Actions" [showInColumnChooser]="false">

            <dxi-button template="notifyCoordinatorBtn">
              <ng-container *dxTemplate="let cellInfo of 'notifyCoordinatorBtn'">
                <dx-button hint="Notify Coordinator" [icon]="'email'"
                           (onClick)="notifyCoordinator($event, cellInfo)"></dx-button>
              </ng-container>
            </dxi-button>

          </dxi-column>

          <dxi-column
            caption="Code"
            dataField="last.__lastCodeAuth.last.Code"
            dataType="string"
            [allowEditing]="false"
          >
            <dxo-lookup
              [dataSource]="codeDso"
              valueExpr="_id"
              [displayExpr]="service.codeDisplayExpr"
            ></dxo-lookup>
          </dxi-column>

          <dxi-column
            caption="MemberID"
            dataField="last.__lastCodeAuth.last.MemberID"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            caption="Tenant"
            dataField="last.tenantId"
            dataType="string"
            [allowEditing]="false"
          >
            <dxo-lookup [dataSource]="facilityDso" displayExpr="shortname" valueExpr="id"></dxo-lookup>
          </dxi-column>

          <dxi-column
            caption="Status"
            dataField="last.status"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            caption="MedicaidID"
            dataField="last.mci"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            caption="MCO"
            dataField="last.activeMco"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            caption="LastName"
            dataField="last.person.lastname"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            caption="FirstName"
            dataField="last.person.firstname"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            caption="DOB"
            dataField="last.person.dob"
            dataType="date"
            format="shortDate"
            [customizeText]="config.customizeShortDateText"
            [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"
            [allowEditing]="false"
          ></dxi-column>

          <dxi-column
            *ngFor="let m of months | reverse"
            [caption]="m | utc:'YYYY-MM'"
            [dataField]="m | utc:'YYYYMM'"
            [showEditorAlways]="false"
            trueText="Has Auth"
            falseText="No Auth"
            [customizeText]="monthCustomizeTextFn"
          ></dxi-column>

          <dxi-column
            caption="Phone"
            dataField="last._auth.Phone"
            dataType="string"
            [allowEditing]="false"
          ></dxi-column>

          <!-- endregion -->

          <!-- region Templates -->

          <div *dxTemplate="let info of 'detail'">
            <dx-tab-panel width="100%">
              <dxi-item title="Auths">
                <dx-data-grid
                  width="100%"
                  [dataSource]="getMemberDso(info.data)"
                  [dateSerializationFormat]="null"
                >

                  <dxo-editing
                    mode="none"
                    [allowAdding]="false"
                    [allowUpdating]="false"
                    [allowDeleting]="false"
                  ></dxo-editing>

                  <dxo-grouping [contextMenuEnabled]="true"></dxo-grouping>
                  <dxo-group-panel [visible]="true"></dxo-group-panel>

                  <!--  region Columns          -->

                  <dxi-column caption="MemberID" dataField="MemberID" dataType="string"></dxi-column>

                  <dxi-column caption="MedicaidID" dataField="MedicaidID" dataType="string"></dxi-column>

                  <dxi-column caption="Name" dataField="Name" dataType="string"></dxi-column>

                  <dxi-column caption="LastName" dataField="LastName" dataType="string"></dxi-column>

                  <dxi-column caption="FirstName" dataField="FirstName" dataType="string"></dxi-column>

                  <dxi-column caption="Phone" dataField="Phone" dataType="string"></dxi-column>

                  <dxi-column
                    caption="MemberDOB"
                    dataField="MemberDOB"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                  <dxi-column caption="IOType" dataField="IOType" dataType="string"></dxi-column>

                  <dxi-column caption="RequestType" dataField="RequestType" dataType="string"></dxi-column>

                  <dxi-column caption="RequestStatus" dataField="RequestStatus" dataType="string"></dxi-column>

                  <dxi-column
                    caption="AuthNumberFacets"
                    dataField="AuthNumberFacets"
                    dataType="string"></dxi-column>

                  <dxi-column caption="AuthNumberUMI" dataField="AuthNumberUMI" dataType="string"></dxi-column>

                  <dxi-column
                    caption="StartDT"
                    dataField="StartDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                  <dxi-column
                    caption="EndDT"
                    dataField="EndDT"
                    dataType="date"
                    format="shortDate"
                    [customizeText]="config.customizeShortDateText"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDate' }"></dxi-column>

                  <dxi-column
                    caption="AuthUnitsApproved"
                    dataField="AuthUnitsApproved"
                    dataType="number"></dxi-column>

                  <dxi-column caption="RSProviderName" dataField="RSProviderName" dataType="string"></dxi-column>

                  <dxi-column caption="Code" dataField="Code" dataType="string"></dxi-column>

                  <dxi-column caption="ProviderType" dataField="ProviderType" dataType="string"></dxi-column>

                  <dxi-column caption="ProviderName" dataField="ProviderName" dataType="string"></dxi-column>

                  <dxi-column caption="Duration" dataField="Duration" dataType="number"></dxi-column>

                  <dxi-column caption="Frequency" dataField="Frequency" dataType="string"></dxi-column>

                  <dxi-column caption="Rule" dataField="_manifest" cellTemplate="rule_cell"></dxi-column>

                  <dxi-column caption="Note" dataField="Note" dataType="string"></dxi-column>

                  <dxi-column
                    caption="ServiceCoordinatorName"
                    dataField="ServiceCoordinatorName"
                    dataType="string"></dxi-column>

                  <dxi-column
                    caption="ServiceCoordinatorPhone"
                    dataField="ServiceCoordinatorPhone"
                    dataType="string"></dxi-column>

                  <dxi-column
                    caption="ServiceCoordinatorEmail"
                    dataField="ServiceCoordinatorEmail"
                    dataType="string"></dxi-column>

                  <dxi-column
                    caption="Valid"
                    dataField="_valid"
                    dataType="boolean"
                    [showEditorAlways]="false"
                  ></dxi-column>

                  <dxi-column
                    caption="AuthLastModifiedDT"
                    dataField="AuthLastModifiedDT"
                    dataType="date"
                    format="shortDateShortTime"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                  <dxi-column
                    caption="Imported Date"
                    dataField="_ctime"
                    dataType="date"
                    format="shortDateShortTime"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                  <dxi-column
                    caption="Updated Date"
                    dataField="_rtime"
                    dataType="date"
                    format="shortDateShortTime"
                    [editorOptions]="{ type: 'date', displayFormat: 'shortDateShortTime' }"></dxi-column>

                  <!--  endregion          -->

                  <!-- region Templates  -->

                  <ng-container *dxTemplate="let cellInfo of 'rule_cell'">
                    <table class="rules" cellpadding="0" cellspacing="0">
                      <tr>
                        <td>mo</td>
                        <td>tu</td>
                        <td>we</td>
                        <td>th</td>
                        <td>fr</td>
                        <td>sa</td>
                        <td>su</td>
                        <td>tot</td>
                      </tr>
                      <tr>
                        <td>{{ cellInfo.value?.M }}</td>
                        <td>{{ cellInfo.value?.T }}</td>
                        <td>{{ cellInfo.value?.W }}</td>
                        <td>{{ cellInfo.value?.Th }}</td>
                        <td>{{ cellInfo.value?.F }}</td>
                        <td>{{ cellInfo.value?.Sa }}</td>
                        <td>{{ cellInfo.value?.Su }}</td>
                        <td>{{ cellInfo.value?.Tot }}</td>
                      </tr>
                    </table>
                  </ng-container>

                  <!-- endregion -->

                </dx-data-grid>
              </dxi-item>
            </dx-tab-panel>
          </div>

          <!-- endregion -->
        </dx-data-grid>
      </div>
    </div>
  </div>

</app-content-w-header>

import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { DataSourceService } from 'src/app/shared/modules/my-common/services/datasource.service';
import { MyUtils } from 'src/app/shared/sdk';
export class ReportsConsumerComponent {
    constructor(dss) {
        this.dss = dss;
        this.isConsumersByDestinationButtonDisabled = false;
    }
    ngOnInit() {
        this.checkExpiration();
    }
    checkExpiration() {
        const expirationTime = localStorage.getItem('isConsumersByDestinationButtonDisabledExpirationTime');
        if (expirationTime) {
            const expirationMoment = moment(expirationTime);
            const currentMoment = moment();
            this.isConsumersByDestinationButtonDisabled = currentMoment.isBefore(expirationMoment.add(10, 'minute'));
        }
    }
    genPeriodReport() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const from = moment(this.selectedFromValue);
            const to = moment(this.selectedToValue);
            const weeks = to.diff(from, 'weeks');
            yield this.dss.getApi(MyUtils).mtmTripsReport(to.format('YYYY-MM-DD'), weeks).toPromise();
            this.isConsumersByDestinationButtonDisabled = true;
            localStorage.setItem('isConsumersByDestinationButtonDisabledExpirationTime', moment().format());
            notify('The report is in progress to be generated. To re-generate this report, please go back to this page in 10 minutes.', 'info', 20000);
        });
    }
}
